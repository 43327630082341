import React, { useContext, useMemo, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Icon, Typography } from "../../../components_v2";
import setSelectValues from "../../../utils/setSelectValues";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SelectBox } from "../../../components/SelectBox";
import { SIZE, TextBox } from "../../../components/TextBox";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";
import AllocatePaymentTable from "./AllocatePaymentTable";
import { useEffect } from "react";
import { KIND, TextButton } from "../../../components/TextButton";
import { useMutation, useQuery } from "react-query";
import { paymentAllocationData } from "../../../services";
import getSelectValues from "../../../utils/getSelectValues";
import moment from "moment";
import { useParams } from "react-router-dom";
import { PAYMENT_DRAWER, setDrawerState } from "../../../redux/actions";
import {
  CO_INVOICES_,
  CO_PAYMENT_,
  CUSTOMER_OVERVIEW,
  EDIT,
  GET_GROUPING_LIST_DATA,
  NEW,
  REVERSE_PAYMENT,
  VIEW,
} from "../../../constants";
import { utcTimstampToLocalDate } from "../../../utils/utcTimstampToLocalDate";
import _ from "lodash";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_, CO_TIMELINE_ } from "../../../constants";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import useFormat from "../../../hooks/useFormat";
import { useDropzone } from "react-dropzone";
import AttachmentFile from "../../../components/AttachmentFile/AttachmentFile";
import { toast } from "react-toastify";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import Loader from "../../../components/Loader";

const AddPayment = (props) => {
  let {
    editData = {},
    type = "",
    onSuccess = () => {},
    services = () => {},
    formValues = {},
    setAttachment,
    updatingDeskUpload,
    removeAttchFile,
    fileObject,
  } = props;
  const { SMSAttachment } = useSelector((s) => s.SMSAction);

  const { template, emailAttached, fileName } = useMemo(() => {
    let template = [];
    let emailAttached = [];
    let fileName;

    SMSAttachment &&
      SMSAttachment.map((e) => {
        if (e.type == "TEMPLATE") {
          template.push(e.fileRef);
        } else {
          emailAttached.push(e.fileRef);
          fileName = e.name;
        }
      });

    return { template, emailAttached, fileName };
  }, [SMSAttachment.map((e) => e.fileRef)]);

  console.log("SMSAttachment", SMSAttachment);

  const intl = useIntl();
  const format = useFormat();
  const { customerId } = useParams();
  let dispatch = useDispatch();
  const [isDueAmount, setIsDueAmount] = useState("");

  // used for getting invoice data to parent level
  const [isInvData, setIsInvData] = useState();

  const [isAmount, setIsAmount] = useState();
  const [isCmAmt, setIsCmAmt] = useState("");
  const {
    referenceData,
    currentOrganization,
    userInfo,
    defaultData,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const schema = Yup.object({
    // payment_amount: Yup.number()
    //   .test(
    //     "maxDigitsAfterDecimal",
    //     "number field must have 2 digits after decimal or less",
    //     (payment_amount) => /^\d+(\.\d{1,2})?$/.test(payment_amount)
    //   )
    //   .transform((value) => (isNaN(value) ? undefined : value)),
    payment_amount: Yup.number()
      .test(
        "maxDigitsAfterDecimal",
        "number field must have 2 digits after decimal or less",
        (payment_amount) => {
          const regex =
            type === REVERSE_PAYMENT
              ? /^-?\d+(\.\d{1,2})?$/
              : /^\d+(\.\d{1,2})?$/;
          return regex.test(payment_amount);
        }
      )
      .transform((value) => (isNaN(value) ? undefined : value)),
    date: Yup.date().required("Payment Date is Required"),
    payment_status: Yup.array()
      .min(1, "Required")
      .required("Status is Required"),
    check_num: Yup.string().when("payment_method", {
      is: (frVal) => {
        return frVal && frVal[0] && frVal[0].id === "DC_NACHA" ? true : false;
      },
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    rout_num: Yup.string().when("payment_method", {
      is: (frVal) => {
        return (frVal && frVal[0] && frVal[0].id === "DC_NACHA") ||
          (frVal && frVal[0] && frVal[0].id === "PA_NACHA")
          ? true
          : false;
      },
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    acc_num: Yup.string().when("payment_method", {
      is: (frVal) => {
        return (frVal && frVal[0] && frVal[0].id === "DC_NACHA") ||
          (frVal && frVal[0] && frVal[0].id === "PA_NACHA")
          ? true
          : false;
      },
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    acc_nam: Yup.string().when("payment_method", {
      is: (frVal) => {
        return (frVal && frVal[0] && frVal[0].id === "DC_NACHA") ||
          (frVal && frVal[0] && frVal[0].id === "PA_NACHA")
          ? true
          : false;
      },
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    ...(type === REVERSE_PAYMENT
      ? {
          commission_percentage: Yup.number()
            .transform((value, originalValue) => {
              return originalValue === "" ? undefined : value;
            })
            .nullable() // allows null values
            .optional(), // makes the field optional
        }
      : {
          commission_percentage: Yup.number()
            .transform((value, originalValue) => {
              return originalValue === "" ? undefined : value;
            })
            .nullable() // allows null values
            .optional() // makes the field optional
            .min(0, "Value should be between 0 - 100") // sets minimum value to 0
            .max(100, "Value should be between 0 - 100"), // sets maximum value to 10
        }),
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);

  let rdData = referenceData["currency"];

  let customerCurrency = _.get(customerData, "data.doc.invoice_currencies", []);

  let filteringCurrency = rdData.filter(
    (i) => customerCurrency && customerCurrency.includes(i.id)
  );

  const debounceFilter = useDebouncedCallback((value) => {
    setIsAmount(value);
  }, 1000);

  const debounceCmAmt = useDebouncedCallback((value) => {
    setIsCmAmt(value);
  }, 1000);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      paid_to: setSelectValues("TRUST_ACCOUNT"),
      cur: setSelectValues(currentDefaultFormatDetails.default_currency),
      ...(((editData && type === VIEW) ||
        type === EDIT ||
        type === REVERSE_PAYMENT) && {
        ...editData,
        date:
          type === REVERSE_PAYMENT
            ? new Date()
            : new Date(utcTimstampToLocalDate(editData?.date)),
        cur: setSelectValues(editData.amount?.currency),
        payment_method: setSelectValues(editData.method),
        payment_status: setSelectValues(editData.status),
        paid_to: setSelectValues(editData?.paid_to),
        ...(type === REVERSE_PAYMENT
          ? {
              description: `Payment reversal of ${
                currentDefaultFormatDetails.default_currency
              } ${_.get(editData, "amount.value", "")} on ${format.date({
                value: new Date(),
              })}`,
            }
          : {}),
        ...(type === REVERSE_PAYMENT
          ? {
              payment_amount: `-${_.get(editData, "amount.value", "").toFixed(
                2
              )}`,
            }
          : { payment_amount: _.get(editData, "amount.value", "").toFixed(2) }),
      }),
    },
  });

  const {
    invoices,
    payment_amount,
    payment_status,
    cur,
    pending_allocation,
    payment_method,
    date,
    cm_amt,
    commission_percentage,
  } = watch();

  const { debtCom } = useMemo(() => {
    let debtCom;
    if (_.get(customerData, "data.doc.business_unit", "")) {
      debtCom =
        referenceData &&
        referenceData["business_unit_list"].find(
          (e) => e.id === _.get(customerData, "data.doc.business_unit", null)
        );
    }
    return { debtCom };
  }, []);

  // useEffect(() => {
  //   if (
  //     debtCom &&
  //     debtCom.commission_method === "DC" &&
  //     payment_amount &&
  //     commission_percentage
  //   ) {
  //     let findCmtAmt = (payment_amount * commission_percentage) / 100;
  //     if (findCmtAmt) {
  //       setValue("cm_amt", findCmtAmt.toFixed(2) || "");
  //     } else {
  //       setValue("cm_amt", "");
  //     }
  //   } else {
  //     let findCmtAmt =
  //       (payment_amount * commission_percentage) /
  //       (100 + commission_percentage);
  //     if (findCmtAmt) {
  //       setValue("cm_amt", findCmtAmt.toFixed(2) || "");
  //     } else {
  //       setValue("cm_amt", "");
  //     }
  //   }
  // }, [payment_amount, commission_percentage]);

  useEffect(() => {
    if (payment_amount && cm_amt) {
      let findPer = (cm_amt * 100) / payment_amount;
      if (findPer && findPer?.toString()?.length > 0) {
        setValue("commission_percentage", Math.abs(findPer.toFixed(2)));
      } else {
        setValue("commission_percentage", "");
      }
    }
  }, [payment_amount, cm_amt]);

  const updateCommissionAmount = (percentage) => {
    if (payment_amount && percentage) {
      let calculatedAmount = (percentage / 100) * payment_amount;
      setValue("cm_amt", Math.abs(calculatedAmount.toFixed(2)));
    }
  };

  useEffect(() => {
    if (_.get(customerData, "data.doc.display_name") && type == NEW) {
      setValue("acc_nam", _.get(customerData, "data.doc.display_name", ""));
    }
  }, [customerData]);

  useEffect(() => {
    if (editData && editData.attachments && editData.attachments.length) {
      let emailAttachedData = editData.attachments.map((e) => {
        return {
          fileRef: e.ref,
          name: e.name,
          // type: e.file_type,
        };
      });
      dispatch(setAttachment(emailAttachedData));
    }
  }, [editData, editData?.attachments?.length]);

  const statusOption = useMemo(() => {
    const dayDiff =
      moment
        .tz(date, currentDefaultFormatDetails.timeZone)
        .startOf("day")
        .diff(
          moment.tz(currentDefaultFormatDetails.timeZone).startOf("day"),
          "days"
        ) + 1;
    let payStatus;
    if (
      dayDiff > 0 &&
      (_.get(payment_method, "[0].id") === "PA_NACHA" ||
        _.get(payment_method, "[0].id") === "DC_NACHA")
    ) {
      payStatus = referenceData["payment_status"].map((e) => {
        return {
          ...e,
          ...(e.id !== "initiated" && { disabled: true }),
        };
      });
    } else {
      payStatus = referenceData["payment_status"];
    }
    return payStatus || [];
  }, [date, _.get(payment_method, "[0].id")]);

  useEffect(() => {
    if (
      _.get(validationField, "minPayment", 0) &&
      payment_amount &&
      payment_amount < _.get(validationField, "minPayment") &&
      type !== REVERSE_PAYMENT &&
      type !== VIEW
    ) {
      setError("payment_amount", {
        message: `*Minimum Payment Amount Is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("payment_amount");
    }
  }, [payment_amount]);

  const allocatedTo = useMemo(() => {
    let onlyChangedValue = invoices?.filter(
      (i) => i.allocatedAmount != undefined
    );

    return onlyChangedValue?.map((i) => {
      return { invoice_id: i._id, amount: Number(i.allocatedAmount) };
    });
  }, [invoices && invoices.map((e) => e.allocatedAmount)]);

  let allocatedMappingVales = invoices?.map((i) => Number(i.allocatedAmount));

  // const sum = allocatedMappingVales?.reduce((acc, val) => {
  //   return acc + (val || 0);
  // }, 0);

  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        not_paid_amount,
      }));
    return invIds;
  });

  const postPaymentValues = useMutation(
    (data) =>
      services({
        ...data,
        organization: currentOrganization,
        id: customerId,
        entity: "customer",
        ...(type === REVERSE_PAYMENT
          ? {
              payment_id: `${_.get(editData, "_id", "")}`,
            }
          : {}),
      }),
    {
      onSuccess: (data, variables, context) => {
        reset();
        onSuccess();
        if (_.get(customerData, "data.doc.gid", "").length) {
          queryClient.refetchQueries([
            `${GET_GROUPING_LIST_DATA}-${customerId}`,
          ]);
        }
        props.closeDrawer();
      },
    }
  );

  const updatePaymentValues = useMutation(
    (data) =>
      services({
        organization: currentOrganization,
        id: editData && editData.customer_id,
        entity: "customer",
        data,
        contactId: editData._id,
      }),
    {
      onSuccess: (data, variables, context) => {
        if (_.get(customerData, "data.doc.gid", "").length) {
          queryClient.refetchQueries([
            `${GET_GROUPING_LIST_DATA}-${customerId}`,
          ]);
        }
        reset();
        onSuccess();
      },
    }
  );

  const onSubmit = async (data) => {
    const currentDate = new Date();
    const timeZone = currentDefaultFormatDetails.time_zone;
    let values;
    let paymentValues = {
      ...(type === REVERSE_PAYMENT
        ? {
            date: moment
              .tz(
                `${currentDate.getFullYear()}-${
                  currentDate.getMonth() + 1
                }-${currentDate.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : {
            date: moment
              .tz(
                `${data?.date?.getFullYear()}-${
                  data?.date?.getMonth() + 1
                }-${data?.date?.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }),
      amount: {
        value: data.payment_amount,
        currency: getSelectValues(data.cur),
      },
      payment_currency: getSelectValues(data.cur),
      method: getSelectValues(data.payment_method),
      reference: data.reference,
      status: getSelectValues(data.payment_status),
      check_num: data.check_num,
      // chk_img_nam: fileName,
      // check_img_ref: emailAttached,
      attachments: SMSAttachment.map((e) => {
        return {
          ref: e.fileRef,
          name: e.name,
          // file_type: e.type,
        };
      }),
      ...(type === REVERSE_PAYMENT
        ? {
            description: `Payment reversal of ${
              currentDefaultFormatDetails.default_currency
            } ${data.payment_amount} on ${format.date({
              value: moment
                .tz(
                  `${currentDate.getFullYear()}-${
                    currentDate.getMonth() + 1
                  }-${currentDate.getDate()}`,
                  "YYYY-MM-DD",
                  timeZone
                )
                .utc()
                .valueOf(),
            })}`,
          }
        : { description: data.description }),

      ...(data?.payment_method?.[0]?.id == "DC_NACHA" ||
      data?.payment_method?.[0]?.id == "PA_NACHA"
        ? {
            acc_nam: data.acc_nam,
            acc_num: data.acc_num,
            rout_num: data.rout_num,
          }
        : {}),
      // ...((data?.payment_method?.[0]?.id == "DC_NACHA" &&
      //   emailAttached &&
      //   emailAttached.length > 0) ||
      // (data?.payment_method?.[0]?.id == "PA_NACHA" &&
      //   emailAttached &&
      //   emailAttached.length > 0)
      //   ? {
      //       acc_nam: data.acc_nam,
      //       acc_num: data.acc_num,
      //       rout_num: data.rout_num,
      //     }
      //   : {}),

      ...(data.expected_payment_date &&
      data.expected_payment_date?.getFullYear()
        ? {
            expected_date: moment
              .tz(
                `${data.expected_payment_date?.getFullYear()}-${
                  data.expected_payment_date?.getMonth() + 1
                }-${data.expected_payment_date?.getDate()}`,
                "YYYY-MM-DD",
                timeZone
              )
              .utc()
              .valueOf(),
          }
        : { expected_date: 1 }),
      comment: data.comment,
      ...(data?.payment_status?.[0]?.id == "initiated" &&
      data?.payment_method?.[0]?.id == "DC_NACHA"
        ? {
            allocated_to: allocatedTo,
          }
        : {}),
      ...(data?.payment_status?.[0]?.id == "initiated" &&
      data?.payment_method?.[0]?.id == "PA_NACHA"
        ? {
            allocated_to: allocatedTo,
          }
        : {}),
      ...(data &&
      data.payment_status &&
      data.payment_status[0] &&
      data.payment_status[0].id != "success"
        ? {}
        : {
            allocated_to: allocatedTo,
          }),
      ...(data.paid_to ? { paid_to: getSelectValues(data.paid_to) } : {}),
      ...(data.commission_percentage !== undefined &&
      data.commission_percentage !== "" &&
      data.commission_percentage !== null
        ? { commission_percentage: data.commission_percentage }
        : {}),
      ...(data.cm_amt !== undefined &&
      data.cm_amt !== "" &&
      data.cm_amt !== null
        ? { cm_amt: data.cm_amt }
        : {}),
    };
    if (formValues.action_type === "FIELD_VISIT") {
      values = { ...formValues, payment: { ...paymentValues } };
    } else {
      values = paymentValues;
    }

    if (type === NEW || type === REVERSE_PAYMENT) {
      await postPaymentValues.mutateAsync(values);
    } else {
      await updatePaymentValues.mutateAsync(values);
    }
  };

  const paymentAllocation = useQuery(
    [
      `GET_PAYMENT_ALLOCATION-${customerId}}`,
      {
        filters: {
          currency: _.get(cur, "[0].id"),
          amount: isAmount,
          ...(debtCom &&
          debtCom.commission_method !== "DC" &&
          isCmAmt?.length > 0
            ? { commission: isCmAmt }
            : {}),
          ...(_.get(customerData, "data.doc.gid", "")
            ? { gid: _.get(customerData, "data.doc.gid", "") }
            : {}),
        },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      filters = {
        ...filters,
      };
      return await paymentAllocationData({
        customer_id: customerId,
        filters,
        organization: currentOrganization,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        (_.get(cur, "[0].id") &&
          isAmount &&
          _.get(payment_status, "[0].id") === "success" &&
          type !== EDIT) ||
        (_.get(cur, "[0].id") &&
          isAmount &&
          _.get(payment_status, "[0].id") === "initiated" &&
          type !== EDIT &&
          (_.get(payment_method, "[0].id") === "PA_NACHA" ||
            _.get(payment_method, "[0].id") === "DC_NACHA")) ||
        (type === NEW &&
          _.get(payment_status, "[0].id") === "success" &&
          debtCom &&
          debtCom.commission_method !== "DC" &&
          isCmAmt &&
          isCmAmt.length > 0)
          ? true
          : false,
    }
  );

  const { sum } = useMemo(() => {
    const sum = allocatedMappingVales?.reduce((acc, val) => {
      return acc + (val || 0);
    }, 0);

    return { sum };
  }, [
    invoices?.length,
    paymentAllocation.isFetching,
    // _.get(paymentAllocation, "data.data.doc.allocations", []).length,
    allocatedMappingVales?.reduce((acc, val) => {
      return acc + (val || 0);
    }, 0),
  ]);

  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      maxFiles: 1,
      ...(_.get(payment_method, "[0].id") === "DC_NACHA" ||
      _.get(payment_method, "[0].id") === "PA_NACHA"
        ? {
            accept: {
              "image/jpg": [".jpg", ".png", ".jpeg"],
            },
          }
        : {
            accept: {
              "image/jpg": [".jpg", ".png", ".jpeg"],
              "text/*": [".pdf", ".csv"],
            },
          }),
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        dispatch(fileObject(arrObj));
      },
    });

  const [isSingleAllocate, setIsSingleAllocate] = useState(false);

  useEffect(() => {
    if (
      debtCom &&
      debtCom.commission_method !== "DC" &&
      invoices &&
      invoices.length > 0 &&
      debounceCmAmt?.toString()?.length > 0
    ) {
      setIsSingleAllocate(invoices.some((e) => e.allocatedAmount !== ""));
    }
  }, [invoices && invoices.map((e) => e?.allocatedAmount)]);

  console.log(
    "paymentdata_EDIT",
    Number(payment_amount).toFixed(2) - Number(sum).toFixed(2),
    Number(payment_amount).toFixed(2),
    Number(sum).toFixed(2)
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="co_add_payment_top_input_wraper">
          <div style={{ width: "180px" }}>
            <Controller
              name="date"
              control={control}
              style={{ backgroundColor: "#EEEEEE" }}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <DateRangeSelect
                  disabled={
                    type === VIEW ||
                    type === REVERSE_PAYMENT ||
                    (editData && editData.source === "INSTANT") ||
                    (editData && editData.source === "portal")
                  }
                  clearable={false}
                  requiredAstric={true}
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "payment_date",
                  })}
                  placeholder={intl.formatMessage({
                    id: " ",
                  })}
                  value={field.value}
                  onChange={(e) => field.onChange(e.date)}
                  // selected={field.value}
                />
              )}
            />
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            <div style={{ width: "90px" }}>
              <Controller
                name="cur"
                defaultValues={currentDefaultFormatDetails?.default_currency}
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    disabled={
                      type === VIEW ||
                      type === REVERSE_PAYMENT ||
                      (editData && editData.source === "INSTANT") ||
                      (editData && editData.source === "portal")
                    }
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    clearable={false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "ccy",
                    })}
                    placeholder={intl.formatMessage({
                      id: "ccy",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={filteringCurrency}
                  />
                )}
              />
            </div>
            <div style={{ width: "180px" }}>
              <Controller
                name="payment_amount"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    requiredAstric={true}
                    disabled={
                      type === VIEW ||
                      type === REVERSE_PAYMENT ||
                      (editData && editData.source === "INSTANT") ||
                      (editData && editData.source === "portal")
                    }
                    size={SIZE.mini}
                    {...field}
                    name={field.name}
                    type="number"
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "payment_amount",
                    })}
                    placeholder={intl.formatMessage({
                      id: "payment_amount",
                    })}
                    onKeyDown={(e) => {
                      if (
                        e.key == "ArrowUp" ||
                        e.key == "ArrowDown" ||
                        e.key == "-"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      field.onChange(e);
                      debounceFilter(e.target.value);
                    }}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ width: "180px" }}>
            <Controller
              defaultValues={[]}
              name="payment_method"
              control={control}
              render={({ field }) => (
                <SelectBox
                  disabled={
                    type === VIEW ||
                    type === REVERSE_PAYMENT ||
                    (editData && editData.source === "INSTANT") ||
                    (editData && editData.source === "portal")
                  }
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  clearable={false}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "payment_method",
                  })}
                  placeholder={intl.formatMessage({
                    id: "payment_method",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["payment_method"]}
                />
              )}
            />
          </div>
          <div style={{ width: "180px" }}>
            <Controller
              name="reference"
              control={control}
              render={({ field }) => (
                <TextBox
                  disabled={
                    type === VIEW ||
                    type === REVERSE_PAYMENT ||
                    (editData && editData.source === "INSTANT") ||
                    (editData && editData.source === "portal")
                  }
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "payment_reference",
                  })}
                  placeholder={intl.formatMessage({
                    id: "payment_reference",
                  })}
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            pattingTop: "15px",
            gap: "10px",
          }}
        >
          <div style={{ width: "180px" }}>
            <Controller
              defaultValues={[]}
              name="payment_status"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <SelectBox
                  requiredAstric={true}
                  disabled={type === VIEW || type === REVERSE_PAYMENT}
                  size={SIZE.mini}
                  {...field}
                  name={field.name}
                  clearable={false}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "payment_status",
                  })}
                  placeholder={intl.formatMessage({
                    id: "payment_status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={statusOption}
                />
              )}
            />
          </div>
          {currentDefaultFormatDetails &&
          currentDefaultFormatDetails.industry_sector === "INSE297" ? (
            <>
              <div style={{ width: "190px" }}>
                <Controller
                  defaultValues={[]}
                  name="paid_to"
                  control={control}
                  render={({ field }) => (
                    <SelectBox
                      // requiredAstric={true}
                      disabled={
                        type === VIEW ||
                        type === REVERSE_PAYMENT ||
                        (editData && editData.source === "INSTANT") ||
                        (editData && editData.source === "portal")
                      }
                      size={SIZE.mini}
                      {...field}
                      name={field.name}
                      clearable={false}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "paid_to",
                      })}
                      placeholder={intl.formatMessage({
                        id: "paid_to",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={referenceData["payment_paid_to"]}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <></>
          )}

          <div style={{ width: "190px" }}>
            <Controller
              // defaultValues={[]}
              name="commission_percentage"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <TextBox
                  {...field}
                  fullWidth
                  name={field.name}
                  disabled={type === VIEW || type === REVERSE_PAYMENT}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "commission_percentage",
                  })}
                  placeholder={intl.formatMessage({
                    id: "commission_percentage",
                  })}
                  onChange={(e) => {
                    field.onChange(e);
                    updateCommissionAmount(e.target.value); // Update `cm_amt` when `commission_percentage` changes
                  }}
                  value={field.value}
                  type="number"
                />
              )}
            />
          </div>
          <div style={{ width: "190px" }}>
            <Controller
              name="cm_amt"
              control={control}
              // rules={{ required: "Required" }}
              render={({ field }) => (
                <TextBox
                  {...field}
                  fullWidth
                  name={field.name}
                  disabled={type === VIEW || type === REVERSE_PAYMENT}
                  size={SIZE.mini}
                  error={errors[field.name] && errors[field.name].message}
                  label={intl.formatMessage({
                    id: "commission_amount",
                  })}
                  placeholder={intl.formatMessage({
                    id: "commission_amount",
                  })}
                  value={field.value}
                  type="number"
                  onChange={(e) => {
                    field.onChange(e);
                    debounceCmAmt(e.target.value);
                  }}
                  onBlur={() => {
                    if (cm_amt?.toString()?.length === 0) {
                      setValue("commission_percentage", "");
                    }
                  }}
                />
              )}
            />
          </div>
        </div>

        {/* NACHA Fields */}
        {(_.get(payment_method, "[0].id") === "DC_NACHA" ||
          _.get(payment_method, "[0].id") === "PA_NACHA") && (
          <div className="nacha_fields_container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>
                {format.rd({
                  name: "payment_method",
                  id: _.get(payment_method, "[0].id"),
                })}
              </Typography>
              {Array.isArray(SMSAttachment) && SMSAttachment.length > 0 ? (
                <></>
              ) : (
                <div
                  className="upload_pay_wraper"
                  onClick={() =>
                    type !== VIEW || type !== REVERSE_PAYMENT ? open() : null
                  }
                >
                  <Icon icon="upload" color="#516beb" />
                  <Typography>Upload Check</Typography>
                </div>
              )}
            </div>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
              {_.get(payment_method, "[0].id") === "DC_NACHA" && (
                <Controller
                  name="check_num"
                  control={control}
                  shouldUnregister
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      fullWidth
                      name={field.name}
                      disabled={
                        type === VIEW ||
                        type === REVERSE_PAYMENT ||
                        (editData && editData.source === "INSTANT") ||
                        (editData && editData.source === "portal")
                      }
                      size={SIZE.mini}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "check_number",
                      })}
                      placeholder={intl.formatMessage({
                        id: "check_number",
                      })}
                      value={field.value}
                      type="number"
                    />
                  )}
                />
              )}

              <Controller
                name="rout_num"
                control={control}
                shouldUnregister
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    fullWidth
                    name={field.name}
                    disabled={
                      type === VIEW ||
                      type === REVERSE_PAYMENT ||
                      (editData && editData.source === "INSTANT") ||
                      (editData && editData.source === "portal")
                    }
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "routing_number",
                    })}
                    placeholder={intl.formatMessage({
                      id: "routing_number",
                    })}
                    value={field.value}
                    type="number"
                  />
                )}
              />

              <Controller
                name="acc_num"
                shouldUnregister
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    fullWidth
                    name={field.name}
                    disabled={
                      type === VIEW ||
                      type === REVERSE_PAYMENT ||
                      (editData && editData.source === "INSTANT") ||
                      (editData && editData.source === "portal")
                    }
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "account_number",
                    })}
                    placeholder={intl.formatMessage({
                      id: "account_number",
                    })}
                    value={field.value}
                    type="number"
                  />
                )}
              />
              <Controller
                name="acc_nam"
                shouldUnregister
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextBox
                    {...field}
                    fullWidth
                    name={field.name}
                    disabled={
                      type === VIEW ||
                      type === REVERSE_PAYMENT ||
                      (editData && editData.source === "INSTANT") ||
                      (editData && editData.source === "portal")
                    }
                    size={SIZE.mini}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "account_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "account_name",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
        )}

        <Controller
          defaultValues={[]}
          name="description"
          control={control}
          // rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              fullWidth
              name={field.name}
              disabled={
                type === VIEW ||
                type === REVERSE_PAYMENT ||
                (editData && editData.source === "INSTANT") ||
                (editData && editData.source === "portal")
              }
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "payment_description",
              })}
              placeholder={intl.formatMessage({
                id: "payment_description",
              })}
              value={field.value}
            />
          )}
        />
        {(type === VIEW || type === "EDIT") && (
          <div style={{ display: "flex", gap: "10px" }}>
            {_.get(editData, "fee_amt.value", 0) > 0 && (
              <div style={{ width: "180px" }}>
                <TextBox
                  disabled={true}
                  size={SIZE.mini}
                  name="processing_fee"
                  label={"Processing Fee"}
                  placeholder={"Processing Fee"}
                  value={_.get(editData, "fee_amt.value", "")}
                />
              </div>
            )}

            {_.get(editData, "tot_pd_amt.value", 0) > 0 && (
              <div style={{ width: "180px" }}>
                <TextBox
                  disabled={true}
                  size={SIZE.mini}
                  name="total_amount"
                  label={intl.formatMessage({
                    id: "total_amount",
                  })}
                  placeholder={intl.formatMessage({
                    id: "total_amount",
                  })}
                  value={_.get(editData, "tot_pd_amt.value", "")}
                />
              </div>
            )}

            {_.get(editData, "crd_lst_4", "") && (
              <div style={{ width: "180px" }}>
                <TextBox
                  disabled={true}
                  size={SIZE.mini}
                  name="card_last_4"
                  label={"Card Last 4 Digit"}
                  placeholder={"Card Last 4 Digit"}
                  value={_.get(editData, "crd_lst_4", "")}
                />
              </div>
            )}
          </div>
        )}

        <div
          style={{
            marginBottom: "15px",
            gap: "15px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {_.get(payment_method, "[0].id", "") !== "DC_NACHA" &&
          _.get(payment_method, "[0].id", "") !== "PA_NACHA" ? (
            <div style={{ width: "100px" }}>
              <TextButton
                size="mini"
                kind={KIND.secondary}
                onClick={() => {
                  open();
                }}
                fullWidth
                // startEnhancer={<Icon icon="upload" color="#516beb" />}
                type="button"
              >
                Upload
              </TextButton>
            </div>
          ) : (
            <></>
          )}

          {SMSAttachment?.map((attachment, index) => {
            let { tempId } = attachment;
            let viewAction;
            if (type === "VIEW") {
              viewAction = attachment?.name?.split(".");
            } else if (type === "EDIT") {
              viewAction = attachment?.name?.split(".");
            }

            return (
              <AttachmentFile
                key={tempId}
                {...attachment}
                id={customerId}
                currentOrganization={currentOrganization}
                entity={"customer"}
                removeAttchFile={removeAttchFile}
                updatingDeskUpload={updatingDeskUpload}
                subCategory={
                  _.get(payment_method, "[0].id") === "DC_NACHA" ||
                  _.get(payment_method, "[0].id") === "PA_NACHA"
                    ? "Check"
                    : "Payment"
                }
                type={
                  type === "VIEW" || type === REVERSE_PAYMENT
                    ? viewAction && viewAction[1]
                    : type === "EDIT"
                    ? viewAction && viewAction[1]
                    : attachment.type
                }
                action={type}
              />
            );
          })}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Typography type="h3" subType="medium">
            Allocate to Invoice
          </Typography>
          <div
            style={{
              background: "#EEEEEE",
              borderRadius: "5px",
              padding: "5px",
            }}
          >
            <Typography type="p" subType="small">
              Pending Allocation
            </Typography>

            <NumericFormat
              // type="text"
              displayType="text"
              value={
                // _.get(pending_allocation, "value", "")
                //   ? _.get(pending_allocation, "value", "")
                //   :
                Number(payment_amount).toFixed(2) - Number(sum).toFixed(2) || 0
              }
              thousandsGroupStyle={currencyGroupStyle}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator={currencySeparator}
              decimalSeparator={currencyDecimalSeparator}
              renderText={(value) => <Typography type="h4">{value}</Typography>}
            />
            {Number(payment_amount).toFixed(2) - Number(sum).toFixed(2) < 0 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    // paddingTop: "19px",
                    paddingLeft: "35px",
                    paddingRight: "35px",
                    color: "red",
                  }}
                >
                  Allocation is greater than Payment Amount
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <AllocatePaymentTable
          setValue={setValue}
          watch={watch}
          reset={reset}
          control={control}
          errors={errors}
          setError={setError}
          type={type}
          editData={editData}
          paymentStatus={_.get(payment_status, "[0].id", "")}
          paymentMethod={_.get(payment_method, "[0].id", "")}
          setIsDueAmount={setIsDueAmount}
          cur={cur}
          paymentAllocation={paymentAllocation}
          setIsInvData={setIsInvData}
          isSingleAllocate={isSingleAllocate}
          debtCom={debtCom}
          debounceCmAmt={debounceCmAmt}
        />
        {type === REVERSE_PAYMENT && (
          <div
            style={{
              padding: "6px",
              backgroundColor: "#FFFCF2",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              borderRadius: "4px",
            }}
          >
            <Icon icon="terms_outline" color="#333860"></Icon>

            <Typography type="p" className="regular" subType="regular">
              By Clicking Save, Payment will reversed as of today
            </Typography>
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "10px",
            marginTop: "10px",
            paddingRight: "15px",
          }}
        >
          <TextButton
            kind={KIND.tertiary}
            size={"mini"}
            type="button"
            onClick={() => props.closeDrawer()}
          >
            Cancel
          </TextButton>
          <TextButton
            size={"mini"}
            disabled={
              props.type === "VIEW" ||
              Number(payment_amount) === 0 ||
              Number(payment_amount).toFixed(2) - Number(sum).toFixed(2) < 0 ||
              Number(isDueAmount) < 0 ||
              updatePaymentValues.isLoading ||
              postPaymentValues.isLoading ||
              (isInvData && isInvData.isLoading)
                ? true
                : false
            }
            isLoading={
              updatePaymentValues.isLoading || postPaymentValues.isLoading
            }
          >
            Save
          </TextButton>
        </div>
      </form>
    </>
  );
};

export default AddPayment;
