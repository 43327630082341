import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Icon, Typography } from "../../../components_v2";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import {
  deletePaymentDetailsCustomerOverview,
  getGridStructure,
  useCustomerPaymentDtl,
} from "../../../services";
import columnMapper_V2 from "../../../utils/mapper_v2";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import {
  ADD_PAYMENTS,
  DELETE_PAYMENT,
  EDIT_PAYMENTS,
} from "../../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../../providers/RBACProvider/RBACWrapper";
import {
  ADD_PAYMENT,
  PAYMENT_DRAWER,
  PAYMENT_EMAIL_DRAWER,
  REVERSE_PAYEMENT,
  setDrawerState,
} from "../../../redux/actions";
import { useDispatch } from "react-redux";
import {
  CO_PAYMENT_,
  CO_TIMELINE_,
  CUSTOMER_OVERVIEW_,
  EDIT,
  NEW,
  REVERSE_PAYMENT,
  VIEW,
} from "../../../constants";
import { KIND, TextButton } from "../../../components/TextButton";
import Loader from "../../../components/Loader";
import { ModalContext } from "../../../providers/ModalProvider";
import warningImage from "../../../../src/assets/img/warning.png";
import { toast } from "react-toastify";
import queryClient from "../../../providers/queryClient";
export default function PaymentTable(props) {
  const { currentOrganization, currentDefaultFormatDetails } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();
  const { handleModal } = useContext(ModalContext);
  let dispatch = useDispatch();
  const [isRefeatch, setIsRefeatch] = useState(0);
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  let ref = React.useRef();

  const [width, setWidth] = useState();
  const [sorting, setSorting] = React.useState([]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current?.offsetWidth);
    }
  }, [ref]);

  const gridStructure = useQuery(
    [`GRID_STRUCTURE_PAYMENT_LIST`],
    async () => {
      return await getGridStructure({
        organization: currentOrganization,
        menu: "customer_payment_v2",
      });
    },
    {
      cacheTime: 86400000,
    }
  );

  const deletePaymentData = useMutation(
    (paymentData) =>
      deletePaymentDetailsCustomerOverview({
        ...paymentData,
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }
        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
        queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
        queryClient.invalidateQueries([`${CO_PAYMENT_}${customerId}`]);
        setIsRefeatch(1 + isRefeatch);
      },
    }
  );

  const paymentList = useCustomerPaymentDtl({
    customerId: customerId,
    organization: currentOrganization,
    filters: {
      pgid: _.get(customerData, "data.doc.pgid", []),
    },
  });

  const columns = useMemo(() => {
    let { columns } = columnMapper_V2({
      columns:
        gridStructure.data &&
        gridStructure.data.data &&
        gridStructure.data.data.doc &&
        gridStructure.data.data.doc.table,
      isExpanded: false,
      isInvExpand: false,
      isExpandedHeader: false,
      isSelection: false,
    });
    return columns;
  }, [gridStructure.status]);

  const data = useMemo(() => {
    const data =
      paymentList.data &&
      paymentList.data.data &&
      paymentList.data.data.docs &&
      Array.isArray(paymentList.data.data.docs)
        ? paymentList.data.data.docs
        : [];
    return data;
  }, [paymentList.isLoading, paymentList.isFetched, paymentList.isRefetching]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row) => row.subRows,
    getSortedRowModel: getSortedRowModel(),
  });

  if (paymentList.isLoading || gridStructure.isLoading) {
    return (
      <div className="co-payment-table-cointer">
        <Loader />;
      </div>
    );
  }

  return (
    <div className="co-payment-table-cointer">
      {/* SECTION HEADER */}
      <div className="co-invoice-section_header">
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Typography type="h3" subType="medium">
            Payments & Adjustments
          </Typography>
          {currentDefaultFormatDetails.is_add_payment ? (
            <RBACWrapper role={ADD_PAYMENTS} type="PERMISSION">
              <>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    background: "#787878",
                    borderRadius: "50px",
                    marginLeft: "8px",
                  }}
                ></div>
                <div>
                  <TextButton
                    size={"mini"}
                    kind={KIND.tertiary}
                    onClick={() => {
                      setDrawer(ADD_PAYMENT, { actionType: NEW });
                    }}
                  >
                    <Icon icon="plus" color="#516beb" />
                    <span style={{ color: "#516beb" }}>Add Payment</span>
                  </TextButton>
                </div>
              </>
            </RBACWrapper>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* SECTION HEADER */}

      <div className="co-payment-table_wrapper">
        {/* TABLE HEADER */}
        <div className="co-table_header --payment_modifi" ref={ref}>
          {table.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="co-table_header-group">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.id}
                    style={{
                      width: header.getSize(),
                    }}
                    className="co-table_header-cell"
                  >
                    {header.isPlaceholder ? null : (
                      <div className="test_TABLE_HADE">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {/* TABLE HEADER */}

        {/* TABLE BODY */}
        <div className="co-table_body" style={{ position: "relative" }}>
          {/* TABLE ROW */}
          {table.getRowModel().rows.map((row) => (
            <div key={row.id} className="co-table_body-group">
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  style={{ width: cell.column.getSize() }}
                  className="co-table_body-cell"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
              <StatefulPopover
                content={({ close }) => {
                  return (
                    <div className="payment_pop_over">
                      {_.get(row, "original.amount.value", null) < 0 ? (
                        <></>
                      ) : row.original.source === "maxyfi" &&
                        (row.original.status === "success" ? false : true) ? (
                        <>
                          {row.original.status !== "success" && (
                            <RBACWrapper
                              role={DELETE_PAYMENT}
                              type={"PERMISSION"}
                            >
                              <Typography
                                style={{
                                  cursor: "pointer",
                                  padding: "5px 30px",
                                  textAlign: "center",
                                  borderBottom: "0.5px solid #CDCED9",
                                }}
                                onClick={() => {
                                  handleModal({
                                    title: "You are about to delete a Payment",
                                    content:
                                      "This will delete your Payment. Are you sure?",
                                    successCallback: () => {
                                      deletePaymentData.mutate({
                                        customerId: _.get(
                                          row,
                                          "original.customer_id",
                                          ""
                                        ),
                                        contactId: _.get(
                                          row,
                                          "original._id",
                                          ""
                                        ),
                                      });
                                    },
                                    formContent: ({ handleModal }) => {
                                      return <></>;
                                    },
                                    cancelCallback: () => {},
                                    isChildren: false,
                                    image: warningImage,
                                    buttonText: "Delete",
                                    closeButtonText: "Cancel",
                                    isCloseAble: true,
                                  });
                                }}
                              >
                                Delete
                              </Typography>
                            </RBACWrapper>
                          )}
                        </>
                      ) : row.original.source === "UPLOAD" ? (
                        <>
                          {row.original.status !== "success" &&
                            !_.get(row, "original.is_rev", false) && (
                              <RBACWrapper
                                role={DELETE_PAYMENT}
                                type={"PERMISSION"}
                              >
                                <Typography
                                  style={{
                                    cursor: "pointer",
                                    padding: "5px 30px",
                                    textAlign: "center",
                                    borderBottom: "0.5px solid #CDCED9",
                                  }}
                                  onClick={() => {
                                    handleModal({
                                      title:
                                        "You are about to delete a Payment",
                                      content:
                                        "This will delete your Payment. Are you sure?",
                                      successCallback: () => {
                                        deletePaymentData.mutate({
                                          customerId: _.get(
                                            row,
                                            "original.customer_id",
                                            ""
                                          ),
                                          contactId: _.get(
                                            row,
                                            "original._id",
                                            ""
                                          ),
                                        });
                                      },
                                      formContent: ({ handleModal }) => {
                                        return <></>;
                                      },
                                      cancelCallback: () => {},
                                      isChildren: false,
                                      image: warningImage,
                                      buttonText: "Delete",
                                      closeButtonText: "Cancel",
                                      isCloseAble: true,
                                    });
                                  }}
                                >
                                  Delete
                                </Typography>
                              </RBACWrapper>
                            )}
                        </>
                      ) : (
                        <></>
                      )}

                      <RBACWrapper role={EDIT_PAYMENTS} type={"PERMISSION"}>
                        {!_.get(row, "original.is_rev", false) && (
                          <Typography
                            style={{
                              cursor: "pointer",
                              padding: "5px 30px",
                              textAlign: "center",
                              borderBottom: "0.5px solid #CDCED9",
                            }}
                            onClick={() => {
                              setDrawer(ADD_PAYMENT, {
                                editData: row.original,
                                actionType: EDIT,
                              });
                            }}
                          >
                            Edit
                          </Typography>
                        )}
                      </RBACWrapper>

                      <Typography
                        style={{
                          cursor: "pointer",
                          padding: "5px 30px",
                          textAlign: "center",
                          borderBottom: "0.5px solid #CDCED9",
                        }}
                        onClick={() => {
                          setDrawer(ADD_PAYMENT, {
                            editData: row.original,
                            actionType: VIEW,
                          });
                        }}
                      >
                        Preview
                      </Typography>
                      {!_.get(row, "original.is_rev", false) && (
                        <Typography
                          style={{
                            cursor: "pointer",
                            padding: "5px 30px",
                            textAlign: "center",
                            borderBottom: "0.5px solid #CDCED9",
                          }}
                          onClick={() => {
                            setDrawer(PAYMENT_EMAIL_DRAWER, {
                              editData: row.original,
                              payment_id: row.original._id,
                            });
                          }}
                        >
                          Send Email
                        </Typography>
                      )}

                      {row.original.status === "success" &&
                      !_.get(row, "original.is_rev", false) ? (
                        <Typography
                          style={{
                            cursor: "pointer",
                            padding: "5px 30px",
                            textAlign: "center",
                            borderBottom: "0.5px solid #CDCED9",
                          }}
                          onClick={() => {
                            setDrawer(ADD_PAYMENT, {
                              editData: row.original,
                              actionType: REVERSE_PAYMENT,
                            });
                          }}
                        >
                          Reverse Payment
                        </Typography>
                      ) : (
                        <></>
                      )}

                      {/* delete payment is removed 18-10-23 */}
                    </div>
                  );
                }}
                accessibilityType={"tooltip"}
                triggerType={TRIGGER_TYPE.hover}
                overrides={{
                  Inner: {
                    style: () => ({
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      background: "#ffffff",
                      border: "0.5px solid #CDCED9",
                    }),
                  },
                  Body: {
                    style: () => ({
                      backgroundColor: "#FFFFFF",
                      borderRadius: "5px",
                      top: "-10px",
                    }),
                  },
                  Arrow: {
                    style: ({ $theme }) => ({}),
                  },
                }}
              >
                <div style={{ position: "absolute", right: "0" }}>
                  <Icon icon="triple_dot" isPressable />
                </div>
              </StatefulPopover>
            </div>
          ))}
          {/* TABLE ROW */}
        </div>
        {/* TABLE BODY */}
      </div>
    </div>
  );
}
