import AlphaNumeric from "./AlphaNumeric/AlphaNumeric";
import Currency from "./Currency/Currency";
import Date from "./Date/Date";
import CustomerHyperlink from "./CustomerHyperlink";
import LinkCreditNote from "./LinkCreditNote/LinkCreditNote";
import InvoiceCustomerHyperlink from "./InvoiceCustomerHyperlink";
import PaymentButton from "./PaymentButton/PaymentButton";
import CustomerWorkflowCellType from "./CustmerWorkflowCellType/CustomerWorkflowCellType";
import OutstandingDays from "./OutstandingDays/OutstandingDays";
import SelectSystemField from "./SelectSystemField/SelectSystemField";
import RequiredIndicator from "./RequiredIndicator/RequiredIndicator";
import SelectFormatField from "./SelectFormatField/SelectFormatField";
import SelectDefaultField from "./SelectDefaultField/SelectDefaultField";
import DateTime from "./DateTime/DateTime";
import StatsIndicator from "./StatsIndicator/StatsIndicator";
import StatusIndicator from "./StatusIndicator/StatusIndicator";
import User from "./User/User";
import UserName from "./UserName/UserName";
import ObjectString from "./ObjectString";
import StatusConverter from "./StatusConverter/StatusConverter";
import ReferenceValue from "./ReferenceValue/ReferenceValue";
import UserSessionInfo from "./UserSessionInfo/UserSessionInfo";
import Department from "./Department/Department";
import Role from "./Role/Role";
import NameDate from "./NameDate/NameDate";
import InvoiceStatus from "./InvoiceStatus/InvoiceStatus";
import AllocatedInvNumber from "./AllocatedInvNumber/AllocatedInvNumber";
import PaymentStatus from "./PaymentStatus/PaymentStatus";
import DateOrgTZ from "./DateOrgTZ/Date";
import UserStatus from "./UserStatus/UserStatus";
import TwoFASetup from "./TwoFASetup/TwoFASetup";
import InvoiceDownload from "./InvoiceDownload/InvoiceDownload";
import CreditLimitStatus from "./CreditLimitStatus/CreditLimitStatus";
import DateStatus from "./DateStatus/DateStatus";
import SyncStatus from "./SyncStatus/SyncStatus";
import PaymentSource from "./PaymentSource/PaymentSource";
import CreditLimitPercentage from "./CreditLimitPercentage/CreditLimitPercentage";
import TextToCamelCase from "./TextToCamelCase/TextToCamelCase";
import CustomerCategorization from "./CustomerCategorization/CustomerCategorization";
import RevenueBillingLink from "./RevenueBillingLink";
import NotesDefaultField from "./NotesDefaultField/NotesDefaultField";
import NotesFormatField from "./NotesFormatField/NotesFormatField";
import NotesSystemField from "./NotesSystemField/NotesSystemField";
import UploadFileColumns from "./UploadFileColumns/UploadFileColumns";
import Validation from "../TableFilters/Validation/Validation";
import PrimaryContactStatus from "../TableFilters/Validation/Validation";
import EsclationContact from "../TableFilters/EsclationContact/EsclationContact";
import ActionHold from "../TableFilters/ActionHold/ActionHold";
import PhoneNumber from "./PhoneNumber/PhoneNumber";
import ContactDefaultField from "./ContactDefaultField/ContactDefaultField";
import ContactFormatField from "./ContactFormatField/ContactFormatField";
import ContactSystemField from "./ContactSystemField/ContactSystemField";
import ContactUploadFileColumns from "./ContactUploadFileColumns/ContactUploadFileColumns";
import LetterDeliveryStatus from "../TableFilters/LetterDeliveryStatus/LetterDeliveryStatus";
import LetterMaintenanceCus from "../TableFilters/LetterMaintenanceCustomer/LetterMaintenanceCus";
import LetterMaintenanceInv from "../TableFilters/LetterMaintenanceInvoice/LetterMaintenanceInv";
import CSMContactType from "./CSMContactType/CSMContactType";
import PaymentDefaultField from "./PaymentDefaultField/PaymentDefaultField";
import PaymentSystemField from "./PaymentSystemField/PaymentSystemField";
import PaymentFormatField from "./PaymentFormatField/PaymentFormatField";
import BlankFragment from "./Blank/BlankFragment";
import UserAndCustomer from "./UserAndCustomer/UserAndCustomer";
import Instruction from "./Instruction/Instruction";
import DateFormat from "./DateFormat/DateFormat";
import ReqInfAssignedTo from "./ReqInfAssignedTo/ReqInfAssignedTo";
import ReqInfStatus from "./ReqInfStatus/ReqInfStatus";
import ReqSubCategorys from "./ReqSubCategorys/ReqSubCategorys";
import PredefinedDefaultField from "./PredefinedDefaultField/PredefinedDefaultField";
import PerformanceIndication from "./PerformanceIndication/PerformanceIndication";
import PaymentCollected from "./PaymentCollected/PaymentCollected";
import CommissionEarned from "./CommissionEarned/CommissionEarned";
import PredefinedPaymentDefaultField from "./PredefinedPaymentDefaultField/PredefinedPaymentDefaultField";
import MasterClient from "./MasterClient/MasterClient";
import DateMonth from "./DateMonth/Date";
import TimeCell from "./TimeCell/TimeCell";
import PaymentUploadFileColumn from "./PaymentUploadFileColumn/PaymentUploadFileColumn";
import ClientList from "./ClientList/ClientList";
import StringArrayType from "./StringArrayType/StringArrayType";
import CurrencyOrg from "./CurrencyOrg/CurrencyOrg";
import BoolenType from "./BoolenType/BoolenType";
import UserSystem from "./User/UserSystem";
import CurrencyFeeAmount from "./CurrencyFeeAmount/CurrencyFeeAmount";
import CurrencyAmountNew from "./CurrencyAmount/CurrencyAmount";
import GroupContact from "./GroupContact/GroupContact";
import ContactGroupCell from "./ContactGroupCell/ContactGroupCell";
import IsAdhocReferenceValue from "./IsAdhocReferenceValue/IsAdhocReferenceValue";
import UserAndConsumer from "./UserAndCustomer/UserAndConsumer";
import ReportCustomerLink from "./ReportCustomerLink";
import BoolYesNo from "./BoolYesNo/BoolYesNo";
import CustomerPortalLink from "./CustomerPortalLink/CustomerPortalLink";
import ReportDlvClientGroup from "./ReportDlvClientGroup/ReportDlvClientGroup";
import EmailAndFTP from "./EmailAndFTP/EmailAndFTP";
import CollectionTarget from "./CollectionTargetAmout/CollectionTarget";

const tableCellFilter = (props) => {
  let { filterType, alignment, accessor, suffix, isCurrency, rdName, color } =
    props;

  switch (filterType) {
    case "ALPHANUMERIC":
      return (props) =>
        AlphaNumeric({ ...props, alignment, accessor, suffix, color });

    case "DATE":
      return (props) => Date({ ...props, alignment, accessor, color });

    case "DATEORGTZ":
      return (props) => DateOrgTZ({ ...props, alignment, accessor, color });
    case "DATEMONTH":
      return (props) => DateMonth({ ...props, alignment, accessor, color });

    case "CURRENCY_NEW":
      return (props) =>
        CurrencyAmountNew({ ...props, alignment, accessor, isCurrency });
    case "CURRENCY":
      return (props) =>
        Currency({ ...props, alignment, accessor, isCurrency, color });

    case "CURRENCYFEEAMOUNT":
      return (props) =>
        CurrencyFeeAmount({ ...props, alignment, accessor, isCurrency, color });

    case "CLIENTS_LIST":
      return (props) =>
        ClientList({ ...props, alignment, accessor, suffix, color });

    case "CUSTOMER_HYPERLINK":
      return (props) =>
        CustomerHyperlink({ ...props, alignment, accessor, color });

    case "CUSTOMER_CATEGORIZATION":
      return (props) =>
        CustomerCategorization({ ...props, alignment, accessor, color });

    case "INVOICE_CUSTOMER_HYPERLINK":
      return (props) =>
        InvoiceCustomerHyperlink({ ...props, alignment, accessor, color });

    case "LINKED_INVOICES":
      return (props) =>
        LinkCreditNote({ ...props, alignment, accessor, color });

    case "PAYMENT_HOVER_BUTTON":
      return (props) => PaymentButton({ ...props, alignment, accessor, color });

    case "CUSTOMERWORKFLOWTYPE":
      return (props) =>
        CustomerWorkflowCellType({ ...props, alignment, accessor, color });

    case "OUTSTANDINGDAYS":
      return (props) =>
        OutstandingDays({ ...props, alignment, accessor, color });

    case "SELECTSYSTEMFIELD":
      return (props) =>
        SelectSystemField({ ...props, alignment, accessor, color });

    case "GROUPCONTACT":
      return (props) => GroupContact({ ...props, alignment, accessor });

    case "CONTACTGROUPCELL":
      return (props) => ContactGroupCell({ ...props, alignment, accessor });

    case "REQUIREDINDICATOR":
      return (props) =>
        RequiredIndicator({ ...props, alignment, accessor, color });

    case "SELECTFORMATFIELD":
      return (props) =>
        SelectFormatField({ ...props, alignment, accessor, color });

    case "SELECTDEFAULTFIELD":
      return (props) => SelectDefaultField({ ...props, alignment, accessor });

    case "PREDEFINED_SELECT_FIELD":
      return (props) =>
        PredefinedDefaultField({ ...props, alignment, accessor, color });

    case "PREDEFINED_SELECT_FIELD":
      return (props) =>
        PredefinedDefaultField({ ...props, alignment, accessor, color });

    case "DATETIME":
      return (props) => DateTime({ ...props, alignment, accessor, color });
    case "TIMECELL":
      return (props) => TimeCell({ ...props, alignment, accessor });
    case "STATSINDICATOR":
      return (props) =>
        StatsIndicator({ ...props, alignment, accessor, color });

    case "STATUSINDICATOR":
      return (props) =>
        StatusIndicator({ ...props, alignment, accessor, color });

    case "USER":
      return (props) => User({ ...props, alignment, accessor, color });

    case "USER_AND_CUSTOMER":
      return (props) =>
        UserAndCustomer({ ...props, alignment, accessor, color });
    case "USER_AND_CONSUMER":
      return (props) => UserAndConsumer({ ...props, alignment, accessor });
    case "USER_NAME":
      return (props) => UserName({ ...props, alignment, accessor, color });

    case "OBJECT_STRING":
      return (props) => ObjectString({ ...props, alignment, accessor, color });

    case "STATUS":
      return (props) =>
        StatusConverter({ ...props, alignment, accessor, color });

    case "REFERENCE_VALUE":
      return (props) =>
        ReferenceValue({ ...props, alignment, accessor, rdName, color });

    case "REQ_INF_ASSIGNED_TO":
      return (props) =>
        ReqInfAssignedTo({ ...props, alignment, accessor, color });

    case "USER_SESSION_INFO":
      return (props) =>
        UserSessionInfo({ ...props, alignment, accessor, color });

    case "USER_DEPARTMENT":
      return (props) => Department({ ...props, alignment, accessor, color });

    case "USER_ROLE":
      return (props) => Role({ ...props, alignment, accessor, color });

    case "AUDIT_USER":
      return (props) => NameDate({ ...props, alignment, accessor, color });

    case "CREDIT_LIMIT_STATUS":
      return (props) =>
        CreditLimitStatus({ ...props, alignment, accessor, color });

    case "INVOICE_STATUS":
      return (props) => InvoiceStatus({ ...props, alignment, accessor, color });

    case "REQ_INF_STATUS":
      return (props) => ReqInfStatus({ ...props, alignment, accessor, color });

    case "DATE_STATUS":
      return (props) => DateStatus({ ...props, alignment, accessor, color });

    case "ALLOCATED_INV_NUMBER":
      return (props) =>
        AllocatedInvNumber({ ...props, alignment, accessor, color });

    case "PAYMENT_STATUS":
      return (props) => PaymentStatus({ ...props, alignment, accessor, color });
    case "PAYMENT_SYNC_STATUS":
      return (props) => SyncStatus({ ...props, alignment, accessor, color });

    case "USER_STATUS":
      return (props) => UserStatus({ ...props, alignment, accessor, color });

    case "2FA_SETUP":
      return (props) => TwoFASetup({ ...props, alignment, accessor, color });

    case "INVOICE_DOWNLOAD":
      return (props) =>
        InvoiceDownload({ ...props, alignment, accessor, color });

    case "PAYMENT_SOURCE":
      return (props) => PaymentSource({ ...props, alignment, accessor, color });

    case "TEXT_TO_CAMEL_CASE":
      return (props) =>
        TextToCamelCase({ ...props, alignment, accessor, color });

    case "CREDIT_LIMIT_PERCENTAGE":
      return (props) => CreditLimitPercentage({ ...props });

    case "REVENUE_BILLING_LINK":
      return (props) =>
        RevenueBillingLink({ ...props, alignment, accessor, color });
    case "REPORT_CUSTOMER_LINK":
      return (props) => ReportCustomerLink({ ...props, alignment, accessor });
    case "NOTESDEFAULTSELECT":
      return (props) =>
        NotesDefaultField({ ...props, alignment, accessor, color });

    case "NOTESFORMATFIELDSSELECT":
      return (props) =>
        NotesFormatField({ ...props, alignment, accessor, color });

    case "NOTESSYSTEMFIELDSSELECT":
      return (props) =>
        NotesSystemField({ ...props, alignment, accessor, color });

    case "CONTACT_DEFAULT_SELECT":
      return (props) =>
        ContactDefaultField({ ...props, alignment, accessor, color });

    case "CONTACT_FORMATFIELDS_SELECT":
      return (props) =>
        ContactFormatField({ ...props, alignment, accessor, color });

    case "CONTACT_SYSTEMFIELDS_SELECT":
      return (props) =>
        ContactSystemField({ ...props, alignment, accessor, color });

    case "UPLOADFILECOLUMNS":
      return (props) =>
        UploadFileColumns({ ...props, alignment, accessor, color });

    case "CONTACT_UPLOAD_FILE_COLUMNS":
      return (props) =>
        ContactUploadFileColumns({ ...props, alignment, accessor, color });

    case "PRIMARY_CONTACT_STATAUS":
      return (props) =>
        PrimaryContactStatus({ ...props, alignment, accessor, color });

    case "ESCLATION_CONTACT_STATUS":
      return (props) =>
        EsclationContact({ ...props, alignment, accessor, color });

    case "ACTION_HOLD":
      return (props) => ActionHold({ ...props, alignment, accessor, color });
    case "delivery_status":
      return (props) =>
        LetterDeliveryStatus({ ...props, alignment, accessor, color });

    case "LETTER_MAINTENANCE_CUSTOMER":
      return (props) =>
        LetterMaintenanceCus({ ...props, alignment, accessor, color });
    case "LETTER_MAINTENANCE_INVOICE":
      return (props) =>
        LetterMaintenanceInv({ ...props, alignment, accessor, color });
    case "PHONE_NUMBER":
      return (props) =>
        PhoneNumber({ ...props, alignment, accessor, suffix, color });

    case "CSM_CONTACT_TYPE":
      return (props) =>
        CSMContactType({ ...props, alignment, accessor, suffix, color });

    case "PAYMENT_UPLOAD_SYSTEMFIELDS":
      return (props) =>
        PaymentSystemField({ ...props, alignment, accessor, color });

    case "PAYMENT_DEFAULT_SELECT":
      return (props) =>
        PaymentDefaultField({ ...props, alignment, accessor, color });

    case "PAYMENT_FORMAT_FIELD_SELECT":
      return (props) =>
        PaymentFormatField({ ...props, alignment, accessor, color });

    case "BLANK":
      return (props) => BlankFragment({ ...props, alignment, accessor, color });

    case "INSTRUCTION":
      return (props) => Instruction({ ...props, alignment, accessor, color });

    case "DATEFORMAT":
      return (props) => DateFormat({ ...props, alignment, accessor, color });

    case "REQ_SUB_CATEGORYS":
      return (props) =>
        ReqSubCategorys({ ...props, alignment, accessor, color });

    case "PERFORMANCE_ACTION_INDICATION":
      return (props) =>
        PerformanceIndication({ ...props, alignment, accessor, color });

    case "PAYMENT_COLLECTED":
      return (props) =>
        PaymentCollected({ ...props, alignment, accessor, color });

    case "COMMISSION_EARNED":
      return (props) =>
        CommissionEarned({ ...props, alignment, accessor, color });
    case "COLLECTION_TARGET":
      return (props) =>
        CollectionTarget({ ...props, alignment, accessor, color });
    case "PREDEFINED_PAYMENT_DEFAULT_FIELD":
      return (props) =>
        PredefinedPaymentDefaultField({ ...props, alignment, accessor, color });

    case "MASTER_CLIENT":
      return (props) => MasterClient({ ...props, alignment, accessor, color });

    case "PAYMENT_UPLOAD_FILE_COLUMNS":
      return (props) =>
        PaymentUploadFileColumn({ ...props, alignment, accessor, color });
    case "STRING_ARRAY_TYPE":
      return (props) =>
        StringArrayType({ ...props, alignment, accessor, suffix, color });

    case "CURRENCY_ORG":
      return (props) =>
        CurrencyOrg({ ...props, alignment, accessor, suffix, color });

    case "BOOLEN_TYPE":
      return (props) => BoolenType({ ...props, alignment, accessor, color });

    case "USER_SYSTEM":
      return (props) => UserSystem({ ...props, alignment, accessor, color });

    case "ISADHOCUSER":
      return (props) =>
        IsAdhocReferenceValue({ ...props, alignment, accessor, rdName, color });

    case "BOOL_YES_NO":
      return (props) =>
        BoolYesNo({ ...props, alignment, accessor, rdName, color });

    case "CUSTOMER_PORTAL_LINK":
      return (props) =>
        CustomerPortalLink({ ...props, alignment, accessor, rdName, color });

    case "CLIENT_GROUP_AND_CLIENT":
      return (props) =>
        ReportDlvClientGroup({ ...props, alignment, accessor, suffix, color });

    case "EMAIL_AND_FTP":
      return (props) =>
        EmailAndFTP({ ...props, alignment, accessor, suffix, color });

    default:
      return (props) =>
        AlphaNumeric({ ...props, alignment, accessor, suffix, color });
  }
};

export default tableCellFilter;
