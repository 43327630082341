/* COMMUNICATION TEMPLATE */
// export const ADD_TEMPLATE = "ADD_TEMPLATE";
// export const VIEW_TEMPLATE = "VIEW_TEMPLATE";
// export const COPY_TEMPLATE = "COPY_TEMPLATE";
// export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
// export const DELETE_TEMPLATE = "DELETE_TEMPLATE";
// export const DISABLE_TEMPLATE = "DISABLE_TEMPLATE";

/* WORKFLOW */
// export const ADD_WORKFLOW = "ADD_WORKFLOW";
// export const VIEW_WORKFLOW = "VIEW_WORKFLOW";
// export const COPY_WORKFLOW = "COPY_WORKFLOW";
// export const EDIT_WORKFLOW = "EDIT_WORKFLOW";
// export const DELETE_WORKFLOW = "DELETE_WORKFLOW";
// export const DISABLE_WORKFLOW = "DISABLE_WORKFLOW";
export const CP_AUTHENTICATION = "CP_AUTHENTICATION";

/* SETTINGS */
export const ONLINE_PAYMENT = "ONLINE_PAYMENT";
export const OUTBOUND_BUSINESS_EMAIL = "OUTBOUND_BUSINESS_EMAIL";

/* CUSTOMER_OVERVIEW */
export const INBOUND_EMAIL = "INBOUND_EMAIL";
export const VIEW = "VIEW";
export const CREATE_VIEW = "CREATE_VIEW";
export const DELETE_VIEW = "DELETE_VIEW";
export const DOWNLOAD_VIEW_DATA = "DOWNLOAD_VIEW_DATA";
export const DOWNLOAD_DIALER_LIST = "download_dialer_list";
export const DOWNLOAD_FIELD_VISIT = "download_field_visit";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const TRIGER_NEXT_ACTION = "TRIGER_NEXT_ACTION";
export const SET_RELATIONAL_MANAGER = "SET_RELATIONAL_MANAGER";
export const SET_WORKFLOW = "SET_WORKFLOW";
export const SUSPENT_WORKFLOW = "SUSPENT_WORKFLOW";
export const SUSPENT_ACTION = "SUSPENT_ACTION";
export const RELEASE_ON_HOLD_ACTION = "RELEASE_ON_HOLD_ACTION";

export const ADHOC_UPLOAD = "adhoc_upload";
export const CREDIT_NOTE = "credit_notes";
export const INVOICE_OVERVIEW = "invoice_overview";
export const CUSTOMER_OVERVIEW = "customer_overview";
export const DASHBOARD = "dashboard";
export const USER_DASHBOARD = "user_dashboard";
// ALL PERMISSIONS
export const EDIT_CUSTOMER_NOTES = "edit_customer_notes";
export const ADD_CUSTOMER_NOTES = "add_customer_notes";
export const MARK_COMPLETE_CUSTOMER_NOTES = "mark_complete_customer_notes";
export const VIEW_DOWNLOAD_DOCUMENTS = "view_download_documents";

export const EDIT_DOCUMENTS = "edit_documents";
export const DELETE_DOCUMENTS = "delete_documents";
export const ADD_VIEWS = "add_views";
export const DELETE_VIEWS = "delete_views";
export const ADD_CONTACTS = "add_contacts";
export const EDIT_CONTACTS = "edit_contacts";
export const DELETE_CONTACTS = "delete_contacts";
export const ASSIGN_CREDIT_LIMIT = "assign_credit_limit";
export const ASSIGN_RM = "assign_rm";
export const ASSIGN_WORKFLOW = "asign_workflow";
export const ASSIGN_BUSSINESS_LOCATION = "assign_bu_location";
export const ASSIGN_STATUS = "assign_status_comments";
export const ADD_PAYMENTS = "add_payments";
export const BULK_ASSIGN_WORKFLOW = "bulk_assign_workflow";
export const BULK_ASSIGN_RM = "bulk_assig_rm";
export const BULK_CHECKIN = "bulk_checkin";
export const BULK_RM_WORKFLOW = "bulk_upload_rm_workflow";
export const BULK_PAUSE = "bulk_pause";
export const BULK_STATUS = "bulk_customer_status";
export const ADD_CUSTOMER = "add_customers";
export const DOWNLOAD_CUSTOMER_LISIT = "download_customer_list";
export const DOWNLOAD_AGING_SUMMARY = "download_aging_summary";
export const DELETE_CUSTOMER_NOTES = "delete_customer_notes";
export const DISMISS_CUSTOMER_NOTES = "dismiss_customer_notes";
export const ADD_DOCUMENTS = "add_documents";
export const EDIT_PAYMENTS = "edit_payments";
export const DELETE_PAYMENT = "delete_payments";
export const CUSTOMER_MANUAL_CALL = "customer_manual_call";
export const CUSTOMER_SMS = "customer_sms";
export const CUSTOMER_MANUAL_EMAIL = "customer_manual_email";
export const CUSTOMER_NEXT_ACTION = "customer_next_action";
export const CUSTOMER_DISPUTE = "customer_dispute";
export const CUSTOMER_PAUSE = "customer_pause";
export const CUSTOMER_WHATSAPP = "customer_whastapp";
export const CUSTOMER_PROMISE_REVIEW = "customer_promise_review";
export const CUSTOMER_LETTER = "customer_letter";
export const CUSTOMER_LETTER_STATUS = "customer_letter_status";
export const BANKRUCPTCY_STATUS = "cuadbkr";
export const CUSTOMER_WF_ACTION = "customer_wf_action";
export const CUSTOMER_WF_SKIP = "customer_wf_skip";
export const CUSTOMER_RESUME = "customer_resume";
export const CUSTOMER_PORTAL_SETTINGS = "customer_portal_settings";
export const ADD_INVOICE = "add_invoice";
export const EDIT_INVOICE = "edit_invoice";
export const ADD_CHARGES = "add_charges";
export const EDIT_CHARGES = "edit_charges";
export const ADD_RECURRING_INVOICE = "add_recurring_invoice";
export const ENABLE_DISABLE_RECURRING_INVOICE =
  "enable_disable_recurring_invoice";
export const EDIT_RECURRING_INVOICE = "edit_recurring_invoice";
export const DOWNLOAD_INVOICE_LIST = "download_invoice_list";
export const INVOICE_PRIMARY_ESCALATION = "invoice_primary_escalation";
export const ADD_INVOICE_NOTES = "add_invoice_notes";
export const EDIT_INVOICE_NOTES = "edit_invoice_notes";
export const DELETE_INVOICE_NOTES = "delete_invoice_notes";
export const DISMISS_INVOICE_NOTES = "dismiss_invoice_notes";
export const INVOICE_SMS = "invoice_sms";
export const INVOICE_MANUAL_EMAIL = "invoice_manual_email";
export const INVOICE_WHATSAPP = "invoice_whastapp";
export const INVOICE_DISPUTE = "invoice_dispute";
export const INVOICE_PAUSE = "invoice_pause";
export const INVOICE_WF_ACTION = "invoice_wf_action";
export const INVOICE_WF_SKIP = "invoice_wf_skip";
export const INVOICE_RESUME = "invoice_resume";
export const UPLOAD_CUSTOMER_INVOICE = "upload_customer_invoice";
export const UPLOAD_NOTES = "upload_notes";
export const DOWNLOAD_UPLOAD_FILE = "download_uploaded_file";
export const DOWNLOAD_ERROR_FILE = "download_error_file";
export const USER_MAINTENANCE = "user_maintenance";
export const ROLE_MAINTENANCE = "role_maintenance";
export const WORKFLOW_MAINTENANCE = "workflow_maintenance";
export const COMMUNICATION_TEMPLATE_MAINTENANCE =
  "communication_template_maintenance";

//stratagy Maintainance
export const STRATEGY_MAINTAINANCE = "wsm";
export const ADD_STRATEGY_MAINTAINANCE = "aws";
export const EDIT_STRATEGY_MAINTAINANCE = "ews";
export const DELETE_STRATEGY_MAINTAINANCE = "dws";
export const VIEW_STRATEGY_MAINTAINANCE = "vws";
export const ENABLE_DISABLE_STRATEGY_MAINTAINANCE = "edws";
export const COPY_STRATEGY_MAINTAINANCE = "cws";

export const INVOICE_ITEM_MAINTENANCE = "invoice_item_maintenance";

export const REVENUE_BILLING = "revenue_billing";
export const BUSINESS_UNIT_MAINTENANCE = "business_unit_maintenance";

export const REPORTS = "reports";
export const ESIGNPERMISSIONS = "esign";
export const ACTIVITY_REPORT = "activity_report";
export const ESIGN_REPORT = "esign_report";
export const PROMISE_REPORT = "promise_report";
export const DISPUTE_REPORT = "dispute_report";
export const PAYMENT_REPORT = "payment_report";
export const NOTES_REPORT = "notes_report";
export const CREDIT_LIMIT_REPORT = "credit_limit_report";
export const AGING_SUMMARY_REPORT = "aging_summary_report";
export const NEEDS_ACTION_REPORT = "needs_action_report";
// export const COMPLIANCE_BREACH_REPORT= "compliance_breach_report";
export const CHECKIN_REPORT = "checkin_report";
export const RECURRING_REPORT = "recurring_invoice_report";
export const DOWNLOAD_REPORT = "download_report";
export const ALL_REPORTS_DOWNLOAD_ACCESS = "drpt";
export const DOWNLOAD_SELF_REPORT = "dsrpt";
export const DOWNLOAD_ALL_DOWNLOAD = "darpt";
export const ADD_BUSINESS_UNIT = "add_business_unit";
export const EDIT_BUSINESS_UNIT = "edit_business_unit";
export const DELETE_BUSINESS_UNIT = "delete_business_unit";
export const ENABLE_DISABLE_BUSINESS_UNIT = "enable_disable_business_unit";
export const GENERATE_REVENUE_BILLING = "generate_revenue_billing";
export const ADD_INVOICE_ITEM = "add_invoice_item";
export const ENABLE_DISABLE_INVOICE_ITEM = "enable_disable_invoice_item";

export const EDIT_INVOICE_ITEM = "edit_invoice_item";
export const COPY_COMMUNICATION_TEMPLATE = "copy_communication_template";
export const VIEW_AUDIT_INVOICE_ITEM = "view_audit_invoice_item";
export const ADD_COMMUNICATION_TEMPLATE = "add_communication_template";
export const EDIT_COMMUNICATION_TEMPLATE = "edit_communication_template";
export const VIEW_COMMUNICATION_TEMPLATE = "view_communication_template";
export const DELETE_COMMUNICATION_TEMPLATE = "delete_communication_template";

export const ENABLE_DISABLE_COMMUNICATION_TEMPLATE =
  "enable_disable_communication_template";
export const ADD_LIST_VALUES = "add_list_values";

export const ADD_WORKFLOW = "add_workflows";
export const EDIT_WORKFLOW = "edit_workflows";
export const DELETE_WORKFLOW = "delete_workflows";
export const VIEW_WORKFLOW = "view_workflows";
export const COPY_WORKFLOW = "copy_workflows";
export const DISABLE_WORKFLOW = "enable_disable_workflows";

export const ADD_ROLE = "add_role";
export const EDIT_ROLE = "edit_role";
export const DELETE_ROLE = "delete_role";
export const VIEW_ROLE = "view_role";
export const VIEW_ROLE_AUDIT = "view_audit_role";
export const ENABLE_DISABLE_ROLE = "enable_disable_role";

export const ADD_USER = "add_user";
export const EDIT_USER = "edit_user";
export const DELETE_USER = "delete_user";
export const ENABLE_DISABLE_USER = "enable_disable_user";
export const ENABLE_DISABLE_USER_2FA = "enable_disable_user_2fa";

export const UPDATE_ORGANIZATION_SETTINGS = "update_organization_settings";
export const VIEW_ORGANIZATION_SETTINGS = "view_organization_settings";
export const ALL_BUSINESS_UNITS = "all_business_units";
export const ALL_WORK_QUEUE = "awq";
export const ORGANIZATION_SETTINGS = "organization_settings";
export const GLOBAL_SEARCH_PERMISSION = "global_search_permission";
export const BULK_CALL_UNSUCESSFUL = "bulk_call_unsucessful";
export const REVENUE_DASHBOARD = "revenue_dashboard";
export const COMMAND_CENTER_DASHBOARD = "command_center_dashboard";
export const CUSTOMERANALYSIS = "customer_interaction_analysis";
export const SMS_ANALYTICS = "sms_analytics_dashboard";
export const EMAIL_ANALYTICS = "emldas";
export const BUSINESS_DASHBOARD = "business_dashboard";
export const CLIENT_PORTAL_ACCESS = "client_portal_access";
export const CLIENT_DASHBOARD = "client_dashboard";
export const UPLOAD_CUSTOMER_CONTACT = "upload_customer_contact";
export const ADD_ORGANIZATION = "add_organization";
export const SYNC_ORGANIZATION = "sync_organization";
export const UPLOAD_CLIENT_CUSTOMER_INVOICE = "upload_client_customer_invoice";
export const CUSTOMER_NAME_EDIT = "customer_name_edit";
export const LETTER_STATUS = "letter_status_maintenance";
export const BULK_LETTER_STATUS_UPDATE = "bulk_letter_status_update";
export const BULK_LETTERFILE_DOWNLOAD = "bulk_letter_file_download";
export const BULK_LETTER_DATA_DOWNLOAD = "bulk_letter_data_download";
export const PAYMENT_PLAN_INSTALMENT = "payment_plan_installment_report";
export const PAYMENT_PLAN_REPORT = "payment_plan_report";
export const UPLOAD_CUSTOMER_PAYMENTS = "upload_customer_payments";
export const DELETE_INVOICE = "delete_invoice";
export const DEBT_COLLECTION_DASHBOARD = "debt_collection_dashboard";
export const Contact_Status_Maintenance = "contact_status_maintenance";
export const Add_Contact_Status = "add_contact_status";
export const Edit_Contact_Status = "edit_contact_status";
export const View_Audit_Contact_Status = "view_audit_contact_status";
export const SKIP_TRACE_REPORT = "skip_trace_report";
export const CUSTOMER_FIELD_VISIT = "customer_field_visit";
export const CUSTOMER_GENERIC_ACTION = "customer_generic_action_capture";
export const RESCHEDULR_NEXT_ACTION = "reschedule_next_action";
export const ADHOC_UPLOAD_ADDITIONAL_SETTINGS = "auas";

// Mini Miranda Config
export const MINI_MIRANDA_CONFIGURATION = "mini_miranda_configuration";
export const ADD_MINI_MIRANDA = "add_mini_miranda";
export const EDIT_MINI_MIRANDA = "edit_mini_miranda";
export const ENABLE_DISABLE_MINI_MIRANDA = "enable_disable_mini_miranda";
export const VIEW_MINI_MIRANDA = "view_mini_miranda";
export const VIEW_AUDIT_MINI_MIRANDA = "view_audit_mini_miranda";
export const ADDITIONAL_SETTINGS_MINI_MIRANDA =
  "additional_settings_mini_miranda";
export const HOLIDAY_BLACKLIST_MAINTENANCE = "holiday_blacklist_maintenance";
export const ADD_HOLIDAY_BLACKLIST = "add_holiday_blacklist";
export const EDIT_HOLIDAY_BLACKLIST = "edit_holiday_blacklist";
export const ENABLE_HOLIDAY_BLACKLIST = "enable_disable_holiday_blacklist";
export const VIEW_HOLIDAY_BLACKLIST = "view_holiday_blacklist";
export const VIEW_AUDIT_HOLIDAY_BLACKLIST = "view_audit_holiday_blacklist";
export const HOLIDAY_BLACKLIST_SETTING = "hbm_additional_settings";
export const BULK_SKIP_TRACE = "bulk_skip_trace";

export const REQUEST_INFORMATION = "request_information";
export const DOWNLOAD_REQUEST_INFORMATION = "download_request_information";
export const UPLOAD_REQUEST_INFORMATION = "upload_request_information";
export const SUBMIT_REQUEST_INFORMATION = "submit_information";
export const CUSTOMER_REQUEST_INFORMATION = "customer_request_information";
export const ASSIGN_RI_TO_CLIENT = "assign_ri_to_client";
export const LOCATION_TRACKER = "location_tracking";
export const VIEW_LOCATION_TRACKER = "view_location_tracking";
export const LEGAL_MOTION_REPORT = "legal_motions_report";
export const LEGAL_SUMMARY_REPORT = "legal_summary_report";
export const LEGAL_DEBT_REPORT = "legal_debt_report";
export const LEGAL_TIMESHEET_REPORT = "legal_timesheet_report";
export const CAPTURE_SECTION_LEGAL = "capture_section_legal";
export const ADD_REMOVED_DEBTS_LEGAL = "add_remove_debts_legal";
export const ADD_REMOVED_DEFENDANTS_LEGAL = "add_remove_defendants_legal";
export const ADD_REMOVED_COUNSEL_LEGAL = "add_update_counsel_legal";
export const ADD_EDIT_MOTIONS_COUNSEL_LEGAL = "add_edit_motions_legal";
export const DELETE_MOTIONS_LEGAL = "delete_motions_legal";
export const STATUS_UPDATE_LEGAL = "status_update_legal";
export const UPDATE_TIMESHEET_LEGAL = "update_timesheet_legal";
export const MERGE_CONSUMER_RECORDS = "merge_consumer_records";
export const UNMERGE_CONSUMER_RECORDS = "unmerge_consumer_records";
export const MAINTAIN_PROCESS_GROUP = "maintain_process_group";
export const REVIEW_GROUP_REPORT = "review_grouping_report";
export const COMPLIANCE_BREACH_REPORT = "compliance_breach_report";
export const CONSUMER_STATUS_MAINTENANANCE = "consumer_status_maintenance";
export const BANKRUPTCY_MAINTENANCE = "bkrpty";
export const ADD_BANKRUPTCY = "abkr";
export const EDIT_BANKRUPTCY = "ebkr";
export const DELETE_BANKRUPTCY = "dbkr";
export const AUDIT_BANKRUPTCY = "ubkr";

export const VIEW_CONSUMER_STATUS = "vcs";
export const ADD_CONSUMER_STATUS = "acs";
export const MODIFY_CONSUMER_STATUS = "mcs";
export const PERFORMANCE_DASHBOARD = "performance_dashboard";
export const AUDIT_KPI_MAINTENANCE = "audit_kpi_maintenance";
export const KPI_MENU_CODE = "kpi_maintenance";
export const DELETE_ADHOC_DATA = "dladup";
export const BATCH_CHECK = "chkbt";
export const UPADTE_CHECK_STATUS = "chkup";
export const GENERATE_NACHA_FILE = "gnach";
export const UPDATE_BATCH_STATUS = "btups";
export const CHECK_MAINTENANCE = "chkmt";
export const CONSUMER_MANUAL_GROUPING = "cmlgpr";

export const CONSUMER_LEGAL_ACTION = "culgac";
export const ADD_CREDIT_REPORT = "concr";
export const GENERATE_CREDIT_REPORT = "gencr";
export const UPLOAD_CREDIT_REPORT_RETURN_FILE = "ucrrf";
export const UNASSIGN_RM = "unassign_rm";

//Sticky information
export const STICKY_MAINTENANCE = "snmt";
export const DELETE_OTHER_USER_STICKY_NOTES = "doustnt";
export const MAINTAIN_STICKY_NOTES = "mntstnt";
export const DELETE_STICKY_NOTES = "dlsn";

//MaintainMapping
export const ENABLE_DISABLE_MAPPING = "diupm";
export const DELETE_UPLOAD_MAPPING = "deupm";
export const ADJUSTMENT_REPORT = "arpt";
export const ADD_ADJUSTMENT = "aadj";
export const EDIT_ADJUSTMENT = "eadj";
export const DELETE_ADJUSTMENT = "dadj";

export const COM_UN_SUBSCRIBE_REPORT = "urpt";
export const SMS_EDIT = "smed";
export const EMAIL_EDIT = "emed";

//Notes Permission and Timeline

export const DISABLE_TIMELINE = "dis_tml";
export const DISABLE_NOTES = "dis_not";

//Permission Report Maintainance

export const REPORT_MAINTAINANCE_ALL = "report_maintenance";
export const ADDORCOPY_REPORT = "acrm";
export const EDIT_REPORT_MAINTAINANACE = "erm";
export const DELETE_REPORT_MAINTAINANACE = "drm";
export const VIEW_REPORT_MAINTAINANACE = "vrm";
export const ENABLE_DISABLE_REPORT = "edrm";
// client grouping permission
export const ADD_ORG_CLIENT_GROUPING = "aclgr";
export const EDIT_ORG_CLIENT_GROUPING = "eclgr";
export const DELETE_ORG_CLIENT_GROUPING = "dclgr";
export const ASSIGN_WORK_QUEUE = "aswq";
export const SMS_REPLY = "smsr";
export const EMAIL_REPLY = "emr";

export const REPORT_DELIVERY_SETTINGS = "report_delivery_settings";

export const CUSTOM_FIELD_MAINTAINANCE = "custom_field_maintenance";
export const ADD_REPORT_DELIVERY_SETTINGS = "ard";
export const EDIT_REPORT_DELIVERY_SETTINGS = "urd";
export const VIEW_REPORT_DELIVERY_SETTINGS = "vrd";
export const ENABLE_DISABLE_REPORT_DELIVERY_SETTINGS = "edrd";

export const CUSTOM_FIELD_MAINTENANCE = "custom_field_maintenance";
export const ADD_CUSTOM_FIELD = "atacf";
export const EDIT_CUSTOM_FIELD = "atecf";
export const VIEW_CUSTOM_FIELD = "atvcf";
export const AUDIT_CUSTOM_FIELD = "ataucf";
export const ENABLE_DISABLE_CUSTOM_FIELD = "atedcf";
