import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ParagraphLarge, HeadingXLarge } from "baseui/typography";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { TextBox } from "../../components/TextBox";
import { useIntl } from "react-intl";
import { SelectBox } from "../../components/SelectBox";
import { TextButton, SIZE, KIND } from "../../components/TextButton";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import getSelectValues from "../../utils/getSelectValues";
import { getOrganization, invTaxRateMaintenanceList } from "../../services";
import {
  editInvoiceList,
  postInvoiceItem,
  putCustomFieldList,
} from "../../services/invoiceItem";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import setSelectValues from "../../utils/setSelectValues";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { EDIT, VIEW } from "../../constants";
import queryClient from "../../providers/queryClient";
import { ADD_LIST_VALUES } from "../../providers/RBACProvider/permissionList";

const AddCustomFieldListForm = ({ setAddCustomModal, edtingCustomData }) => {
  let type = edtingCustomData && edtingCustomData.type;

  let intl = useIntl();

  const {
    currentDefaultFormatDetails,
    referenceData,
    currentOrganization,
    space,
  } = useContext(MaxyfiContext);

  const {
    _id,
    options,
    category,
    data_type,
    cby,
    idb,
    udy,
    udt,
    section,
    ...rest
  } = edtingCustomData && edtingCustomData.data;

  const {
    control,
    register,
    handleSubmit,
    unregister,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      ...(type === EDIT
        ? {
            ...rest,
            category: setSelectValues(category),
            data_type: setSelectValues(data_type),
            section: setSelectValues(section),
          }
        : type === VIEW
        ? {
            ...rest,
            category: setSelectValues(category),
            data_type: setSelectValues(data_type),
            section: setSelectValues(section),
          }
        : {}),
    },
  });

  const putCustomField = useMutation(
    (data) =>
      putCustomFieldList({
        organization: currentOrganization,
        customFieldID: _id,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(
          `CUSTOM_FIELD_LIST-${currentOrganization}`
        );
        reset();
        setAddCustomModal(false);
      },
    }
  );

  let { pricing_type } = watch();

  const onSubmit = async (data) => {
    let tax = [];
    if (data && data.taxes) {
      for (let i of data.taxes) {
        let taxes = getSelectValues(i);

        if (taxes) {
          tax.push(taxes);
        }
        // let year = i && i[0] && i[0].year;
        // let session = i && i[0] && i[0].session;
        // if (year && session) {
        //   skipDuration.push({ year: year, session: session });
        // }
      }
    }

    const { cat, category, data_type, section, ...rest } = data;
    let values = {
      ...rest,
      category: getSelectValues(category),
      data_type: getSelectValues(data_type),
      section: getSelectValues(section),
    };
    await putCustomField.mutateAsync(values);
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          style={{
            width: "675px",
            backgroundColor: "#fff",
            // border: "1px solid #CDCED9",
            borderRadius: "5px",
            textAlign: "center",
            padding: "10px 10px",
          }}
        >
          <HeadingXLarge $style={{ color: "#516BEB" }}>
            {edtingCustomData.type === "EDIT"
              ? "Edit Custom Field"
              : edtingCustomData.type === "VIEW"
              ? "View Custom Field"
              : "Add Custom Field"}
          </HeadingXLarge>
          {/* NAME && DESCRIPTION STARTS */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Controller
              name="category"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <SelectBox
                    {...field}
                    requiredAstric={true}
                    fullWidth
                    disabled={type === "VIEW" ? true : false}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_category",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_category",
                    })}
                    size="mini"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["custom_field_category"]}
                  />
                </>
              )}
            />
            <Controller
              name="section"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <SelectBox
                    {...field}
                    fullWidth
                    e={field.name}
                    creatable={creatablePermission ? true : false}
                    disabled={type === "VIEW" ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_section",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_section",
                    })}
                    size="mini"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["custom_field_section"]}
                  />
                </>
              )}
            />
            <Controller
              name="position"
              control={control}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    fullWidth
                    e={field.name}
                    disabled={type === "VIEW" ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_position",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_position",
                    })}
                    size="mini"
                    value={field.value}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Controller
              name="name"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    requiredAstric={true}
                    fullWidth
                    disabled={type === "VIEW" ? true : false}
                    name={field.name}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_name",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_name",
                    })}
                    value={field.value}
                    size="mini"
                  />
                </>
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    fullWidth
                    e={field.name}
                    disabled={type === "VIEW" ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_desc",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_desc",
                    })}
                    value={field.value}
                    size="mini"
                  />
                </>
              )}
            />
            <Controller
              name="data_type"
              control={control}
              rules={{ required: "Required" }}
              render={({ field }) => (
                <>
                  <SelectBox
                    {...field}
                    fullWidth
                    name={field.name}
                    size="mini"
                    error={errors[field.name] && errors[field.name].message}
                    value={field.value}
                    label={intl.formatMessage({
                      id: "data_type",
                    })}
                    placeholder={intl.formatMessage({
                      id: "data_type",
                    })}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    options={referenceData["custom_data_type"]}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Controller
              name="sample_value"
              control={control}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    fullWidth
                    e={field.name}
                    disabled={type === "VIEW" ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_sample_value",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_sample_value",
                    })}
                    value={field.value}
                    size="mini"
                  />
                </>
              )}
            />
            <Controller
              name="placeholder_text"
              control={control}
              render={({ field }) => (
                <>
                  <TextBox
                    {...field}
                    fullWidth
                    e={field.name}
                    disabled={type === "VIEW" ? true : false}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "field_placeholder_text",
                    })}
                    placeholder={intl.formatMessage({
                      id: "field_placeholder_text",
                    })}
                    value={field.value}
                    size="mini"
                  />
                </>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "24px",
              // justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <Controller
              name={`is_generated`}
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  disabled={type === "VIEW" ? true : false}
                >
                  Is Field Value System Generated
                </CheckBoxBaseweb>
              )}
            />
            <Controller
              name={`is_editable`}
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  disabled={type === "VIEW" ? true : false}
                >
                  Is Field User Editable
                </CheckBoxBaseweb>
              )}
            />
            <Controller
              name={`is_hidden`}
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  checked={field.value}
                  disabled={type === "VIEW" ? true : false}
                >
                  Hide Field
                </CheckBoxBaseweb>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <div style={{ width: "100px" }}>
              <TextButton
                onClick={() => {
                  setAddCustomModal(false);
                }}
                kind={KIND.tertiary}
                size={SIZE.compact}
                type="button"
                fullWidth
              >
                Cancel
              </TextButton>
            </div>
            <div style={{ width: "100px" }}>
              <TextButton
                type="submit"
                kind={KIND.primary}
                size={SIZE.compact}
                fullWidth
                disabled={isSubmitting || type === "VIEW" ? true : false}
              >
                Save
              </TextButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCustomFieldListForm;
