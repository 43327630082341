import { Drawer } from "baseui/drawer";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useNumericFormat } from "react-number-format";
import _ from "lodash";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { Typography } from "../../components_v2";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";

const CustomFieldAudit = ({
  isOpen,
  setIsOpen,
  id,
  service,
  isAudit,
  isAuditCount,
  invNatureOfTax = [],
}) => {
  let intl = useIntl();
  let {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  const { data, isFetched, isError, refetch } = useQuery(
    [`CUSTOM_FIELD_AUDIT ${currentOrganization}-${id}-${isAuditCount}`],
    async () => {
      return await service({
        organization: currentOrganization,
        customFieldID: id,
        // customerId: getParams,
      });
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Drawer
      isOpen={isOpen}
      overrides={{
        DrawerContainer: {
          style: ({ $theme }) => ({
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "0px",
            marginTop: "0px",
            width: "440px",
          }),
        },
      }}
      autoFocus
      onClose={() => setIsOpen(false)}
    >
      <Typography
        type="h2"
        style={{
          marginBottom: "15px",
          lineHeight: "24px",
          fontSize: "20px",
          fontWeight: "500",
        }}
      >
        Audit
      </Typography>
      {_.get(data, "data.docs.data", []).map((apIn) => {
        let normalizedObject = Object.keys(apIn).reduce((prev, curr) => {
          let result = { ...prev };

          if (Array.isArray(apIn[curr])) {
            let innerObject = {};

            apIn[curr].map((cr) => {
              if (cr.label && cr.value) {
                innerObject[cr.label] = cr.value;
              }
            });

            result = { ...prev, ...innerObject };

            return result;
          } else if (typeof apIn[curr] === "object") {
            result = { ...prev, ...apIn[curr] };
          } else {
            result[curr] = apIn[curr];
          }

          return result;
        }, {});

        return (
          <>
            {Object.keys(normalizedObject).map((e, i) => {
              let resultLabel = "";
              let resultValue = "";

              let referenceKeys = [
                { key: "audby", rdName: "users" },
                { key: "data_type", rdName: "custom_data_type" },
                { key: "category", rdName: "custom_field_category" },
                { key: "cby", rdName: "users" },
                { key: "udy", rdName: "users" },
              ];

              let exculdeKeys = ["_id", "logo"];
              let dateKeys = ["cat", "udt"];
              let commissionAmt = ["camt"];
              let paymentColleted = ["pcat"];
              let enableKey = ["is_editable", "is_hidden", "is_genterated"];
              let isEnableKey = ["idb"];

              if (exculdeKeys.includes(e)) {
                return null;
              }

              if (Array.isArray(normalizedObject[e])) {
                return null;
              }

              if (referenceKeys.find((rk) => rk.key === e)) {
                let refKey = referenceKeys.find((rk) => rk.key === e);
                let rdData = referenceData[refKey.rdName]
                  ? referenceData[refKey.rdName].find(
                      (rd) => rd.id === normalizedObject[e]
                    )
                  : null;

                if (rdData && rdData.id) {
                  resultLabel = e;
                  resultValue = rdData.label;
                }
              } else {
                resultLabel = e;
                resultValue = normalizedObject[e];
              }

              if (dateKeys.includes(e)) {
                resultValue =
                  moment
                    .utc(resultValue)
                    .format(currentDefaultFormatDetails.date_format) +
                  " & " +
                  intl.formatTime(resultValue);
              }

              if (paymentColleted.includes(e)) {
                resultValue =
                  currencyList[currentDefaultFormatDetails.default_currency] +
                  numberFormat.format(
                    `${
                      apIn &&
                      apIn?.dt &&
                      apIn?.dt?.pcat &&
                      apIn?.dt?.pcat?.value
                    }`
                  );
              }

              if (commissionAmt.includes(e)) {
                resultValue =
                  currencyList[currentDefaultFormatDetails.default_currency] +
                  numberFormat.format(
                    `${
                      apIn &&
                      apIn?.dt &&
                      apIn?.dt?.camt &&
                      apIn?.dt?.camt?.value
                    }`
                  );
              }

              if (enableKey.includes(e)) {
                resultValue = resultValue ? "Yes" : "No";
              }

              if (isEnableKey.includes(e)) {
                resultValue = resultValue ? "Disabled" : "Enabled";
              }

              return (
                <div style={{ marginTop: "10px" }}>
                  <div className="payment_audit_dtl">
                    <Typography style={{ flex: "1", color: "#ADADAD" }}>
                      <FormattedMessage id={resultLabel}>
                        {resultLabel}
                      </FormattedMessage>
                    </Typography>
                    <Typography
                      style={{
                        flex: "2",
                        paddingLeft: "5px",
                      }}
                    >
                      {resultValue}
                    </Typography>
                  </div>
                </div>
              );
            })}
            <hr style={{ marginTop: "10px", borderTop: "1px solid #cdced9" }} />
          </>
        );
      })}
    </Drawer>
  );
};

export default CustomFieldAudit;
