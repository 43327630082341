import React, { useMemo, useState, useEffect, useRef, useContext } from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useFlexLayout,
  useResizeColumns,
  useExpanded,
  useFilters,
  useColumnOrder,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Loader from "../../components/Loader";
import DragDrop from "../../assets/img/svg/DragDrop";
import SortUp from "../../assets/img/svg/SortUp";
import SortDown from "../../assets/img/svg/SortDown";
import { Label2, ParagraphSmall, LabelLarge } from "baseui/typography";
import CardMerge from "../../components/Card";
import PaginationWraper from "../../components/Pagination/Pagination";
import { getGridStructure } from "../../services";

import _ from "lodash";
// import { customerData } from "./getUserList";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerSummaryGridStructure,
  updateTableDraftState,
} from "../../redux/customerSummary/action";
import { CUSTOMER_OVERVIEW, EDIT, VIEW } from "../../constants";
import CustomerAction from "../CustomerSummaryNew";
import { useParams } from "react-router-dom";
import columnMapper from "../../utils/mapper";
import { USERS } from "../../constants";
import { Input } from "baseui/input";
import { Search } from "baseui/icon";

import {
  deleteUsersData,
  disableUsersData,
  getUsersData,
  usersData,
} from "../../services/users";
import axios from "axios";
import DragDropHorizontal from "../../assets/img/svg/DragDropHorizontal";

import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import {
  editUserModal,
  refetchUser,
  setUserTableData,
  setUserFilter,
} from "../../redux/users/actions";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { IconButton, KIND, SIZE } from "../../components/IconButton";
import PaymentEdit from "../../assets/img/svg/PaymentEdit";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { useMutation } from "react-query";

import { Checkbox, STYLE_TYPE, LABEL_PLACEMENT } from "baseui/checkbox";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import TripleDot from "../../assets/img/svg/TripleDot";
import { toast } from "react-toastify";
import { ModalContext } from "../../providers/ModalProvider";
import warningImage from "../../assets/img/warning.png";
// import TabModalUser from "./TabModalUser";
import AllFilters from "../../components/AllFilters/AllFilters";
import {
  disableCustomFieldList,
  disableInvoiceItem,
  getCustomFieldAudit,
  getCustomFieldList,
  getInvoiceList,
  getInvoiceListAudit,
} from "../../services/invoiceItem";
import TaxRateAudit from "../Setting/TypeContainer/TaxRateMaintenance/TaxRateAudit";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  AUDIT_CUSTOM_FIELD,
  EDIT_CUSTOM_FIELD,
  EDIT_INVOICE_ITEM,
  ENABLE_DISABLE_CUSTOM_FIELD,
  ENABLE_DISABLE_INVOICE_ITEM,
  VIEW_AUDIT_INVOICE_ITEM,
  VIEW_CUSTOM_FIELD,
} from "../../providers/RBACProvider/permissionList";
import { SelectBox } from "../../components/SelectBox";
import { Select } from "baseui/select";
import CustomFieldAudit from "./CustomAudit";
import queryClient from "../../providers/queryClient";

const TableBody = ({
  page,
  prepareRow,
  getTableBodyProps,
  visibleColumns,
  setAddCustomModal,
  setEditingCustomData,
  setIsOpen,
  setIsAudit,
}) => {
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);

  // const [checked, setChecked] = React.useState(false);
  let { currentOrganization, referenceData, userInfo } =
    useContext(MaxyfiContext);
  const sessionInfoModal = useSelector((e) => e.userReducer.isSessionModalOpen);
  const disableUser = useMutation(
    (data) =>
      disableUsersData({ organization: currentOrganization, userId: data }),

    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context, error) => {
        //
        dispatch(refetchUser());
      },
    }
  );

  const disableCusField = useMutation(
    (data) =>
      disableCustomFieldList({
        organization: currentOrganization,
        customFieldID: data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context, error) => {
        dispatch(refetchUser());
        queryClient.invalidateQueries(
          `CUSTOM_FIELD_LIST-${currentOrganization}`
        );
      },
    }
  );

  return (
    <>
      {/* {sessionInfoModal ? <TabModalUser /> : <></>} */}
      <div
        className="customer__tabel__body"
        {...getTableBodyProps()}
        style={
          {
            // height: "calc(100vh - 50px)",
          }
        }
      >
        {page.map((row, index) => {
          prepareRow(row);
          const rowProps = row.getRowProps();
          return (
            <React.Fragment key={rowProps.key}>
              <div
                key={index}
                className={`table-main__body-row toggle-btn__relate ${
                  row.isExpanded ? "expanded" : ""
                }`}
                {...row.getRowProps()}
                // style={{ gap: "15px" }}
              >
                {/* <Link to={`/customer/${index}`}> */}
                {row.cells.map((cell) => {
                  return (
                    <div
                      className="table-main__body-cell"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </div>
                  );
                })}
                {/* popover CRUD start here*/}
                {/* {userInfo._id === row.original._id ? (
                  ""
                ) : ( */}
                <>
                  <div
                    className="user-end_icon-wraper"
                    // style={{
                    //   display: "flex",
                    //   alignItems: "center",
                    //   gap: "10px",
                    // }}
                    style={{ width: "7.5%" }}
                  >
                    <RBACWrapper
                      role={ENABLE_DISABLE_CUSTOM_FIELD}
                      type="PERMISSION"
                    >
                      <div>
                        <Checkbox
                          checked={!row?.original?.idb}
                          checkmarkType={STYLE_TYPE.toggle_round}
                          onChange={(e) =>
                            disableCusField.mutateAsync(row.original._id)
                          }
                          labelPlacement={LABEL_PLACEMENT.right}
                        />
                      </div>
                    </RBACWrapper>

                    {/* <Checkbox
                        checked={row.original.is_active}
                        checkmarkType={STYLE_TYPE.toggle_round}
                        onChange={(e) =>
                          disableUser.mutateAsync(row.original._id)
                        }
                        labelPlacement={LABEL_PLACEMENT.right}
                      /> */}
                    <StatefulPopover
                      content={
                        <div className="user-popover__btn">
                          <RBACWrapper
                            role={EDIT_CUSTOM_FIELD}
                            type="PERMISSION"
                          >
                            <ParagraphSmall
                              $style={{
                                cursor: "pointer",
                                padding: "5px 30px",
                                textAlign: "center",
                                borderBottom: "0.5px solid #CDCED9",
                              }}
                              onClick={() => {
                                // dispatch(editUserModal(row.original._id));

                                setAddCustomModal(true);
                                setEditingCustomData({
                                  type: EDIT,
                                  data: row && row.original,
                                });
                              }}
                            >
                              Edit
                            </ParagraphSmall>
                          </RBACWrapper>
                          <RBACWrapper
                            role={VIEW_CUSTOM_FIELD}
                            type="PERMISSION"
                          >
                            <ParagraphSmall
                              $style={{
                                cursor: "pointer",
                                padding: "5px 30px",
                                textAlign: "center",
                                borderBottom: "0.5px solid #CDCED9",
                              }}
                              onClick={() => {
                                // dispatch(editUserModal(row.original._id));

                                setAddCustomModal(true);
                                setEditingCustomData({
                                  type: VIEW,
                                  data: row && row.original,
                                });
                              }}
                            >
                              View
                            </ParagraphSmall>
                          </RBACWrapper>
                          <RBACWrapper
                            role={AUDIT_CUSTOM_FIELD}
                            type="PERMISSION"
                          >
                            <ParagraphSmall
                              $style={{
                                cursor: "pointer",
                                padding: "5px 30px",
                                textAlign: "center",
                              }}
                              onClick={() => {
                                setIsOpen(true);
                                setIsAudit({
                                  editData: row.original._id,
                                });
                              }}
                            >
                              Audit
                            </ParagraphSmall>
                          </RBACWrapper>

                          {/* <ParagraphSmall
                              $style={{
                                cursor: "pointer",
                                padding: "5px 30px",
                                textAlign: "center",
                              }}
                              // onClick={() => {
                              //   deleteUser.mutateAsync(row.original._id);
                              // }}
                              onClick={() => {
                                handleModal({
                                  closeButtonText: "Check",
                                  // To Delete
                                  title: "You are about to delete the User",
                                  content:
                                    "This will delete the user id permanently.",
                                  successCallback: () => {
                                    deleteUser.mutateAsync(row.original._id);
                                  },
                                  formContent: () => {
                                    return <></>;
                                  },
                                  isChildren: false,
                                  image: warningImage,
                                  buttonText: "Delete",
                                  closeButtonText: "Cancel",
                                  isCloseAble: false,
                                });
                              }}
                            >
                              {" "}
                              Delete{" "}
                            </ParagraphSmall> */}
                        </div>
                      }
                      accessibilityType={"tooltip"}
                      triggerType={TRIGGER_TYPE.hover}
                      overrides={{
                        Inner: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            background: "#ffffff",
                            border: "0.5px solid #CDCED9",
                          }),
                        },
                        Body: {
                          style: () => ({
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                            top: "-10px",
                          }),
                        },
                        Arrow: {
                          style: ({ $theme }) => ({
                            // zIndex: "9999",
                          }),
                        },
                      }}
                    >
                      {/* <span className="user-triple_btn">
                        <TripleDot />
                      </span> */}

                      <div
                        className="user-triple_btn"
                        style={{ padding: "10px 15px" }}
                      >
                        {/* <IconButton kind={KIND.tertiary} size={SIZE.compact}> */}
                        <TripleDot />
                        {/* </IconButton> */}
                      </div>
                    </StatefulPopover>
                  </div>
                </>
                {/* )} */}
                {/* popover CRUD start here*/}
                {/* </Link> */}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

const TableBodyMemo = React.memo(TableBody, (prevProps, nextProps) => {
  return nextProps.isColumnResizing;
});

const CustomFieldListTable = ({ setAddCustomModal, setEditingCustomData }) => {
  const [filterSearch, setFilterSearch] = useState("");
  const [isCategory, setIsCategory] = useState([]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [isAudit, setIsAudit] = useState({});

  let { currentOrganization, referenceData } = useContext(MaxyfiContext);
  const [columnData, setColumnData] = useState([]);
  const expandedRowId = useRef(null);
  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(0);
  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [width, setWidth] = useState(0);

  let ref = useRef();

  const dispatch = useDispatch();
  // TODO: VIGNESH
  let reduxData = useSelector((state) => state.customerSummary.tableState);

  let globalFilter = {
    ...(filterSearch.length ? { name: filterSearch } : {}),
    ...(isCategory?.[0]?.id ? { category: isCategory?.[0]?.id } : {}),
  };

  const { data, isFetched, isError, isLoading } = useQuery(
    [
      `CUSTOM_FIELD_LIST-${currentOrganization}`,
      {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: "name",
        filters: {
          ...globalFilter,
        },
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters } = queryKey[1];
      return await getCustomFieldList({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const userListApi = async () => {
      let userListVal = await getGridStructure({
        organization: currentOrganization,
        menu: "custom_field_list",
      });
      setColumnData(userListVal.data.doc.table);
    };
    userListApi();
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(Math.round(ref.current?.offsetWidth));
    }
  }, [ref]);

  const columns = useMemo(() => {
    let { columns } = columnMapper({
      columns: columnData,
      isExpanded: false,
      isSelection: false,
      isExpandedHeader: false,
      width: width - 150,
    });
    return columns;
  }, [columnData]);

  const tabledata = useMemo(
    () =>
      isFetched && !isError && data && data.data && data.data.docs
        ? data?.data?.docs
        : [],
    [isFetched, data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    pageCount,
    visibleColumns,
    state: { pageIndex, pageSize, sortBy, filters, columnResizing, expanded },
    prepareRow,
    selectedFlatRows,
    setHiddenColumns,
    setColumnOrder,
    allColumns,
    toggleAllRowsSelected,
    gotoPage,
    setPageSize,
    setSortBy,
    toggleRowExpanded,
  } = useTable(
    {
      columns,
      data: tabledata,
      manualFilters: true,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: isFetched && !isError && data.data ? data.data.totalPages : 0,
    },
    useFilters,
    useSortBy,
    useFlexLayout,
    useColumnOrder,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns
  );

  const currentColOrder = React.useRef();
  const isColumnResizing = !!columnResizing.isResizingColumn;

  useEffect(() => {
    if (reduxData.sort) {
      let isAsc = reduxData.sort.startsWith("-");
      setSortBy([{ id: reduxData.sort.replace("-", ""), desc: !isAsc }]);
    }
  }, [reduxData.sort]);

  useEffect(() => {
    let rowIds = Object.keys(expanded);

    if (rowIds.length > 1) {
      toggleRowExpanded(
        rowIds.filter((e) => e == expandedRowId.current),
        false
      );
    }

    expandedRowId.current = rowIds[0];
  }, [expanded]);

  useEffect(() => {
    if (!isColumnResizing) {
      dispatch(updateTableDraftState(allColumns));
    }
  }, [isColumnResizing, sortBy, filters]);

  useEffect(() => {
    setHiddenColumns(reduxData.hiddenColumns);
  }, [reduxData.hiddenColumns]);

  useEffect(() => {
    if (pageIndex == queryPageIndex) {
      gotoPage(0);
    }
    setQueryPageIndex(pageIndex);
    setQueryPageSize(0);
    setQuerySortBy(
      sortBy[0]
        ? sortBy[0].desc
          ? "-" + sortBy[0].id
          : "" + sortBy[0].id
        : "-_id"
    );
    let filterObj = {};
    filters.map((e) => {
      if (e.value) {
        if (Array.isArray(e.value)) {
          filterObj[e.id] = e.value.filter((val) => val);
        } else {
          filterObj[e.id] = e.value;
        }
      }
    });
    setQueryFilter(filterObj);
  }, [pageIndex, pageSize, sortBy, filters]);

  return (
    <>
      <div
        className="table-main user-table__main"
        {...getTableProps()}
        style={{ overflow: "hidden" }}
      >
        <div className="table-main-container" ref={ref}>
          <div className="table-main__head">
            {headerGroups.map((headerGroup) => (
              <DragDropContext
                onDragStart={() => {
                  currentColOrder.current = allColumns.map((i) => i.id);
                }}
                onDragUpdate={(dragUpdateObj, b) => {
                  const colOrder = [...currentColOrder.current];
                  const sIndex = dragUpdateObj.source.index;
                  const dIndex =
                    dragUpdateObj.destination &&
                    dragUpdateObj.destination.index;

                  if (
                    typeof sIndex === "number" &&
                    typeof dIndex === "number"
                  ) {
                    colOrder.splice(sIndex, 1);
                    colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                    setColumnOrder(colOrder);
                  }
                }}
              >
                <Droppable droppableId="droppable" direction="horizontal">
                  {(droppableProvided, snapshot) => (
                    <>
                      <div
                        className="table-main__head-container"
                        {...headerGroup.getHeaderGroupProps()}
                        ref={droppableProvided.innerRef}
                      >
                        <div
                          className="table-main__head-row"
                          style={{ justifyContent: "flex-start" }}
                        >
                          <div
                            style={{
                              width: "250px",
                              paddingLeft: "10px",
                              // marginRight: "0.5%"
                            }}
                          >
                            <Input
                              overrides={{
                                Root: {
                                  style: () => ({
                                    borderLeftColor: "#ceced9",
                                    borderRightColor: "#cecde9",
                                    borderTopColor: "#cecde9",
                                    borderBottomColor: "#cecde9",

                                    borderBottomLeftRadius: "5px",
                                    borderBottomRightRadius: "5px",
                                    borderTopLeftRadius: "5px",
                                    borderTopRightRadius: "5px",
                                    borderTopWidth: "1px",
                                    borderBottomWidth: "1px",
                                    borderLeftWidth: "1px",
                                    borderRightWidth: "1px",
                                    // marginRight: "20px",
                                    // marginTop: "20px",
                                    // paddingRight: "20px",
                                    // width: "182px",
                                    background: "white",
                                    height: "34px",
                                  }),
                                },
                                InputContainer: {
                                  style: () => ({
                                    background: "white",
                                  }),
                                },
                                EndEnhancer: {
                                  style: ({ $theme }) => ({
                                    padding: "0px",
                                    background: "white",
                                  }),
                                },
                              }}
                              size={SIZE.compact}
                              endEnhancer={<Search />}
                              placeholder="Search Field Name"
                              onChange={(val) => {
                                let value = val.target.value;
                                setFilterSearch(value);
                              }}
                              value={filterSearch}
                            />
                          </div>
                          <div
                            style={{
                              width: "250px",
                              paddingLeft: "10px",
                              // marginRight: "0.5%"
                            }}
                          >
                            <Select
                              placeholder="Select Field Category"
                              size={"compact"}
                              value={isCategory}
                              onChange={(e) => {
                                setIsCategory(e.value);
                              }}
                              overrides={{
                                ControlContainer: {
                                  style: ({ $theme, $isFocused }) => ({
                                    borderRadius: "5px",
                                    backgroundColor: $isFocused
                                      ? "#F5F6FA"
                                      : "#ffffff",
                                    borderRadius: "5px",
                                    borderColor: "#cecde9",
                                    borderWidth: "1px",
                                    borderStyle: "solid",
                                    paddingRight: "0px",
                                  }),
                                },
                              }}
                              options={referenceData["custom_field_category"]}
                            />
                          </div>
                        </div>
                        <div className="table-main__head-row">
                          {headerGroup.headers.map((column, index) => (
                            <Draggable
                              key={column.id}
                              draggableId={column.id}
                              index={index}
                              isDragDisabled={!column.accessor}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    {...provided.draggableProps}
                                    ref={provided.innerRef}
                                    className="table-main__head-cell-wrapper"
                                  >
                                    <div
                                      className="table-main__head-cell"
                                      {...column.getHeaderProps()}
                                    >
                                      {column.render("Header")}
                                      <div
                                        // className="table-main__head-cell-drag-n-drop"
                                        {...provided.dragHandleProps}
                                      >
                                        {/* {column.disableDND === true ? null : (
                                          <DragDropHorizontal />
                                        )} */}
                                      </div>

                                      {column.canResize && (
                                        <a
                                          className="table-main__head-cell-resizer"
                                          {...column.getResizerProps()}
                                        ></a>
                                      )}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </Droppable>
              </DragDropContext>
            ))}
          </div>
          {isLoading ? (
            <Loader />
          ) : tabledata && tabledata.length === 0 ? (
            <p
              style={{
                paddingTop: "10px",
                textAlign: "center",
                fontSize: "16px",
              }}
            >
              No Records Found
            </p>
          ) : (
            <TableBodyMemo
              isColumnResizing={isColumnResizing}
              page={page}
              prepareRow={prepareRow}
              getTableBodyProps={getTableBodyProps}
              visibleColumns={visibleColumns}
              setAddCustomModal={setAddCustomModal}
              setEditingCustomData={setEditingCustomData}
              setIsOpen={setIsOpen}
              setIsAudit={setIsAudit}
            />
          )}

          {/* <CustomerAction
            className="nested-ribbon"
            selectedFlatRows={selectedFlatRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
          /> */}
        </div>
      </div>
      {/* <PaginationWraper
        pageSize={pageSize}
        pageCount={pageCount}
        pageIndex={pageIndex}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
      /> */}
      {isOpen && (
        <CustomFieldAudit
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={isAudit && isAudit.editData}
          service={getCustomFieldAudit}
          queryKey="CUSTOM_FIELD_LIST_AUDIT"
        />
      )}
    </>
  );
};

export default CustomFieldListTable;
