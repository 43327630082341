import React, { useContext } from "react";
import { Paragraph2 } from "baseui/typography";
import { StatefulTooltip } from "baseui/tooltip";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import _, { values } from "lodash";
import { useMemo } from "react";
import useFormat from "../../../hooks/useFormat";
import { currencyList } from "../../../utils_v2/CurrencyToSymbol";

function CollectionTarget(props) {
  let { value, column, alignment, accessor, suffix, row, color, ...rest } =
    props;

  const format = useFormat();

  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current && ref.current.offsetWidth) {
      if (ref.current.offsetWidth < ref.current.scrollWidth) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [column.width]);

  value = _.get(row.original, accessor, "");

  return (
    <div style={{ padding: "11px" }}>
      <Paragraph2
        $style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: "100%",
          textAlign: alignment,
          color: "#333860",
          lineHeight: "16px",
        }}
      >
        {value ? currencyList[row?.original?.curr] : ""}
        {format.currency({
          amount: value,
        })}{" "}
      </Paragraph2>
    </div>
  );
}
export default CollectionTarget;
