import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { ADD_INVOICE_ITEM } from "../../providers/RBACProvider/permissionList";
import CustomFieldListTable from "../../containers/CustomFieldList/CustomFieldListTable";
import CustomFieldListFormModal from "../../containers/CustomFieldList/CustomFieldListFormModal";
import CustomFieldDrawer from "../../containers/CustomFieldList/CustomFieldDrawer";

const CustomFieldList = () => {
  const [addCustomModal, setAddCustomModal] = useState(false);
  const [edtingCustomData, setEditingCustomData] = useState({
    type: "",
    data: [],
  });

  return (
    <Layout>
      <div className="content-container">
        <div className="content-header">
          <div className="content-header__title">
            <PageTitle id="custom_field_maintenance" />
          </div>

          {/* <RBACWrapper role={ADD_INVOICE_ITEM} type="PERMISSION"> */}
          <div className="content-header__actions">
            <CustomFieldDrawer />
            <CustomFieldListFormModal
              addCustomModal={addCustomModal}
              setAddCustomModal={setAddCustomModal}
              edtingCustomData={edtingCustomData}
              setEditingCustomData={setEditingCustomData}
            />
          </div>
          {/* </RBACWrapper> */}
        </div>
        <div className="content-body">
          <CustomFieldListTable
            setAddCustomModal={setAddCustomModal}
            setEditingCustomData={setEditingCustomData}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CustomFieldList;
