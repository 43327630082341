import React, { useEffect } from "react";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import { useContext } from "react";
import { useState } from "react";
import { HeadingXSmall, ParagraphMedium } from "baseui/typography";
import moment from "moment";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Controller } from "react-hook-form";
import { TextBox } from "../../../components/TextBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { SIZE, TextArea } from "../../../components/TextArea";
import { useIntl } from "react-intl";
import InputTypes from "./InputTypes";
import setSelectValues from "../../../utils/setSelectValues";
import { Typography } from "../../../components_v2";

const InputType = ({
  type,
  control,
  name,
  currentDefaultFormatDetails,
  path,
  arrayKey,
  index,
}) => {
  const intl = useIntl();
  switch (type) {
    case "AMOUNT":
      return (
        <Controller
          name={`${path}.value`}
          control={control}
          key={path}
          render={({ field }) => (
            <TextBox
              {...field}
              type="number"
              name={field.name}
              size={SIZE.mini}
              label={name}
              placeholder={name}
              value={field.value}
            />
          )}
        />
      );
    case "NUMBER":
      return (
        <Controller
          name={path}
          control={control}
          key={path}
          render={({ field }) => (
            <TextBox
              {...field}
              type="number"
              name={field.name}
              size={SIZE.mini}
              label={name}
              placeholder={name}
              value={field.value}
            />
          )}
        />
      );
    case "DATE":
      return (
        <Controller
          name={path}
          control={control}
          key={path}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              size={SIZE.mini}
              label={name}
              placeholder={name}
              value={field.value}
              onChange={(e) => field.onChange(e.date)}
              minDate={new Date()}
            />
          )}
        />
      );
    case "LONG_TEXT":
      return (
        <Controller
          name={path}
          control={control}
          key={path}
          render={({ field }) => (
            <TextArea
              {...field}
              name={field.name}
              size={SIZE.mini}
              label={name}
              placeholder={name}
              value={field.value}
            />
          )}
        />
      );

    // case "FILE_ID":
    //   return (
    //     <Controller
    //       name="promise_type"
    //       control={control}
    //       rules={{ required: "Required" }}
    //       render={({ field }) => (
    //         <SelectBox
    //           {...field}
    //           name={field.name}
    //           error={errors[field.name] && errors[field.name].message}
    //           clearable={false}
    //           size={SIZE.mini}
    //           label={intl.formatMessage({
    //             id: "promise_type",
    //           })}
    //           placeholder={intl.formatMessage({
    //             id: "promise_type",
    //           })}
    //           value={field.value}
    //           onChange={(e) => {
    //             field.onChange(e.value);
    //           }}
    //           options={referenceData["promise_type"] || []}
    //         />
    //       )}
    //     />
    //   );

    default:
      return (
        <Controller
          name={path}
          control={control}
          key={path}
          render={({ field }) => (
            <TextBox
              {...field}
              name={field.name}
              size={SIZE.mini}
              label={name}
              placeholder={name}
              value={field.value}
            />
          )}
        />
      );
  }
};

const CustomFieldsTab = ({
  customFields = {},
  fieldLevel = [],
  width = "",
  wrapWidth = "",
  control,
  setValue,
  reSet,
}) => {
  const {
    currentDefaultFormatDetails,
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
  } = useContext(MaxyfiContext);

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  let result = {};
  for (let obj of fieldLevel) {
    let section = obj.section;
    if (result.hasOwnProperty(section)) {
      if (obj && !obj.idb) {
        result[section].push({ ...obj });
      }
    } else {
      if (obj && !obj.idb) {
        result[section] = [{ ...obj }];
      }
    }
  }

  let getObjectKey = Object.keys(result);

  const [isSectionActive, setIsSectionActive] = useState(
    getObjectKey && getObjectKey[0]
  );

  useEffect(() => {
    if (customFields && customFields?.custom_field) {
      for (let i = 0; i < fieldLevel.length; i++) {
        let path = fieldLevel[i].path;
        if (customFields && customFields.custom_field?.hasOwnProperty(path)) {
          if (fieldLevel?.[i]?.data_type === "DATE") {
            if (
              customFields &&
              customFields?.custom_field &&
              customFields?.custom_field?.[path]
            ) {
              setValue(
                path,
                new Date(customFields && customFields?.custom_field?.[path]) ||
                  null
              );
            }
          } else if (
            fieldLevel?.[i]?.data_type === "TEXT" &&
            fieldLevel?.[i]?.options?.length > 0
          ) {
            if (path && customFields && customFields?.custom_field?.[path]) {
              setValue(
                path,
                setSelectValues(
                  (customFields && customFields?.custom_field?.[path]) || []
                )
              );
            }
          } else if (fieldLevel?.[i]?.data_type === "AMOUNT") {
            if (
              path &&
              customFields &&
              customFields?.custom_field?.[path]?.value
            ) {
              setValue(
                `${path}.value`,
                customFields && customFields?.custom_field?.[path]?.value
              );
              setValue(
                `${path}.currency`,
                customFields?.custom_field?.[path]?.currency
              );
            }
          } else if (fieldLevel?.[i]?.data_type === "NUMBER") {
            if (path && customFields && customFields?.custom_field?.[path]) {
              setValue(path, customFields?.custom_field?.[path]);
            }
          } else {
            setValue(path, customFields?.custom_field?.[path]);
          }
        }
      }
    }
  }, [customFields?.custom_field]);

  return (
    <div style={{ width: width }}>
      <div
        style={{
          display: "flex",
          gap: "5px",
          flexDirection: "column",
        }}
      >
        {Object.keys(result)?.map((inv, i) => {
          return (
            <div>
              <>
                <Typography type="h4" className="mb-12">
                  {inv}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                  }}
                >
                  {result &&
                    result[inv].map((e, i) => {
                      return (
                        !e.is_hidden && (
                          <div style={{ width: "250px" }}>
                            <InputTypes
                              name={e.name}
                              control={control}
                              currentDefaultFormatDetails={
                                currentDefaultFormatDetails
                              }
                              type={e.data_type}
                              path={e.path}
                              index={i}
                              arrayKey={inv}
                              options={e.options}
                              disabled={e.is_editable}
                            />
                          </div>
                        )
                      );
                    })}
                </div>
              </>
              {/* <div
                className={`inv_custom_tab_mx ${
                  isSectionActive === inv && "--custom_inv_active"
                }`}
                onClick={() => {
                  setIsSectionActive(inv);
                }}
              >
                <ParagraphMedium>{inv}</ParagraphMedium>
                <div
                  style={{
                    display: "flex",
                    gap: "15px",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    marginBottom: "10px",
                  }}
                >
                  {result &&
                    result[inv] &&
                    result[inv].map((e) => {
                      return (
                        !e.is_hidden && (
                          <div style={{ width: wrapWidth }}>
                            <InputTypes
                              name={e.name}
                              control={control}
                              currentDefaultFormatDetails={
                                currentDefaultFormatDetails
                              }
                              type={e.data_type}
                              path={e.path}
                              options={e.options}
                            />
                          </div>
                        )
                      );
                    })}
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomFieldsTab;
