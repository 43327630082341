import React, { useContext, useEffect, useMemo, useState } from "react";
import { Drawer } from "baseui/drawer";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import { Icon, Typography } from "../../components_v2";
import { Plus } from "baseui/icon";
import { TextBox } from "../../components/TextBox";
import { SelectBox } from "../../components/SelectBox";
import { postCustomFieldList } from "../../services/invoiceItem";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import getSelectValues from "../../utils/getSelectValues";
import queryClient from "../../providers/queryClient";
import _ from "lodash";
import {
  ADD_CUSTOM_FIELD,
  ADD_LIST_VALUES,
  CUSTOM_FIELD_MAINTENANCE,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import BulkImportCustomField from "./BulkImportCustomField";
import setSelectValues from "../../utils/setSelectValues";
import { useSearchParams } from "react-router-dom";
const CustomFieldDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { referenceData, currentOrganization, space } =
    useContext(MaxyfiContext);
  const [isImportData, setIsImportData] = useState([]);
  const [getSearchParams, setGetSearchParams] = useSearchParams();

  const getParams = getSearchParams.get("ctm");

  // const validationSchema = Yup.object().shape({
  //   custom_field_list: Yup.array().of(
  //     Yup.object().shape({
  //       position: Yup.number()
  //         .typeError("Position must be a number")
  //         .required("Position is required"),
  //     })
  //   ),
  // });

  console.log("getParams", getParams);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      custom_field_list: [
        {
          position: 1,
          name: "",
          category: [
            {
              label: "Consumer Level",
              id: "CUSTOMER",
            },
          ],
          section: [],
          description: "",
          data_type: [],
          sample_value: "",
          placeholder_text: "",
          is_editable: false,
          is_hidden: false,
          is_generated: false,
        },
      ],
    },
  });

  let { custom_field_list } = watch();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "custom_field_list",
  });

  useEffect(() => {
    if (isImportData && Array.isArray(isImportData) && isImportData.length) {
      isImportData.map((e, i) => {
        update(i, {
          ...e,
          category: setSelectValues(e?.category),
          section: setSelectValues(e?.section),
          data_type: setSelectValues(e?.data_type),
          is_generated: e.is_generated === "TRUE" ? true : false,
          is_editable: e.is_editable === "TRUE" ? true : false,
          is_hidden: e.is_hidden === "TRUE" ? true : false,
        });
      });
    }
  }, [isImportData?.length]);

  const { sectionOption } = useMemo(() => {
    let getSection = [];

    if (Array.isArray(custom_field_list)) {
      custom_field_list.forEach((e) => {
        if (e && e.section && Array.isArray(e.section)) {
          getSection.push(e.section[0]);
        }
      });

      getSection = getSection
        .filter((e) => e && e.isCreatable === true)
        .map((e) => {
          let { isCreatable, ...rest } = e;
          return {
            ...rest,
          };
        });
    }

    let sectionOption = referenceData?.["custom_field_section"]?.length
      ? [...referenceData["custom_field_section"], ...getSection]
      : [...getSection];

    return { sectionOption };
  }, [custom_field_list.length]);

  const addMultiCustomField = useMutation(
    (data) =>
      postCustomFieldList({
        organization: currentOrganization,
        ...data,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        reset();
        queryClient.invalidateQueries(
          `CUSTOM_FIELD_LIST-${currentOrganization}`
        );
        setIsOpen(false);
      },
    }
  );

  const onSubmit = async (data) => {
    let value = {
      custom_field_list: data?.custom_field_list?.map((e) => {
        return {
          ...e,
          category: getSelectValues(e.category),
          data_type: getSelectValues(e.data_type),
          section: getSelectValues(e.section),
        };
      }),
    };
    await addMultiCustomField.mutateAsync(value);
  };

  const creatablePermission =
    space && space?.role_permissions?.includes(ADD_LIST_VALUES);

  console.log(
    "GET_MAX_HEL",
    sectionOption,
    referenceData["custom_field_category"],
    referenceData["custom_data_type"]
  );

  return (
    <>
      <RBACWrapper role={ADD_CUSTOM_FIELD} type="PERMISSION">
        <TextButton
          onClick={() => {
            setIsOpen(true);
          }}
          size="mini"
          $style={{ borderRadius: "5px", width: "170px" }}
        >
          <Plus /> Add New Custom Field
        </TextButton>
      </RBACWrapper>
      <Drawer
        isOpen={isOpen}
        size={"full"}
        autoFocus
        onClose={() => {
          reset();
          setIsOpen(false);
        }}
      >
        {getParams ? (
          <BulkImportCustomField setIsImportData={setIsImportData} />
        ) : (
          <></>
        )}

        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "15px" }}>
          <div className="custom-table">
            <table>
              <thead>
                <tr>
                  <th colSpan="1">
                    <Typography type="p">Position</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Name</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Category</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Section</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Desc</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Data Type</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Sample Value</Typography>
                  </th>
                  <th>
                    <Typography type="p">Field Placeholder Text</Typography>
                  </th>
                  <th>
                    <Typography type="p">System Generated</Typography>
                  </th>
                  <th>
                    <Typography type="p">User Editable</Typography>
                  </th>
                  <th>
                    <Typography type="p">Hide</Typography>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((e, i) => {
                  return (
                    <tr key={e.id} className="custom-field-row">
                      <td style={{ width: "65px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].position`}
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                type="number"
                                size="mini"
                                value={field.value}
                                error={
                                  _.get(
                                    errors,
                                    `custom_field_list[${i}].position.type`,
                                    ""
                                  ) === "required"
                                    ? true
                                    : false
                                }
                              />
                            </>
                          )}
                        />
                      </td>
                      <td style={{ borderRight: "1px solid #eeeeee" }}>
                        <Controller
                          name={`custom_field_list.[${i}].name`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                size="mini"
                                value={field.value}
                                error={_.get(
                                  errors,
                                  `custom_field_list[${i}].position.`
                                )}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td style={{ width: "160px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].category`}
                          control={control}
                          defaultValue={[
                            {
                              label: "Consumer Level",
                              id: "CUSTOMER",
                            },
                          ]}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <SelectBox
                                {...field}
                                fullWidth
                                name={field.name}
                                size="mini"
                                error={
                                  errors &&
                                  errors["custom_field_list"] &&
                                  errors["custom_field_list"][i] &&
                                  errors["custom_field_list"][i].category &&
                                  errors["custom_field_list"][i].category
                                    .message
                                }
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["custom_field_category"]}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`custom_field_list.[${i}].section`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <SelectBox
                                {...field}
                                creatable={creatablePermission ? true : false}
                                // fullWidth
                                size="mini"
                                // disabled={type === "VIEW" ? true : false}
                                error={
                                  errors &&
                                  errors["custom_field_list"] &&
                                  errors["custom_field_list"][i] &&
                                  errors["custom_field_list"][i].section &&
                                  errors["custom_field_list"][i].section.message
                                }
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={sectionOption}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`custom_field_list.[${i}].description`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                size="mini"
                                // disabled={type === "VIEW" ? true : false}
                                // error={errors[field.name] && errors[field.name].message}
                                value={field.value}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td style={{ width: "160px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].data_type`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <SelectBox
                                {...field}
                                fullWidth
                                name={field.name}
                                size="mini"
                                error={
                                  errors &&
                                  errors["custom_field_list"] &&
                                  errors["custom_field_list"][i] &&
                                  errors["custom_field_list"][i].data_type &&
                                  errors["custom_field_list"][i].data_type
                                    .message
                                }
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                options={referenceData["custom_data_type"]}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`custom_field_list.[${i}].sample_value`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                size="mini"
                                // disabled={type === "VIEW" ? true : false}
                                // error={errors[field.name] && errors[field.name].message}
                                value={field.value}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td>
                        <Controller
                          name={`custom_field_list.[${i}].placeholder_text`}
                          control={control}
                          render={({ field }) => (
                            <>
                              <TextBox
                                {...field}
                                fullWidth
                                size="mini"
                                // disabled={type === "VIEW" ? true : false}
                                // error={errors[field.name] && errors[field.name].message}
                                value={field.value}
                              />
                            </>
                          )}
                        />
                      </td>
                      <td style={{ width: "50px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].is_generated`}
                          control={control}
                          //   defaultValue={true}
                          render={({ field }) => (
                            <div>
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                // checkmarkType={STYLE_TYPE.toggle_round}
                                // disabled={e.rdis === true}
                              />
                            </div>
                          )}
                        />
                      </td>
                      <td style={{ width: "50px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].is_editable`}
                          control={control}
                          //   defaultValue={true}
                          render={({ field }) => (
                            <div>
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                // checkmarkType={STYLE_TYPE.toggle_round}
                                // disabled={e.rdis === true}
                              />
                            </div>
                          )}
                        />
                      </td>
                      <td style={{ width: "50px" }}>
                        <Controller
                          name={`custom_field_list.[${i}].is_hidden`}
                          control={control}
                          //   defaultValue={true}
                          render={({ field }) => (
                            <div>
                              <CheckBoxBaseweb
                                {...field}
                                checked={field.value}
                                // checkmarkType={STYLE_TYPE.toggle_round}
                                // disabled={e.rdis === true}
                              />
                            </div>
                          )}
                        />
                      </td>
                      {/* {fields?.length !== 1 ? (
                        
                      ) : (
                        <td></td>
                      )} */}
                      <td style={{ width: "40px" }}>
                        <Icon
                          icon="delete"
                          color="#fd372a"
                          isPressable
                          onClick={() => {
                            if (fields?.length !== 1) {
                              remove(i);
                            }
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan="6">
                    <TextButton
                      type="button"
                      kind={KIND.tertiary}
                      size={SIZE.mini}
                      startEnhancer={() => <Icon icon="plus" color="#516BEB" />}
                      onClick={() => {
                        append({
                          position:
                            Number(
                              watch(
                                `custom_field_list[${
                                  custom_field_list.length - 1
                                }].position`
                              )
                            ) + 1,
                          name: "",
                          category: [
                            {
                              label: "Consumer Level",
                              id: "CUSTOMER",
                            },
                          ],
                          section: [],
                          description: "",
                          data_type: [],
                          sample_value: "",
                          placeholder_text: "",
                          is_editable: false,
                          is_hidden: false,
                          is_generated: false,
                        });
                      }}
                    >
                      <span style={{ color: "#516beb" }}>
                        Add Another Field
                      </span>
                    </TextButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <TextButton
              type="button"
              kind={KIND.tertiary}
              size={SIZE.mini}
              style={{ color: "#516BEB", padding: "10px 35px" }}
              onClick={() => {
                reset();
              }}
            >
              Reset
            </TextButton>
            {/* <RBACWrapper
                        role={UPDATE_ORGANIZATION_SETTINGS}
                        type="PERMISSION"
                      > */}
            <TextButton
              type="submit"
              size={"mini"}
              style={{ padding: "10px 35px" }}
              isLoading={addMultiCustomField.isLoading}
              disabled={addMultiCustomField.isLoading}
            >
              Save
            </TextButton>
            {/* </RBACWrapper> */}
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default CustomFieldDrawer;
