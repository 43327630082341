// import { axiosWithAuth } from "../providers/MaxyfiProvider";
import axiosWithAuth from "../providers/AxiosInterceptor";

const APP_CODE = process.env.REACT_APP_APP_CODE;
const HOST = process.env.REACT_APP_HOST;
// const HOST = `http://192.168.1.47:3001/api/`;

export const postInvoiceItem = async ({ organization = null, ...rest }) => {
  return await axiosWithAuth.post(`${HOST}${organization}/invoice-item`, rest, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const getInvoiceList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/invoice-item`, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },

    // headers: {
    //   appCode: APP_CODE,
    // },
  });
};

export const editInvoiceList = async ({
  organization,
  invoiceItemId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/invoice-item/${invoiceItemId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const disableInvoiceItem = async ({ invoiceItemId, organization }) => {
  return await axiosWithAuth.patch(
    `${HOST}${organization}/invoice-item/${invoiceItemId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getInvoiceListAudit = async ({ organization = null, id }) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/invoice-item/${id}/audits`
  );
};

export const contactMaintenance = async ({ organization = null, data }) => {
  //
  return await axiosWithAuth.post(`${HOST}${organization}/contact-status`, {
    ...data,
  });
};

export const contactMaintenanceUpdate = async ({
  organization = null,
  createdId,
  data,
}) => {
  return await axiosWithAuth.put(
    `${HOST}${organization}/contact-status/${createdId}`,
    {
      ...data,
    }
  );
};
export const contactMaintenanceget = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters,
}) => {
  return await axiosWithAuth.get(`${HOST}${organization}/contact-status`, {
    params: {
      page: pageIndex + 1,
      sort: sortBy,
      page_size: pageSize,
      ...filters,
    },
  });
};
export const getStickyNotes = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize,
  filters,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/sticky-notes`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};

export const contactMaintenancegetAudit = async ({
  organization = null,
  auditId,
}) => {
  return await axiosWithAuth.get(
    `${HOST}${organization}/contact-status/audit/${auditId}`
  );
};

export const getCustomFieldList = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/custom-field-list`,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },

      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const postCustomFieldList = async ({ organization = null, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/custom-field-list`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const putCustomFieldList = async ({
  organization = null,
  customFieldID = null,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/custom-field-list/${customFieldID}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const disableCustomFieldList = async ({
  organization = null,
  customFieldID = null,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/custom-field-list/${customFieldID}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getCustomFieldAudit = async ({
  organization = null,
  customFieldID = null,
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/custom-field-list/${customFieldID}/audit`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};
