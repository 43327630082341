import React, { useState, useEffect, useCallback } from "react";
import {
  getUserProfile,
  getReferenceDataList,
  getOrganizationUsers,
  getUserPersonalization,
  getOrganization,
  getSpace,
  getRoleUsers,
} from "../services";
import { Routes, useNavigate, useSearchParams } from "react-router-dom";
import { getRoleMaintenance } from "../services/users";
import { CLIENT_PORTAL_ACCESS } from "./RBACProvider/permissionList";
import _ from "lodash";
import { WORK_QUEUE } from "../constants";
import {
  clearFromIndexedDB,
  getFromIndexedDB,
  isDataExpired,
  isRefDataRefeatch,
  openIndexedDB,
  resetIndexedDB,
  saveToIndexedDB,
} from "../views/ReportDeliverySettings/indexedDBUtil";

let referenceKeyName = "referenceData";
let spacekeyName = "space";

let devMode = process.env.REACT_APP_ENV;

const accessTypes = {
  CNT: { master_filter_type: "BUSINESS_UNIT", master_filter_value: [] },
  MYT: { master_filter_type: "TEAM", master_filter_value: [] },
  WKQ: { master_filter_type: WORK_QUEUE, master_filter_value: [] },
  SLF: { master_filter_type: "SELF", master_filter_value: [] },
};
const rdCount = process.env.REACT_APP_STORE_COUNT;

const MaxyfiContext = React.createContext({
  isLoading: true,
  isGlobalSearch: { active_tab: 0, value: "" },
  debounceValue: "",
  currentOrganization: null,
  userInfo: {},
  users: [],
  permissions: [],
  features: [],
  featureRoleList: [],
  roles: [],
  roleList: [],
  referenceData: [],
  userPersonalization: {},
  restrictions: {},
  refetch: () => {},
  rdRefetch: () => {},
  defaultData: {},
  switchCurrentOrganization: () => {},
  space: {
    org_count: 0,
    is_subscribe: false,
  },
  currencyFormat: " ",
  dateFormat: "",
  currentDefaultFormatDetails: {},
  currencyGroupStyle: {},
  currencySeparator: {},
  currencyDecimalSeparator: {},
  appAccessRest: { master_filter_type: "SELF", master_filter_value: [] },
  setFilterType: () => {},
  setClientGroupId: () => {},
  myTeam: [],
  orgBusinessUnit: [],
  orgWorkQueue: [],
  customCustomer: [],
  customInvoice: [],
  customContact: [],
  legalConfig: [],
});

const MaxyfiProvider = ({ children }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let authState = {
    // rootOrganization: keycloak.tokenParsed.org_id[0],
    accessToken: "",
    refreshToken: "",
    onTokenExpired: () => {},
  };

  const [state, setState] = useState({
    isLoading: true,
    isGlobalSearch: { active_tab: 0, value: "" },
    currentOrganization: authState.rootOrganization,
    debounceValue: "",
    globalSelectBy: [],
    userInfo: {},
    users: [],
    permissions: [],
    features: [],
    featureRoleList: [],
    roles: [],
    roleList: [],
    referenceData: [],
    userPersonalization: {},
    restrictions: {},
    defaultData: {},
    space: {
      org_count: 0,
      is_subscribe: false,
    },
    currencyFormat: "",
    dateFormat: "",
    currentDefaultFormatDetails: {},
    currencyGroupStyle: {},
    currencySeparator: {},
    currencyDecimalSeparator: {},
    myTeam: [],
    orgBusinessUnit: [],
    orgWorkQueue: [],
    appAccessRest: {
      master_filter_type: "SELF",
      master_filter_value: [],
      disable_client_value: [],
    },
    // below groupid state is dummy
    groupId: {
      disable_client_value: [],
    },
    clientGrp: {},
    switchCurrentOrganization: (data) => {
      setState((prevState) => {
        return {
          ...prevState,
          isLoading: true,
          currentOrganization: data.organizationId,
          currentDefaultFormatDetails: prevState.space.organizations.find(
            ({ id }) => id == data.organizationId
          ),
          appAccessRest: {
            master_filter_type: "SELF",
            master_filter_value: [],
          },
        };
      });

      setTimeout(() => {
        localStorage.setItem("currOrgId", data.organizationId);
        setState((prevState) => {
          let filterCustomer = [];
          let filterInvoice = [];
          let filterContact = [];

          let listOfOrganization = prevState.space.organizations.find(
            (i) => i.id === data.organizationId
          );

          if (
            listOfOrganization &&
            listOfOrganization.custom_field_list &&
            Array.isArray(listOfOrganization.custom_field_list)
          ) {
            filterCustomer = listOfOrganization.custom_field_list.filter(
              (cus) => cus.category == "CUSTOMER"
            );
            filterInvoice = listOfOrganization.custom_field_list.filter(
              (cus) => cus.category == "INVOICE"
            );
            filterContact = listOfOrganization.custom_field_list.filter(
              (cus) => cus.category == "CONTACT"
            );
          }

          return {
            ...prevState,
            isLoading: false,
            currentOrganization: data.organizationId,
            currentDefaultFormatDetails: prevState.space.organizations.find(
              ({ id }) => id == data.organizationId
            ),
            appAccessRest: {
              master_filter_type: "SELF",
              master_filter_value: [],
            },
            customCustomer: filterCustomer,
            customInvoice: filterInvoice,
            customContact: filterContact,
            legalConfig: listOfOrganization.lcl
              ? [...listOfOrganization.lcl]
              : [],
          };
        });
      }, 500);
    },
    refetch: async ({ setLoader = false, currentOrganization }) => {
      if (setLoader) {
        setState((prevState) => ({ ...prevState, isLoading: true }));
      }

      let rolePermission = [
        "organization_level_access",
        "all_business_units",
        "general_access",
        "add_views",
        "delete_views",
        "add_list_values",
      ];

      let newRolePermission = rolePermission.map((e) => {
        return { label: e, value: true };
      });

      const forceFetchData2 = async () => {
        try {
          const db = await openIndexedDB();
          await clearFromIndexedDB(db, spacekeyName);
          const data = await getSpace();
          await saveToIndexedDB(db, spacekeyName, data?.data?.doc);
          return data?.data?.doc;
        } catch (error) {
          console.error(error);
        }
      };

      let spaceDetails = await forceFetchData2();

      let userDetails = await getUserProfile();

      let orgUsers = await getOrganizationUsers();

      let roleRd = await getRoleMaintenance();

      const forceFetchData = async () => {
        try {
          const db = await openIndexedDB();
          await clearFromIndexedDB(db, referenceKeyName);
          const data = await getReferenceDataList({
            organizationId: currentOrganization,
          });
          await saveToIndexedDB(db, referenceKeyName, data?.data?.docs);
          return data?.data?.docs;
        } catch (error) {
          console.error(error);
        }
      };

      let getReferenceDataIDB = await forceFetchData();

      let referenceData = [...getReferenceDataIDB];

      let userPersonalization = await getUserPersonalization();

      if (userDetails.data.doc) {
        const users = orgUsers.data.docs.map((user) => ({
          id: user._id,
          displayName: user.display_name,
          organization: user.organization,
          email: user.email.value,
          roleId: user.role,
          active: user.is_active,
          phone: user.phone,
          is_client_login: user.is_client_login,
          business_unit: user.business_unit,
        }));

        let userRef = {
          rd_name: "users",
          data: users.map((user) => {
            return {
              id: user.id,
              label: user.displayName,
              email: user.email,
              active: user.active,
              organization: user.organization,
              is_client_login: user.is_client_login,
              business_unit: user.business_unit,
            };
          }),
        };

        let role = {
          rd_name: "user_role",
          data:
            roleRd &&
            roleRd.data &&
            roleRd.data.doc.map((rl) => {
              return {
                id: rl._id,
                label: rl.role_name,
              };
            }),
        };

        referenceData?.push(userDetails.data.doc.org_rd, userRef, role);

        let myTeam = userDetails?.data?.doc?.my_team?.map((mt) => {
          if (mt.org_id.includes(currentOrganization)) {
            return {
              id: mt._id,
              label: mt.label,
              is_client_login: mt.is_client_login,
            };
          }
        });

        setState((prevState) => {
          let currentOrganization = prevState.currentOrganization
            ? prevState.currentOrganization
            : userDetails.data.doc.organization[0];

          let orgBusinessUnit = userDetails?.data?.doc?.business_unit?.filter(
            (e) => e.business_unit_org_id == currentOrganization
          );

          let orgWorkQueue = userDetails?.data?.doc?.que?.filter(
            (e) => e.org == currentOrganization
          );

          let currentDefaultFormatDetails = spaceDetails.organizations.find(
            ({ id }) => id == currentOrganization
          );

          let currencyGroupStyle =
            currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.amount_format == "lakhs"
              ? "lakh"
              : currentDefaultFormatDetails &&
                currentDefaultFormatDetails?.amount_format == "million_cs_dd"
              ? "thousand"
              : "thousand";

          let currencySeparator =
            currentDefaultFormatDetails &&
            currentDefaultFormatDetails.amount_format == "million_ss_cd"
              ? " "
              : currentDefaultFormatDetails &&
                currentDefaultFormatDetails.amount_format == "million_ds_cd"
              ? "."
              : ",";

          let currencyDecimalSeparator =
            currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.amount_format == "million_ss_cd"
              ? ","
              : currentDefaultFormatDetails &&
                currentDefaultFormatDetails?.amount_format == "million_ds_cd"
              ? ","
              : ".";

          return {
            ...prevState,
            myTeam,
            orgBusinessUnit,
            orgWorkQueue,
            space: {
              ...prevState.space,
              ...spaceDetails,
              permissions: newRolePermission,
            },
            currentDefaultFormatDetails,
            currentOrganization,
            currencyGroupStyle,
            currencySeparator,
            currencyDecimalSeparator,

            isLoading: false,
            userInfo: userDetails.data.doc,
            users,
            userPersonalization:
              userPersonalization.data.doc.userPersonalization,
            features: userPersonalization.data.doc.features,
            featureRoleList: userPersonalization.data.doc.features
              ? userPersonalization.data.doc.features.reduce(
                  (prev, curr) => [...prev, ...curr.roles],
                  []
                )
              : [],
            roles: userPersonalization.data.doc.roles,
            roleList: userPersonalization.data.doc.roles
              ? userPersonalization.data.doc.roles.reduce(
                  (prev, curr) => [...prev, ...curr.roles],
                  []
                )
              : [],

            referenceData: referenceData.reduce(
              (prVal, crVal) => ({
                ...prVal,
                [crVal.rd_name]: [...crVal.data],
              }),
              {}
            ),
            restrictions: userPersonalization.data.doc.restrictions,
            defaultData: userPersonalization.data.doc.default_data,
          };
        });
      }
    },

    rdRefetch: async ({ setLoader = false, currentOrganization }) => {
      //
      if (setLoader) {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
      let referenceData = await getReferenceDataList({
        organizationId: currentOrganization,
      });

      setState((prevState) => {
        let userRd =
          prevState?.referenceData && prevState?.referenceData?.users;

        referenceData.push({ users: userRd });

        return {
          ...prevState,
          isLoading: false,
          referenceData: referenceData?.data?.docs?.reduce(
            (prVal, crVal) => ({
              ...prVal,
              [crVal.rd_name]: [...crVal.data],
            }),
            {}
          ),
          // referenceData:
        };
      });
    },
    setFilterType: ({
      master_filter_type,
      master_filter_value = [],
      master_client_group_value = null,
      disable_client_value = [],
    }) => {
      setState((prevState) => {
        let newArr = [];
        let clientGroup = {};
        let filterPrvData = [];
        let masterValue = [];
        let disableKeys = [];

        let filteredKey = Object.keys(prevState.clientGrp);

        if (prevState.appAccessRest.master_filter_type != master_filter_type) {
          return {
            ...prevState,
            appAccessRest: {
              master_filter_type: master_filter_type,
              master_filter_value: newArr,
            },
            groupId: {
              disable_client_value: [],
            },
            clientGrp: {},
          };
        } else if (filteredKey.includes(master_client_group_value)) {
          let filterKey = master_client_group_value;
          let objectData = prevState.clientGrp;
          filterPrvData = prevState.appAccessRest.master_filter_value.filter(
            (e) => !master_filter_value.includes(e)
          );

          delete objectData[filterKey];
          clientGroup = objectData;
          let deleteValue = [...new Set(Object.values(clientGroup).flat())];
          disableKeys = [...deleteValue];
          masterValue = [...new Set([...deleteValue, ...filterPrvData])];
        } else if (
          master_client_group_value &&
          Array.isArray(master_filter_value) &&
          master_filter_value.length > 0
        ) {
          clientGroup = {
            [master_client_group_value]: [...master_filter_value],
          };
          let prevNewArr = [...new Set(Object.values(clientGroup).flat())];
          masterValue = [
            ...new Set([
              ...prevState.appAccessRest.master_filter_value,
              ...prevNewArr,
            ]),
          ];
          let prevDisKey = [...new Set(Object.values(clientGroup).flat())];
          disableKeys = [
            ...new Set([
              ...prevState.groupId.disable_client_value,
              ...prevDisKey,
            ]),
          ];
        } else if (
          master_filter_value &&
          prevState.appAccessRest.master_filter_value.includes(
            master_filter_value
          )
        ) {
          newArr = prevState.appAccessRest.master_filter_value.filter(
            (e) => e !== master_filter_value
          );
          disableKeys = [...prevState.groupId.disable_client_value];
        } else {
          newArr = [
            ...prevState.appAccessRest.master_filter_value,
            ...(master_filter_value && master_filter_value.length
              ? [master_filter_value]
              : []),
          ];
          disableKeys = [...prevState.groupId.disable_client_value];
        }

        return {
          ...prevState,
          appAccessRest: {
            master_filter_type: master_filter_type,
            master_filter_value: [...masterValue, ...newArr],
          },
          groupId: {
            disable_client_value: [...disableKeys],
          },
          clientGrp: {
            ...prevState.clientGrp,
            ...clientGroup,
          },
        };
      });
    },

    setGlobalFilter: (props) => {
      setState((prevState) => {
        return {
          ...prevState,
          active_tab: props?.active_tab,
          isGlobalSearch: {
            active_tab: props?.active_tab,
            value: props?.value,
          },
        };
      });
    },

    setGlobalSearchDebounce: (props) => {
      setState((prevState) => {
        return {
          ...prevState,
          debounceValue: props,
        };
      });
    },

    setGlobalSelectBy: (props) => {
      setState((prevState) => {
        return {
          ...prevState,
          globalSelectBy: props,
        };
      });
    },
  });

  const getUserDetails = useCallback(async () => {
    const getSpaceData = async () => {
      try {
        const db = await openIndexedDB();
        let data = await getFromIndexedDB(db, spacekeyName);
        if (
          !data ||
          isDataExpired(data.timestamp) ||
          devMode === "development" ||
          isRefDataRefeatch(data.rdCount)
        ) {
          let spaceFeatch = await getSpace();
          data = { data: spaceFeatch?.data?.doc };
          await saveToIndexedDB(db, spacekeyName, data.data); // Save the new data in IndexedDB
        } else {
        }
        return data?.data;
      } catch (error) {
        console.error(error);
      }
    };

    let spaceDetails = await getSpaceData();

    if (!spaceDetails) {
      let referenceData = await getReferenceDataList();

      // navigate("/organization");

      setState({
        ...state,
        isLoading: false,
        referenceData: referenceData?.data?.docs.reduce(
          (prVal, crVal) => ({ ...prVal, [crVal.rd_name]: [...crVal.data] }),
          {}
        ),
      });

      return null;
    }

    let userDetails = await getUserProfile();

    // let myTeam = await getRoleUsers();

    let roleRd = await getRoleMaintenance();

    let orgUsers = await getOrganizationUsers();

    let userPersonalization = await getUserPersonalization();

    let rolePermission = [
      "organization_level_access",
      "all_business_units",
      "general_access",
      "add_views",
      "delete_views",
      "add_list_values",
    ];

    let currentOrganization = userDetails.data.doc.organization[0]
      ? userDetails.data.doc.organization[0]
      : null;

    let newRolePermission = rolePermission.map((e) => {
      // return { [e]: true };
      return { label: e, value: true };
    });

    // if (roleRd && roleRd.data && roleRd.data.doc) {
    //   let currentOrganization = spaceDetails.data.doc.organizations[0]
    //     ? spaceDetails.data.doc.organizations[0].id
    //     : null;

    //   let LScurrOrgId = localStorage.getItem("currOrgId");

    //   if (
    //     LScurrOrgId &&
    //     spaceDetails.data.doc.organizations.some((e) => e.id == LScurrOrgId)
    //   ) {
    //     currentOrganization = LScurrOrgId;
    //   }

    // }

    if (
      userPersonalization &&
      userPersonalization.data &&
      userPersonalization.data.doc &&
      userPersonalization.data.doc.userPersonalization &&
      userPersonalization.data.doc.userPersonalization.user_id
    ) {
      let locgetcurrUserId = localStorage.getItem("currUserId");
      if (
        locgetcurrUserId !==
        userPersonalization.data.doc.userPersonalization.user_id
      ) {
        resetIndexedDB({ dbKey: "referenceData" });
        resetIndexedDB({ dbKey: "space" });
        window.location.reload();
      }
      localStorage.setItem(
        "currUserId",
        userPersonalization.data.doc.userPersonalization.user_id
      );
    }

    if (userDetails.data.doc) {
      let LScurrOrgId = localStorage.getItem("currOrgId");

      if (
        LScurrOrgId &&
        userDetails.data.doc.organization.some((e) => e == LScurrOrgId)
      ) {
        currentOrganization = LScurrOrgId;
      } else {
        localStorage.setItem("currOrgId", currentOrganization);
      }

      // let userRole = referenceData.user_role;
      //
      const users = orgUsers.data.docs.map((user) => ({
        id: user._id,
        displayName: user.display_name,
        organization: user.organization,
        email: user.email.value,
        roleId: user.role,
        active: user.is_active,
        phone: user.phone,
        is_client_login: user.is_client_login,
        business_unit: user.business_unit,
      }));

      // users.push({
      //   id: userDetails.data.doc._id,
      //   displayName: userDetails.data.doc.display_name,
      //   email: userDetails.data.doc.email.value,
      // });

      let userRef = {
        rd_name: "users",
        data: users.map((user) => {
          return {
            id: user.id,
            label: user.displayName,
            active: user?.active,
            organization: user.organization,
            is_client_login: user.is_client_login,
            business_unit: user.business_unit,
          };
        }),
      };

      let myTeam = userDetails?.data?.doc?.my_team?.map((mt) => {
        if (mt.org_id.includes(currentOrganization)) {
          return {
            id: mt._id,
            label: mt.label,
            is_client_login: mt.is_client_login,
          };
        }
      });

      let orgBusinessUnit = userDetails?.data?.doc?.business_unit?.filter(
        (e) => e.business_unit_org_id == currentOrganization
      );
      let orgWorkQueue = userDetails?.data?.doc?.que?.filter(
        (e) => e.org == currentOrganization
      );

      let role = {
        rd_name: "user_role",
        data:
          roleRd &&
          roleRd.data &&
          roleRd.data.doc.map((rl) => {
            return {
              id: rl._id,
              label: rl.role_name,
            };
          }),
      };

      const getData1 = async () => {
        try {
          const db = await openIndexedDB();
          let data = await getFromIndexedDB(db, referenceKeyName);

          if (
            !data ||
            isDataExpired(data.timestamp) ||
            devMode === "development" ||
            localStorage.getItem("currOrgId") !== currentOrganization ||
            isRefDataRefeatch(data.rdCount)
          ) {
            let referenceDataFeatch = await getReferenceDataList({
              organizationId: currentOrganization,
            });
            data = { data: referenceDataFeatch?.data?.docs };
            await saveToIndexedDB(db, referenceKeyName, data?.data); // Save the new data in IndexedDB
          } else {
          }

          return data;
        } catch (error) {
          console.error(error);
        }
      };

      let getReferenceDataIDB = (await getData1()) || [];

      // if (localStorage.getItem("currOrgId") !== currentOrganization) {
      //   resetIndexedDB({ dbKey: "referenceData" });
      //   getReferenceDataIDB = (await getData1()) || [];
      // } else {
      //   getReferenceDataIDB = (await getData1()) || [];
      // }

      let referenceData = [...getReferenceDataIDB?.data];

      referenceData?.push(userDetails.data.doc.org_rd, userRef, role);

      let currentDefaultFormatDetails = spaceDetails.organizations.find(
        ({ id }) => id == currentOrganization
      );

      let currencyGroupStyle =
        currentDefaultFormatDetails &&
        currentDefaultFormatDetails?.amount_format == "lakhs"
          ? "lakh"
          : currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.amount_format == "million_cs_dd"
          ? "thousand"
          : "thousand";

      let currencySeparator =
        currentDefaultFormatDetails &&
        currentDefaultFormatDetails.amount_format == "million_ss_cd"
          ? " "
          : currentDefaultFormatDetails &&
            currentDefaultFormatDetails.amount_format == "million_ds_cd"
          ? "."
          : ",";

      let currencyDecimalSeparator =
        currentDefaultFormatDetails &&
        currentDefaultFormatDetails?.amount_format == "million_ss_cd"
          ? ","
          : currentDefaultFormatDetails &&
            currentDefaultFormatDetails?.amount_format == "million_ds_cd"
          ? ","
          : ".";

      let listOfOrganization = spaceDetails.organizations.find(
        (i) => i.id === currentOrganization
      );

      let filterCustomer;
      let filterInvoice;
      let filterContact;

      if (listOfOrganization && listOfOrganization.custom_field_list) {
        filterCustomer = listOfOrganization.custom_field_list.filter(
          (cus) => cus.category == "CUSTOMER"
        );
        filterInvoice = listOfOrganization.custom_field_list.filter(
          (cus) => cus.category == "INVOICE"
        );
        filterContact = listOfOrganization.custom_field_list.filter(
          (cus) => cus.category == "CONTACT"
        );
      }

      if (
        spaceDetails &&
        spaceDetails.app_message &&
        spaceDetails.app_message.is_trial_end
      ) {
        navigate("/pricing");
      }

      if (currentOrganization && spaceDetails) {
        if (!listOfOrganization?.is_accountBook_set) {
          navigate(`/accounting/${currentOrganization}`);
        } else if (!spaceDetails.is_subscribe) {
          navigate("/pricing");
        }
      } else {
        // navigate("/organization");
      }

      // let isGlobalSearch = false;
      // const q = searchParams.get("q");
      // if (q && q.length > 0) {
      //   isGlobalSearch = true;
      // }

      let accessArrType;
      if (spaceDetails && spaceDetails.role_permissions) {
        let clientLogin =
          spaceDetails.role_permissions.includes(CLIENT_PORTAL_ACCESS);

        if (_.get(userDetails, "data.doc.df_acc", "")) {
          accessArrType =
            accessTypes[_.get(userDetails, "data.doc.df_acc", "")];
        } else if (clientLogin) {
          accessArrType = {
            master_filter_type: "BUSINESS_UNIT",
            master_filter_value: [],
          };
        } else {
          accessArrType = {
            master_filter_type: "SELF",
            master_filter_value: [],
          };
        }
      }

      setState({
        ...state,
        isLoading: false,
        // isGlobalSearch,
        userInfo: userDetails.data.doc,
        userPersonalization: userPersonalization.data.doc.userPersonalization,
        features: userPersonalization.data.doc.features,
        featureRoleList: userPersonalization.data.doc.features
          ? userPersonalization.data.doc.features.reduce(
              (prev, curr) => [...prev, ...curr.roles],
              []
            )
          : [],
        roles: userPersonalization.data.doc.roles,
        roleList: userPersonalization.data.doc.roles
          ? userPersonalization.data.doc.roles.reduce(
              (prev, curr) => [...prev, ...curr.roles],
              []
            )
          : [],
        referenceData: referenceData?.reduce(
          (prVal, crVal) => ({ ...prVal, [crVal.rd_name]: [...crVal.data] }),
          {}
        ),
        restrictions: userPersonalization.data.doc.restrictions,
        defaultData: userPersonalization.data.doc.default_data,
        users,
        space: {
          ...state.space,
          ...spaceDetails,
          permissions: newRolePermission,
        },
        currentOrganization,
        currentDefaultFormatDetails,
        currencyGroupStyle,
        currencySeparator,
        currencyDecimalSeparator,
        myTeam,
        orgBusinessUnit,
        orgWorkQueue,
        customCustomer: filterCustomer ? [...filterCustomer] : [],
        customInvoice: filterInvoice ? [...filterInvoice] : [],
        customContact: filterContact ? [...filterContact] : [],
        appAccessRest: { ...accessArrType },
        legalConfig: listOfOrganization?.lcl ? [...listOfOrganization.lcl] : [],
      });

      return userDetails.data.doc;
    }
    return null;
  }, []);

  useEffect(() => {
    getUserDetails();
  }, []);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     let LScurrOrgId = localStorage.getItem("currOrgId");
  //     if (
  //       document.visibilityState === "visible" &&
  //       state.currentOrganization !== LScurrOrgId
  //     ) {
  //       resetIndexedDB({ dbKey: "referenceData" });
  //       resetIndexedDB({ dbKey: "space" });
  //       window.location.reload();
  //     }
  //   };
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, []);

  return (
    <MaxyfiContext.Provider value={state}>
      {state.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: "1",
            height: "100vh",
          }}
        >
          <p style={{ color: "#cdced9", fontSize: "30px" }}>Loading...</p>
        </div>
      ) : (
        children
      )}
    </MaxyfiContext.Provider>
  );
};

export { MaxyfiProvider, MaxyfiContext };
