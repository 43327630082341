import React, { useEffect, useRef } from "react";
import TitleHead from "../../components_v2/TitleHead";
import TimelineCardWraper, {
  TimelineChatCardWrapper,
} from "./TimelineCardWraper";
import {
  ACT_CALL_BACK_LATER,
  ACT_FIELD_VISIT,
  ACT_NOTES,
  ACT_PAYMENT_PLAN,
  ACT_PROMISE_TO_PAY,
  ACT_RECORD_DISPUTE,
  ACT_RETURN_VISIT,
  ACT_UNSUCCESSFUL,
  BANKRUPTCY,
  BUSINESS_UNIT,
  CALL,
  CALLBACK,
  CALL_CALL_BACK_LATER,
  CALL_DISPUTE,
  CALL_RECORDING,
  CALL_UNSUCCESSFUL,
  CONTACTS,
  CO_INVOICES_,
  CO_TIMELINE_,
  CUSTOMERPORTAL,
  CUSTOMERT_STATUS,
  CUSTOMER_NAME_UPDATE,
  CUSTOMER_OVERVIEW,
  CUSTOMER_OVERVIEW_,
  DATE_ORG,
  DISPUTE,
  EDIT,
  EDIT_CONUSMER_ACTION,
  EDIT_PROCESS_ACTION,
  EMAIL,
  ESIGN,
  ESIGNREPORT,
  FIELD_UNSUCCESSFUL,
  FIELD_VISIT,
  FORWARD,
  GENERIC_ACTION,
  HOLDACTION,
  INSTANT_CALL_PAYMENT,
  INVA_ADJ,
  INVOICE_UPDATE,
  LEGAL_AUDIT,
  LETTER,
  NEXTACTION,
  NOTES,
  PAYMENT,
  PAYMENTDETAILS,
  PAYMENT_PLAN,
  PORTAL_PAYMENT,
  PORTAL_PAYMENT_PLAN,
  PPL_CHNG,
  PROMISETOPAY,
  RELEASED,
  REPLY,
  REPLY_ALL,
  RESCHEDULE_NEXT_ACTION_DATE,
  REVERSE_PAYMENT,
  REVERSE_PAYMENT_TMD,
  SMS,
  TL_CLARIFICATION_REQUEST_INFO,
  TL_DOCUMENT_REQUEST_INFO,
  TL_FIELD_VISIT,
  TL_NOTES,
  TL_PAYMENT,
  TL_RELATION_MANAGER,
  TL_SKIP_ACTIONS,
  TL_UPDATE_PAYMENT_PLAN_DATE,
  TL_UPDATE_PROMISE_DATE,
  UNSUCCESSFUL,
  UN_RELATION_MANAGER,
  WORKFLOW,
  WORKFLOW_STRATEGY,
} from "../../constants";
import {
  deleteAdjustment,
  getCustomerOverviewTimelineList,
  getCustomerOverviewTimelineListV2,
  getDisputeContent,
  getEmailContentTimeline,
  getReviewPromiseDatas,
  markEmailAsReadCustomerTimeline,
} from "../../services";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { Icon, RDText, Typography } from "../../components_v2";
import QuillFrame from "../../components/QuillIframe/QuillIframe";
import TimelineCustomAction from "./TimelineCustomerAction";
import { FormattedMessage, FormattedTime } from "react-intl";
import DocumentCard from "../../components/DocumentCard/DocumentCard";
import {
  ADJUSTMENT_DRAWER,
  EMAIL_TIMELINE_LAYOUT,
  LEGAL_ACTION_DRAWER,
  REPLAY_SMS_DRAWER,
  WHATSAPP_CHAT,
  emailDataToDrawer,
  promiseTopayDataToDrawer,
  setDrawerState,
  smsDataToDrawer,
} from "../../redux/actions";
import { KIND, TextButton } from "../../components/TextButton";
import { toast } from "react-toastify";
import { TimelineCondensed } from "./Timeline";
import ChipFilterNew from "../../components/ChipFilterNew";
import CheckBoxBaseweb from "../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import InfoIcon from "../../assets/img/svg/InfoIcon";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Label3 } from "baseui/typography";
import { useDispatch, useSelector } from "react-redux";
import queryClient from "../../providers/queryClient";
import RDRapper from "../../components_v2/RDRapper";
import useFormat from "../../hooks/useFormat";
import {
  timelineChatRefetch,
  timelineWhatsappDrawer,
} from "../../redux/customerOverview/timeline/action";
import WhatsAppChat from "../../components/WhatsAppChat/WhatsAppChatV2";
import {
  DELETE_ADJUSTMENT,
  EDIT_ADJUSTMENT,
  EMAIL_REPLY,
  SMS_REPLY,
} from "../../providers/RBACProvider/permissionList";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import { currencyList } from "../../utils_v2/CurrencyToSymbol";
import jsPDF from "jspdf";
import DateComponent from "../../components_v2/DateComponent";
import axios from "axios";

const EmailDeliveryStatusTable = ({
  EmailTableData,
  currentDefaultFormatDetails,
  data,
}) => {
  const reasonList = [
    { reasonId: "550", label: "Mailbox Does Not Exist" },
    { reasonId: "5.1.1", label: "Mailbox Does Not Exist" },
    { reasonId: "552", label: "Message Too Large" },
    { reasonId: "5.3.4", label: "Message Too Large" },
    { reasonId: "5.2.2", label: "Mailbox Full" },
    { reasonId: "500", label: "Message Content Rejected" },
    { reasonId: "5.6.1", label: "Message Content Rejected" },
    { reasonId: "554", label: "Unknown Failure" },
    { reasonId: "5.0.0", label: "Unknown Failure" },
    { reasonId: "450", label: "Temporary Failure" },
    { reasonId: "4.0.0", label: "Temporary Failure" },
  ];

  return (
    <div>
      <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
        <strong>Email Delivery Status:</strong>
      </div>
      <table>
        <thead>
          <tr style={{ backgroundColor: "#FFFFFF" }}>
            <th>Email ID</th>
            <th>Status</th>
            <th>Time</th>
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {EmailTableData?.map((e, i) => {
            const deliveredDate = moment
              .utc(e?.delivered_on)
              .tz(currentDefaultFormatDetails.time_zone)
              .format(currentDefaultFormatDetails.date_format);

            const findingReason = reasonList.find(
              (i) => i?.reasonId === e?.reason
            );

            return (
              <tr key={i}>
                <td>{e?.value || ""}</td>
                <td style={{ color: e?.reason ? "#FD372A" : "#4CAF50" }}>
                  {e?.reason ? "Failed" : "Delivered"}
                </td>
                <td>{e?.delivered_on ? <>{deliveredDate}</> : null}</td>
                <td>{findingReason?.label ? findingReason?.label : ""}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <Label3>Email Read At :</Label3>
      </div>
      <table>
        <tr style={{ backgroundColor: "#FFFFFF" }}>
          <th>Read Time</th>
          <th>Read from IP Address</th>
        </tr>

        {[
          ...(data?.content?.open_status ? data?.content?.open_status : {}),
        ].map((e, i) => {
          // let date = e?.delivered_on?.replace("e", "");

          //
          let deliveredDate = moment
            .utc(e.open_at)
            ?.tz(currentDefaultFormatDetails.time_zone)
            ?.format(currentDefaultFormatDetails.date_format);

          return (
            <tr>
              {/* // PRODUCT */}
              <td>
                {/* <div style={{ width: "120px", marginTop: "15px" }}> */}
                {deliveredDate} <FormattedTime value={e?.open_at} />
                {/* </div> */}
              </td>
              {/* // DESCRIPTION */}
              <td>
                <div style={{ width: "" }}>
                  {e?.ip_address ? e.ip_address : ""}
                </div>
              </td>
              {/* // COST */}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const DeliveryStatus = ({ type }) => {
  const commonStyles = {
    fontSize: "12px",
    padding: "5px",
    borderBottomLeftRadius: "5px",
    borderTopRightRadius: "5px",
    cursor: "pointer",
    position: "absolute",
    right: 0,
    top: 0,
    background: "#FFFBE980",
    border: "1px solid #FFD93D",
    color: "#FFD93D",
    display: "flex",
    justifyContent: "center",
    gap: 3,
  };

  switch (type) {
    case "PARTIALLY_DELIVERED_AND_READ":
      return (
        <div
          style={{
            ...commonStyles,
          }}
        >
          <FormattedMessage id="Partially_Delivered_and_Read">
            Partially_Delivered_and_Read
          </FormattedMessage>
          <InfoIcon color="#FFD93D" size={15} />
        </div>
      );

    case "PARTIALLY_DELIVERED":
      return (
        <div
          style={{
            ...commonStyles,
          }}
        >
          <FormattedMessage id=" Partially_Delivered">
            Partially_Delivered
          </FormattedMessage>
          <InfoIcon color="#FFD93D" size={15} />
        </div>
      );

    case "BOUNCED":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#FFEBEB",
            border: "1px solid #E90E0E",
            color: "#E90E0E",
          }}
        >
          <FormattedMessage id="Bounced">Bounced</FormattedMessage>
          <InfoIcon color="#E90E0E" size={15} />
        </div>
      );

    case "DELIVERED":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Delivered">Delivered</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );

    case "READ":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Read">Read</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );
    case "SENT":
      return (
        <div
          style={{
            ...commonStyles,
            background: "#F0FFF1",
            border: "1px solid #48B24F",
            color: "#48B24F",
          }}
        >
          <FormattedMessage id="Sent">Sent</FormattedMessage>
          <InfoIcon color="#48B24F" size={15} />
        </div>
      );

    default:
      return null;
  }
};

const LetterCardStatus = ({ type }) => {
  switch (type) {
    case "TRANSIT":
      return (
        <Typography
          style={{
            color: "#FBBD04",
            background: "#FFF8E4",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Transit
        </Typography>
      );
    case "RETURNED":
      return (
        <Typography
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Returned
        </Typography>
      );

    case "DELIVERED":
      return (
        <Typography
          style={{
            color: "#0FB158",
            background: "#F1FFF7",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Delivered
        </Typography>
      );

    case "FAILED":
      return (
        <Typography
          style={{
            color: "#FD372A",
            background: "#FFE7E5",
            padding: "2px",
            borderRadius: "5px",
            textAlign: "center",
            height: "20px",
          }}
        >
          Failed
        </Typography>
      );

    default:
      return null;
  }
};

const ValueTypes = ({
  type,
  value = "",
  currency = {},
  referance = "",
  address = {},
  name = {},
  time = "",
}) => {
  const format = useFormat();
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);
  const getStatusColor = (status) => {
    switch (status) {
      case "Valid":
        return "#0FB158";
      case "Invalid":
        return "#E5AC16";
      case "DNC":
        return "#BA332B";
      default:
        return "";
    }
  };

  switch (type) {
    case "DATE":
      return (
        <Typography type="p" subType="regular">
          {value
            ? moment(value).format(currentDefaultFormatDetails.date_format)
            : "-"}
        </Typography>
      );
    case "DATEORGTZ":
      return (
        <Typography type="p" subType="regular">
          {value
            ? moment
                .utc(value)
                ?.tz(currentDefaultFormatDetails.time_zone)
                ?.format(currentDefaultFormatDetails.date_format)
            : "-"}
        </Typography>
      );
    case "CURRENCY":
      return (
        <Typography type="p" subType="regular">
          {(currency && currency.currency) || ""}{" "}
          {format.currency({ amount: currency && currency.value })}
        </Typography>
      );
    case "RDTEXT":
      return (
        <Typography type="p" subType="regular">
          {format.rd({ id: value, name: referance })}
        </Typography>
      );
    case "AMOUNT_DATE":
      return (
        <Typography type="p" subType="regular">
          {(currency && currency.currency) || ""}{" "}
          {format.currency({ amount: currency && currency.value })} &{" "}
          {value
            ? moment(value).format(currentDefaultFormatDetails.date_format)
            : "-"}
        </Typography>
      );
    case "ADDRESS":
      return (
        <Typography type="p" subType="regular">
          {address.line_1 === "-" ? "" : address.line_1}{" "}
          {address.line_2 === "-" ? "" : address.line_2}
          {address.state === "-" ? "" : address.state}{" "}
          {address.city === "-" ? "" : address.city}{" "}
          {address.country === "-" ? "" : address.country}
        </Typography>
      );
    // case "NAME":
    //   return (
    //     <>
    //       <Typography type="p" subType="regular">
    //         {name.name === "-" ? "" : name.name}{" "}
    //         {name.value === "-" ? "" : name.value}{" "}
    //         {name.designation === "-" ? "" : name.designation}{" "}
    //         {name.status === "-" ? "" : name.status}{" "}
    //       </Typography>
    //     </>
    //   );
    case "NAME":
      return (
        <>
          <Typography type="p" subType="regular">
            {_.get(name, "value", "")} -{" "}
            <span style={{ color: getStatusColor(_.get(name, "status")) }}>
              {_.get(name, "status", "")}
            </span>{" "}
            {_.get(name, "cmt", "") ? "-" : ""} {_.get(name, "cmt", "")}
          </Typography>
        </>
      );
    case "HYPERLINK":
      return (
        <Typography type="a" href={value} target="_blank">
          {value}
        </Typography>
        // <a href={value}>{value}</a>
      );
    default:
      return (
        <Typography type="p" subType="regular">
          {value || "-"}
        </Typography>
      );
  }
};

const ListLableValue = ({
  label = "",
  value = "",
  currency = {},
  referance = "",
  type = "",
  address = {},
  name = {},
  isLabelChild = "",
}) => {
  return (
    <>
      <div className="co_timeline_amount_wraper">
        <Typography
          type="p"
          className="text-secondary lable_contain"
          subType="regular"
        >
          {label}{" "}
          {isLabelChild && isLabelChild.length > 0 ? (
            <>
              <br />
              <span style={{ fontSize: "12px", color: "#adadad" }}>
                {isLabelChild}
              </span>
            </>
          ) : (
            <></>
          )}
        </Typography>
        <ValueTypes
          label={label}
          value={value}
          currency={currency}
          address={address}
          referance={referance}
          type={type}
          name={name}
        />
      </div>
    </>
  );
};

const StatusOfAction = ({
  actionName = null,
  is_hold_action = false,
  isConfirmationMsg = false,
  isPromiseFollowMsg = false,
  isCalender = false,
}) => {
  return (
    <>
      {is_hold_action ? (
        <Typography type="p" className="text-secondary mt-5">
          <FormattedMessage id={`hold_workflow_till_${actionName}`}>
            hold_workflow_till_promise_date
          </FormattedMessage>
        </Typography>
      ) : (
        <></>
      )}
      {isCalender ? (
        <Typography type="p" className="text-secondary mt-5">
          <FormattedMessage id="enable_calendar_alert">
            Enable Calendar Alert
          </FormattedMessage>
        </Typography>
      ) : (
        <></>
      )}
      {isConfirmationMsg ? (
        <Typography type="p" className="text-secondary mt-5">
          <FormattedMessage id="send_confirmation_message">
            send_confirmation_message
          </FormattedMessage>
        </Typography>
      ) : (
        <></>
      )}
      {isPromiseFollowMsg ? (
        <Typography type="p" className="text-secondary mt-5">
          <FormattedMessage id="send_promise_follow_up_message">
            send_promise_follow_up_message
          </FormattedMessage>
        </Typography>
      ) : (
        <></>
      )}
    </>
  );
};

const SmsAction = (props) => {
  const dispatch = useDispatch();
  const format = useFormat();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  return (
    <TimelineCardWraper
      {...props}
      icon="sms"
      title={
        <>
          SMS {_.get(props, "is_adhoc_action", false) ? "(QA)" : ""}{" "}
          {_.get(props, "is_receiver", false) ? "Reply" : props && props.name}
        </>
      }
    >
      <ListLableValue
        label={"Recipients:"}
        value={
          props?.content?.to.length > 0
            ? props.content.to
                .map(
                  (to) =>
                    `${_.capitalize(_.get(to, "name"))} ${to.value} ${format.rd(
                      {
                        id: _.get(to, "designation", ""),
                        name: "recipient_type",
                      }
                    )}`
                )
                .join(", ")
            : "-"
        }
      />
      <ListLableValue
        label={"Contents:"}
        value={_.get(props, "content.body")}
      />
      <RBACWrapper role={SMS_REPLY} type="PERMISSION">
        <div
          style={{
            display: "flex",
            gap: "35px",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Icon
              icon="replay"
              isPressable
              onClick={() => {
                dispatch(smsDataToDrawer(props));
                setDrawer(REPLAY_SMS_DRAWER, props);
              }}
            ></Icon>
          </div>
        </div>
      </RBACWrapper>
      {/* <QuillFrame content={props.content.body} /> */}
    </TimelineCardWraper>
  );
};

const PaymentPlanInstalmentList = ({ paymentId }) => {
  const { referenceData, currentOrganization, userInfo, space } =
    useContext(MaxyfiContext);
  const { customerId } = useParams();
  const { data } = useQuery(
    `timeLine_Payment_Plan-${paymentId}`,
    async () => {
      return await getReviewPromiseDatas({
        organization: currentOrganization,
        promiseId: customerId,
        payment_plan_id: paymentId,
        entity: "promise-to-pay",
      });
    },
    { refetchOnWindowFocus: false, refetchOnMount: false }
  );

  const format = useFormat();

  return (
    <div style={{ maxHeight: "200px", width: "100%", overflowY: "auto" }}>
      {_.get(data, "data.doc", [])
        .reverse()
        .map((item, i) => (
          <div
            key={i}
            style={{
              display: "flex",
              gap: "40px",
              marginTop: "10px",
            }}
          >
            <Typography type="p" className="text-secondary" subType="regular">
              {i + 1} Installment Amount & Date:
            </Typography>

            <Typography type="p" className="text-secondary" subType="regular">
              {item?.promise_amount?.currency}{" "}
              {format.currency({
                amount: _.get(item, "promise_amount.value", "-"),
              })}{" "}
              &{" "}
              {format.date({
                value: _.get(item, "promise_date", ""),
                isOrg: true,
              })}
            </Typography>
            {/* <div>
            <Typography
              type="p"
              className="text-secondary lable_contain"
              subType="regular"
            >
              {item?.status}
            </Typography>
          </div> */}
          </div>
        ))}
    </div>
  );
};

const PaymentPlanedList = (props) => {
  const [state, setState] = useState("");
  const [drawer, setDrawerOpen] = useState(false);
  const { customerId } = useParams();
  const format = useFormat();

  const handleDrawer = () => {
    setDrawerOpen(!drawer);
    // if (
    //   props &&
    //   props?.content &&
    //   props?.content?.payment_plan_id !== undefined
    // ) {
    //   setDrawerOpen(true);
    //   setState(props.content.payment_plan_id);
    // }
  };
  return (
    <>
      <Typography type="p" className="text-secondary mt-5">
        {_.get(props, "content.payment_plan_frequency", "-")} Payment plan with
        installment amount of{" "}
        {format.currency({
          amount: _.get(
            props,
            "content.payment_plan_instalation_amount.value",
            0
          ),
        })}{" "}
        {_.get(props, "content.payment_plan_instalation_amount.currency", "-")}{" "}
        starting from{" "}
        {props?.content.payment_plan_start_date
          ? moment(parseInt(props?.content.payment_plan_start_date)).format(
              "Do MMM YYYY"
            )
          : "-"}{" "}
        till{" "}
        {props?.content.payment_plan_end_date
          ? moment(parseInt(props?.content.payment_plan_end_date)).format(
              "Do MMM YYYY"
            )
          : "-"}{" "}
        <Typography type="a" onClick={() => handleDrawer()}>
          View payment Plans
        </Typography>
      </Typography>
      {drawer && (
        <PaymentPlanInstalmentList
          paymentId={props?.content?.payment_plan_id}
        />
      )}
    </>
  );
};

const CallAction = (props) => {
  const dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const format = useFormat();
  switch (props && props.content && props.content.call_outcome) {
    case PAYMENT_PLAN:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              Call - {_.get(props, "name", "-")} From{" "}
              {format.date({
                value: _.get(props, "content.payment_plan_start_date"),
              })}{" "}
              {format.rd({
                id: _.get(props, "content.promise_status", "-"),
                name: "promise_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Total payment amount:"}
            currency={{
              value: _.get(props, "content.payment_plan_amount.value", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Installment Amount:"}
            currency={{
              value: _.get(
                props,
                "content.payment_plan_instalation_amount.value",
                "-"
              ),
              currency: _.get(
                props,
                "content.payment_plan_instalation_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Planned Start Date:"}
            value={_.get(props, "content.payment_plan_start_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Planned Frequency:"}
            value={_.get(props, "content.payment_plan_frequency", "-")}
            // referance="payment_plan_frequency"
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.payment_plan_method", "-")}
            // referance="payment_plan_frequency"
          />
          <PaymentPlanedList {...props} />
          <StatusOfAction
            actionName={ACT_PAYMENT_PLAN}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case TL_FIELD_VISIT:
      return (
        <TimelineCardWraper
          {...props}
          icon="field_visit"
          title={
            <>
              {_.capitalize(_.get(props, "type", "-"))} -{" "}
              {_.get(props, "name", "-")} on{" "}
              {format.date({ value: _.get(props, "content.visit_date") })}{" "}
            </>
          }
        >
          <ListLableValue
            label={"Receiver:"}
            value={
              props?.content?.to.length > 0
                ? props.content.to
                    .map(
                      (to) =>
                        `${_.capitalize(_.get(to, "name"))} ${
                          to.value
                        } ${format.rd({
                          id: _.get(to, "designation", ""),
                          name: "recipient_type",
                        })}`
                    )
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Field Visit Date:"}
            value={_.get(props, "content.visit_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Field Visit Time:"}
            value={format.rd({
              id: _.get(props, "content.visit_time", "-"),
              name: "time",
            })}
          />
          <ListLableValue
            label={"Location:"}
            value={`${_.get(props, "content.visit_address.line_1", "")} ${_.get(
              props,
              "content.visit_address.line_2",
              ""
            )} ${_.get(props, "content.visit_address.city", "")} ${_.get(
              props,
              "content.visit_address.state",
              ""
            )} ${_.get(props, "content.visit_address.zip_code", "")} ${_.get(
              props,
              "content.visit_address.country",
              ""
            )}`}
            // address={{
            //   line_1: _.get(props, "content.visit_address.line1", "-"),
            //   line_2: _.get(props, "content.visit_address.line2", "-"),

            //   city: _.get(props, "content.visit_address.city", "-"),
            //   state: _.get(props, "content.visit_address.state", "-"),
            //   country: _.get(props, "content.visit_address.country", "-"),
            //   zip_code: _.get(props, "content.visit_address.zip_code", "-"),
            // }}
            // type={"ADDRESS"}
          />

          <StatusOfAction
            actionName={ACT_FIELD_VISIT}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case PROMISETOPAY:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "is_receiver", false) ? "Portal" : "Call"} -{" "}
              {_.get(props, "name", "")} by{" "}
              {format.date({
                value: _.get(props, "content.promise_date", "-"),
              })}{" "}
              {format.rd({
                id: _.get(props, "content.promise_status", ""),
                name: "promise_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Receiver:"}
            value={
              props?.content?.to.length > 0
                ? props.content.to
                    .map(
                      (to) =>
                        `${_.capitalize(_.get(to, "name"))} ${
                          to.value
                        } ${format.rd({
                          id: _.get(to, "designation", ""),
                          name: "recipient_type",
                        })}`
                    )
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Promise Type:"}
            value={_.get(props, "content.promise_type", "-")}
            type="RDTEXT"
            referance="promise_type"
          />

          <ListLableValue
            label={"Promise Amount:"}
            currency={{
              value: _.get(props, "content.promise_amount.value", "-"),
              currency: _.get(props, "content.promise_amount.currency", "-"),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Promise Date:"}
            value={_.get(props, "content.promise_date", "-")}
            type={"DATEORGTZ"}
          />

          {_.get(props, "content.cpcm", "") ? (
            <ListLableValue
              label={"Confirmation Message:"}
              value={`${_.get(
                props,
                "content.cpcm",
                "-"
              )} - Accepted by ${_.get(props, "cna", "-")} on ${format.date({
                value: _.get(props, "executed_on"),
                includeTime: true,
              })} `}
            />
          ) : (
            <></>
          )}

          <StatusOfAction
            actionName={ACT_PROMISE_TO_PAY}
            isCalender={_.get(props, "content.is_enable_calendar_alert", false)}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case CALL_CALL_BACK_LATER:
      const callbackTime = _.get(props, "content.callback_time", "");

      const formattedCallbackTime = callbackTime
        ? `at ${format.rd({
            name: "time",
            id: callbackTime,
          })}`
        : "";
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="callback_later"
            title={
              <>
                {_.get(props, "is_receiver", false) ? "Portal" : "Call"} -{" "}
                {_.get(props, "action_taken", "") === "CL_RESDUL"
                  ? "Rescheduled -"
                  : _.get(props, "action_taken", "") === "Deleted"
                  ? "Deleted -"
                  : ""}{" "}
                {_.get(props, "name", "")} on{" "}
                {format.date({ value: _.get(props, "content.callback_date") })}{" "}
                {formattedCallbackTime}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue
              label={"Callback Date:"}
              value={_.get(props, "content.callback_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Callback Time:"}
              value={
                props.content.callback_time
                  ? moment(props.content.callback_time, "h_mm_A").format(
                      "h:mm A"
                    )
                  : "-"
              }
              time={"TIME"}
            />
            <StatusOfAction
              actionName={ACT_CALL_BACK_LATER}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
              isConfirmationMsg={_.get(
                props,
                "content.is_send_confirmation_message",
                false
              )}
              isPromiseFollowMsg={_.get(
                props,
                "content.is_send_action_follow_up",
                false
              )}
            />

            {props && props?.upby && (
              <>
                <ListLableValue
                  label={"Delete By:"}
                  value={format.rd({ id: props && props?.upby, name: "users" })}
                />
                <ListLableValue
                  label={"Delete At:"}
                  value={_.get(props, "upat", "-")}
                  type={"DATEORGTZ"}
                />
              </>
            )}
          </TimelineCardWraper>
        </>
      );

    case CALL_DISPUTE:
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = props?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === props?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === props?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(props?.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(props?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...props.content,
        disputeSubject: `${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(props, "content.dispute_details", "")
            ? _.get(props, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          props.comment ? props.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };
      return (
        <TimelineCardWraper
          {...props}
          icon="dispute"
          title={
            <>
              Call: Dispute{" "}
              {_.get(props, "is_adhoc_action", false) ? "(QA)" : ""} -{" "}
              {_.get(props, "content.dispute_status", "") === "CLOSED" ? (
                <>
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Closed
                </>
              ) : _.get(props, "content.dispute_status", "") === "OPEN" ? (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Open
                </>
              ) : (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  {"-"} In-Progress
                </>
              )}
            </>
          }
        >
          <ListLableValue
            label="Dispute Type:"
            value={
              _.get(props, "content.dispute_type", "")
                ? format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })
                : ""
            }
          />
          <ListLableValue
            label="Assigned To:"
            value={
              _.get(props, "content.assigned_to", "")
                ? format.rd({
                    id: _.get(props, "content.assigned_to", ""),
                    name: "users",
                  })
                : "-"
            }
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.dispute_invoices", []).length > 0
                ? _.get(props, "content.dispute_invoices", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Amount:"
            value={
              _.get(props, "content.amt", null)
                ? `${
                    currencyList[currentDefaultFormatDetails.default_currency]
                  } ${format.currency({
                    amount: _.get(props, "content.amt", null),
                  })}`
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Detailed Text:"
            value={_.get(props, "content.dispute_details", "")}
          />
          <ListLableValue
            label="Status:"
            value={
              _.get(props, "content.dispute_status", "")
                ? _.capitalize(_.get(props, "content.dispute_status", ""))
                : "-"
            }
          />
          <ListLableValue
            label="Review Date"
            value={_.get(props, "content.dispute_review_date", "")}
            type="DATEORGTZ"
          />
          <StatusOfAction
            actionName={ACT_RECORD_DISPUTE}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
          />
          <RBACWrapper role={EMAIL_REPLY} type="PERMISSION">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Icon
                  icon="forward"
                  isPressable
                  onClick={async () => {
                    return await getDisputeContent({
                      organization: currentOrganization,
                      customerId: props?.customer_id,
                      disputeId: props?._id,
                    })
                      .then((res) => {
                        dispatch(
                          emailDataToDrawer({
                            emailType: FORWARD,
                            emailData: {},
                            subData: undefined,
                            ...(_.get(res, "data.doc.template_id", "")
                              ? {
                                  template_id: _.get(
                                    res,
                                    "data.doc.template_id",
                                    ""
                                  ),
                                }
                              : {}),
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: FORWARD,
                          subData: undefined,
                          ...(_.get(res, "data.doc.template_id", "")
                            ? {
                                template_id: _.get(
                                  res,
                                  "data.doc.template_id",
                                  ""
                                ),
                              }
                            : {}),
                          type: {
                            ...props.content,
                            type: DISPUTE,
                            disputeSubject: _.get(res, "data.doc.subject", ""),
                            disputeBody: _.get(res, "data.doc.value", ""),
                          },
                        });
                      })
                      .catch((err) =>
                        toast.error(_.get(err, "response.data.message", ""))
                      );
                  }}
                ></Icon>
              </div>
            </div>
          </RBACWrapper>
        </TimelineCardWraper>
      );
    case TL_NOTES:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="note"
            title={
              <>
                {_.capitalize(_.get(props, "type", ""))}:{" "}
                {format.rd({
                  id: _.get(props, "name", "_"),
                  name: "notes_category",
                })}{" "}
                {_.get(props, "action_taken", "_")}{" "}
              </>
            }
          >
            {/* <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            /> */}
            <ListLableValue
              label="Content:"
              value={_.get(props, "comment", "")}
            />
            <ListLableValue
              label="Category:"
              value={_.get(props, "content.notes_category", "")}
              referance="notes_category"
              type="RDTEXT"
            />
            <ListLableValue
              label="Follow-up on:"
              value={_.get(props, "content.notes_follow_up", "")}
              type="DATEORGTZ"
            />
            <ListLableValue
              label="Type:"
              value={_.capitalize(_.get(props, "content.notes_type", ""))}
            />
            <StatusOfAction
              actionName={ACT_NOTES}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            />
          </TimelineCardWraper>
        </>
      );

    case PAYMENT:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="payment"
            title={
              <>
                {_.capitalize(_.get(props, "type", "-"))} -{" "}
                {_.get(props, "name", "-")}{" "}
                {_.get(props, "content.portal_payment_amount.currency", "-")}{" "}
                {_.get(props, "content.portal_payment_amount.value", "-")} via{" "}
                {_.get(props, "content.portal_payment_gateway_name", "-")} {"-"}{" "}
                {_.get(props, "action_taken", "-")}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            {_.get(props, "content.crd_lst_4", "") ? (
              <ListLableValue
                label={"Card Last 4 Digit:"}
                value={_.get(props, "content.crd_lst_4", "-")}
              />
            ) : (
              <></>
            )}
            {_.get(props, "content.op_acc_nam", "") ? (
              <ListLableValue
                label={"Account Name:"}
                value={_.get(props, "content.op_acc_nam", "-")}
              />
            ) : (
              <></>
            )}
            {_.get(props, "content.exp_dt", "") ? (
              <ListLableValue
                label={"Expiry Date:"}
                value={_.get(props, "content.exp_dt", "-")}
              />
            ) : (
              <></>
            )}
            {_.get(props, "content.op_rout_num", "") ? (
              <ListLableValue
                label={"Routing Number:"}
                value={_.get(props, "content.op_rout_num", "-")}
              />
            ) : (
              <></>
            )}
            {_.get(props, "content.conv_fee", "") ? (
              <ListLableValue
                label={"Processing Fee:"}
                value={_.get(props, "content.conv_fee", "-")}
              />
            ) : (
              <></>
            )}

            <ListLableValue
              label={"Total Paid Amount:"}
              currency={{
                value: _.get(props, "content.tot_pd_amt", "-"),
                currency: _.get(
                  props,
                  "content.portal_payment_amount.currency",
                  "-"
                ),
              }}
              type={"CURRENCY"}
            />
            <ListLableValue
              label={"Payment Amount:"}
              currency={{
                value: _.get(props, "content.portal_payment_amount.value", "-"),
                currency: _.get(
                  props,
                  "content.portal_payment_amount.currency",
                  "-"
                ),
              }}
              type={"CURRENCY"}
            />
            <ListLableValue
              label={"Payment Date:"}
              value={_.get(props, "content.portal_payment_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Payment method:"}
              value={_.get(props, "content.portal_payment_gateway_name", "-")}
            />
            <ListLableValue
              label={"Payment Reference:"}
              value={_.get(props, "content.portal_payment_reference", "-")}
            />
            <ListLableValue
              label={"Invoice#:"}
              value={_.get(props, "content.portal_payment_invoice_number", [
                "-",
              ])
                .map((value) => value.value || value)
                .join(",")}
            />
          </TimelineCardWraper>
        </>
      );

    case CALL_UNSUCCESSFUL:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="call_unsuccessfull"
            title={
              <>
                {_.capitalize(_.get(props, "type", "-"))}-{" "}
                {_.get(props, "name", "-")}:{" "}
                {_.startCase(
                  _.capitalize(
                    _.get(props, "content.call_unsuccessful_reason", "-")
                  )
                )}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue
              label={"UnSuccessful Reason:"}
              value={format.rd({
                name: "call_unsuccessful_reason",
                id: _.get(props, "content.call_unsuccessful_reason", "-"),
              })}
            />
            <ListLableValue
              label={"Follow Up Date:"}
              value={
                props?.content?.followup_date
                  ? format.date({ value: props?.content?.followup_date })
                  : "-"
              }
            />
            <StatusOfAction
              actionName={ACT_UNSUCCESSFUL}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
              isConfirmationMsg={_.get(
                props,
                "content.is_send_confirmation_message",
                false
              )}
            />
          </TimelineCardWraper>
        </>
      );

    default:
      return null;
  }
};

const CaptureAction = (props) => {
  const format = useFormat();
  const dispatch = useDispatch();
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  switch (_.get(props, "content.outcome", "")) {
    case PAYMENT_PLAN:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "action_source", "")} - {_.get(props, "name", "-")}{" "}
              From{" "}
              {format.date({
                value: _.get(props, "content.payment_plan_start_date", "-"),
              })}{" "}
              {format.rd({
                id: _.get(props, "content.payment_plan_status", "-"),
                name: "payment_plan_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Total payment amount:"}
            currency={{
              value: _.get(props, "content.payment_plan_amount.value", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Installment Amount:"}
            currency={{
              value: _.get(
                props,
                "content.payment_plan_instalation_amount.value",
                "-"
              ),
              currency: _.get(
                props,
                "content.payment_plan_instalation_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.dispute_invoices", []).length > 0
                ? _.get(props, "content.dispute_invoices", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Planned Start Date:"}
            value={_.get(props, "content.payment_plan_start_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Planned Frequency:"}
            value={_.get(props, "content.payment_plan_frequency", "-")}
            // referance="payment_plan_frequency"
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.payment_plan_method", "-")}
            // referance="payment_plan_frequency"
          />
          <PaymentPlanedList {...props} />
          <StatusOfAction
            actionName={ACT_PAYMENT_PLAN}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case TL_FIELD_VISIT:
      return (
        <TimelineCardWraper
          {...props}
          icon="field_visit"
          title={
            <>
              {_.capitalize(_.get(props, "action_source", ""))} -{" "}
              {_.get(props, "name", "-")} on{" "}
              {format.date({ value: _.get(props, "content.visit_date") })}{" "}
            </>
          }
        >
          <ListLableValue
            label={"Receiver:"}
            value={
              props?.content?.to.length > 0
                ? props.content.to
                    .map(
                      (to) =>
                        `${_.capitalize(_.get(to, "name"))} ${
                          to.value
                        } ${format.rd({
                          id: _.get(to, "designation", ""),
                          name: "recipient_type",
                        })}`
                    )
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Field Visit Date:"}
            value={_.get(props, "content.visit_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Field Visit Time:"}
            value={format.rd({
              id: _.get(props, "content.visit_time", "-"),
              name: "time",
            })}
          />
          <ListLableValue
            label={"Location:"}
            value={`${_.get(props, "content.visit_address.line_1", "")} ${_.get(
              props,
              "content.visit_address.line_2",
              ""
            )} ${_.get(props, "content.visit_address.city", "")} ${_.get(
              props,
              "content.visit_address.state",
              ""
            )} ${_.get(props, "content.visit_address.zip_code", "")} ${_.get(
              props,
              "content.visit_address.country",
              ""
            )}`}
            // address={{
            //   line_1: _.get(props, "content.visit_address.line1", "-"),
            //   line_2: _.get(props, "content.visit_address.line2", "-"),

            //   city: _.get(props, "content.visit_address.city", "-"),
            //   state: _.get(props, "content.visit_address.state", "-"),
            //   country: _.get(props, "content.visit_address.country", "-"),
            //   zip_code: _.get(props, "content.visit_address.zip_code", "-"),
            // }}
            // type={"ADDRESS"}
          />

          <StatusOfAction
            actionName={ACT_FIELD_VISIT}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case PROMISETOPAY:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "is_receiver", false)
                ? "Portal"
                : _.get(props, "action_source", "")}{" "}
              - {_.get(props, "name", "")} by{" "}
              {format.date({ value: _.get(props, "content.promise_date") })}{" "}
              {format.rd({
                name: "promise_status",
                id: _.get(props, "content.promise_status", ""),
              })}
            </>
          }
        >
          <ListLableValue
            label={"Receiver:"}
            value={
              props?.content?.to.length > 0
                ? props.content.to
                    .map(
                      (to) =>
                        `${_.capitalize(_.get(to, "name"))} ${
                          to.value
                        } ${format.rd({
                          id: _.get(to, "designation", ""),
                          name: "recipient_type",
                        })}`
                    )
                    .join(", ")
                : "-"
            }
          />

          <ListLableValue
            label={"Promise Type:"}
            value={_.get(props, "content.promise_type", "-")}
            type="RDTEXT"
            referance="promise_type"
          />

          <ListLableValue
            label={"Promise Amount:"}
            currency={{
              value: _.get(props, "content.promise_amount.value", "-"),
              currency: _.get(props, "content.promise_amount.currency", "-"),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Promise Date:"}
            value={_.get(props, "content.promise_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <StatusOfAction
            actionName={ACT_PROMISE_TO_PAY}
            isCalender={_.get(props, "content.is_enable_calendar_alert", false)}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    case CALL_CALL_BACK_LATER:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="callback_later"
            title={
              <>
                {_.get(props, "is_receiver", false)
                  ? "Portal"
                  : _.get(props, "action_source", "")}{" "}
                - {_.get(props, "name", "")} on{" "}
                {format.date({ value: _.get(props, "content.callback_date") })}{" "}
                at{" "}
                {format.rd({
                  id: _.get(props, "content.callback_time", ""),
                  name: "time",
                })}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue
              label={"Callback Date:"}
              value={_.get(props, "content.callback_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Callback Time:"}
              value={
                props.content.callback_time
                  ? moment(props.content.callback_time, "h_mm_A").format(
                      "h:mm A"
                    )
                  : "-"
              }
              time={"TIME"}
            />
            <StatusOfAction
              actionName={ACT_CALL_BACK_LATER}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
              isConfirmationMsg={_.get(
                props,
                "content.is_send_confirmation_message",
                false
              )}
              isPromiseFollowMsg={_.get(
                props,
                "content.is_send_action_follow_up",
                false
              )}
            />
          </TimelineCardWraper>
        </>
      );

    case CALL_DISPUTE:
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = props?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === props?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === props?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(props?.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails?.date_format);

      const timezoneBasedDate = moment
        .utc(props?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...props.content,
        disputeSubject: `${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(props, "content.dispute_details", "")
            ? _.get(props, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          props.comment ? props.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };
      return (
        <TimelineCardWraper
          {...props}
          icon="dispute"
          title={
            <>
              {_.get(props, "action_source", "")}: Dispute{" "}
              {_.get(props, "is_adhoc_action", false) ? "(QA)" : ""} -{" "}
              {_.get(props, "content.dispute_status", "") === "CLOSED" ? (
                <>
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Closed
                </>
              ) : _.get(props, "content.dispute_status", "") === "OPEN" ? (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Open
                </>
              ) : (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  {"-"} In-Progress
                </>
              )}
            </>
          }
        >
          <ListLableValue
            label="Dispute Type:"
            value={
              _.get(props, "content.dispute_type", "")
                ? format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })
                : ""
            }
          />
          <ListLableValue
            label="Assigned To:"
            value={
              _.get(props, "content.assigned_to", "")
                ? format.rd({
                    id: _.get(props, "content.assigned_to", ""),
                    name: "users",
                  })
                : "-"
            }
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.dispute_invoices", []).length > 0
                ? _.get(props, "content.dispute_invoices", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Amount:"
            value={
              _.get(props, "content.amt", null)
                ? `${
                    currencyList[currentDefaultFormatDetails.default_currency]
                  } ${format.currency({
                    amount: _.get(props, "content.amt", null),
                  })}`
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Detailed Text:"
            value={_.get(props, "content.dispute_details", "")}
          />
          <ListLableValue
            label="Satus:"
            value={
              _.get(props, "content.dispute_status", "")
                ? _.capitalize(_.get(props, "content.dispute_status", ""))
                : "-"
            }
          />
          <ListLableValue
            label="Review Date:"
            value={_.get(props, "content.dispute_review_date", "")}
            type="DATEORGTZ"
          />
          <StatusOfAction
            actionName={ACT_RECORD_DISPUTE}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
          />
          <RBACWrapper role={EMAIL_REPLY} type="PERMISSION">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Icon
                  icon="forward"
                  isPressable
                  onClick={async () => {
                    return await getDisputeContent({
                      organization: currentOrganization,
                      customerId: props?.customer_id,
                      disputeId: props?._id,
                    })
                      .then((res) => {
                        dispatch(
                          emailDataToDrawer({
                            emailType: FORWARD,
                            emailData: {},
                            subData: undefined,
                            ...(_.get(res, "data.doc.template_id", "")
                              ? {
                                  template_id: _.get(
                                    res,
                                    "data.doc.template_id",
                                    ""
                                  ),
                                }
                              : {}),
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: FORWARD,
                          subData: undefined,
                          ...(_.get(res, "data.doc.template_id", "")
                            ? {
                                template_id: _.get(
                                  res,
                                  "data.doc.template_id",
                                  ""
                                ),
                              }
                            : {}),
                          type: {
                            ...props.content,
                            type: DISPUTE,

                            disputeSubject: _.get(res, "data.doc.subject", ""),
                            disputeBody: _.get(res, "data.doc.value", ""),
                          },
                        });
                      })
                      .catch((err) =>
                        toast.error(_.get(err, "response.data.message", ""))
                      );
                  }}
                ></Icon>
              </div>
            </div>
          </RBACWrapper>
        </TimelineCardWraper>
      );
    case TL_NOTES:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="note"
            title={
              <>
                {_.get(props, "action_source", "")}:{" "}
                {format.rd({
                  id: _.get(props, "name", "_"),
                  name: "notes_category",
                })}{" "}
                {_.get(props, "action_taken", "_")}{" "}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue
              label="Content:"
              value={_.get(props, "comment", "")}
            />
            <ListLableValue
              label="Category:"
              value={_.get(props, "content.notes_category", "")}
              referance="notes_category"
              type="RDTEXT"
            />
            <ListLableValue
              label="Follow-up on:"
              value={_.get(props, "content.notes_follow_up", "")}
              type="DATEORGTZ"
            />
            <ListLableValue
              label="Type:"
              value={_.capitalize(_.get(props, "content.notes_type", ""))}
            />
            <StatusOfAction
              actionName={ACT_NOTES}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            />
          </TimelineCardWraper>
        </>
      );

    case PAYMENT:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="payment"
            title={
              <>
                {_.get(props, "action_source", "")} -{" "}
                {_.get(props, "name", "-")}{" "}
                {_.get(props, "content.portal_payment_amount.currency", "-")}{" "}
                {_.get(props, "content.portal_payment_amount.value", "-")} via{" "}
                {_.get(props, "content.portal_payment_gateway_name", "-")} {"-"}{" "}
                {_.get(props, "action_taken", "-")}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                props?.content?.to.length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue
              label={"Payment Amount:"}
              currency={{
                value: _.get(props, "content.portal_payment_amount.value", "-"),
                currency: _.get(
                  props,
                  "content.portal_payment_amount.currency",
                  "-"
                ),
              }}
              type={"CURRENCY"}
            />
            <ListLableValue
              label={"Payment Date:"}
              value={_.get(props, "content.portal_payment_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Payment method:"}
              value={_.get(props, "content.portal_payment_gateway_name", "-")}
            />
            <ListLableValue
              label={"Payment Reference:"}
              value={_.get(props, "content.portal_payment_reference", "-")}
            />
            <ListLableValue
              label={"Invoice#:"}
              value={_.get(props, "content.portal_payment_invoice_number", [
                "-",
              ])
                .map((value) => value.value || value)
                .join(",")}
            />
          </TimelineCardWraper>
        </>
      );

    default:
      return null;
  }
};

//FieldVisitAction

const FieldVisitAction = (props) => {
  const dispatch = useDispatch();
  const {
    referenceData,
    currentOrganization,
    currentDefaultFormatDetails,
    users,
    userInfo,
  } = useContext(MaxyfiContext);
  const orgRef = referenceData?.organizations?.find(
    ({ id }) => id === currentOrganization
  );
  let disputeStatus = referenceData["dispute_status"];
  const disputeInvoices = _.get(props, "content.dispute_invoices", [])
    ?.map((i) => i.toString())
    .toString()
    .replaceAll(",", ", ");
  const findingStatus = disputeStatus?.find(
    (i) => i.id === _.get(props, "content.dispute_status", "")
  );
  let refType = referenceData["dispute_type"];
  const timeZone = orgRef?.time_zone;

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const findingValue = refType?.find(
    (i) => i.id === _.get(props, "content.dispute_type", "")
  );
  const disputeRaisedOn = moment
    .utc(props.executed_on)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);
  const { customerDetails } = useSelector(
    (state) => state.customerOverviewReducer
  );

  const timezoneBasedDate = moment
    .utc(props.content.dispute_review_date)
    ?.tz(timeZone)
    ?.format(currentDefaultFormatDetails.date_format);
  const visit_status = _.get(props, "content.visit_status", "");
  const format = useFormat();
  switch (
    visit_status === FIELD_UNSUCCESSFUL
      ? FIELD_UNSUCCESSFUL
      : _.get(props, "content.visit_outcome", "")
  ) {
    case PAYMENT_PLAN:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              Field Visit - {_.get(props, "name", "-")} From{" "}
              {format.date({
                value: _.get(props, "content.payment_plan_start_date"),
              })}{" "}
              {format.rd({
                id: _.get(props, "content.payment_plan_status", "-"),
                name: "payment_plan_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Total payment amount:"}
            currency={{
              value: _.get(props, "content.payment_plan_amount.value", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Installment Amount:"}
            currency={{
              value: _.get(
                props,
                "content.payment_plan_instalation_amount.value",
                "-"
              ),
              currency: _.get(
                props,
                "content.payment_plan_instalation_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Planned Start Date:"}
            value={_.get(props, "content.payment_plan_start_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Planned Frequency:"}
            value={_.get(props, "content.payment_plan_frequency", "-")}
            // referance="payment_plan_frequency"
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.payment_plan_method", "-")}
            // referance="payment_plan_frequency"
          />
          <PaymentPlanedList {...props} />
          <StatusOfAction
            actionName={ACT_PAYMENT_PLAN}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );
    case PROMISETOPAY:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "is_receiver", false) ? "Portal" : "Field Visit"} -{" "}
              {_.get(props, "name", "")} by{" "}
              {format.date({ value: _.get(props, "content.promise_date") })}{" "}
              {format.rd({
                id: _.get(props, "content.promise_status", ""),
                name: "promise_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Location:"}
            value={`${_.get(props, "content.visit_address.line_1", "")} ${_.get(
              props,
              "content.visit_address.line_2",
              ""
            )} ${_.get(props, "content.visit_address.city", "")} ${_.get(
              props,
              "content.visit_address.state",
              ""
            )} ${_.get(props, "content.visit_address.zip_code", "")} ${_.get(
              props,
              "content.visit_address.country",
              ""
            )}`}
          />
          <ListLableValue
            label={"Whom You Met:"}
            value={_.get(props, "content.visit_person", "-")}
          />

          <ListLableValue
            label={"Promise Type:"}
            value={_.get(props, "content.promise_type", "-")}
            type="RDTEXT"
            referance="promise_type"
          />

          <ListLableValue
            label={"Promise Amount:"}
            currency={{
              value: _.get(props, "content.promise_amount.value", "-"),
              currency: _.get(props, "content.promise_amount.currency", "-"),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Promise Date:"}
            value={_.get(props, "content.promise_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <StatusOfAction
            actionName={ACT_PROMISE_TO_PAY}
            isCalender={_.get(props, "content.is_enable_calendar_alert", false)}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );
    case CALL_CALL_BACK_LATER:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="callback_later"
            title={
              <>
                {_.get(props, "is_receiver", false) ? "Portal" : "Field Visit"}{" "}
                - {_.get(props, "name", "")} on{" "}
                {format.date({ value: _.get(props, "content.callback_date") })}{" "}
                at{" "}
                {format.rd({
                  id: _.get(props, "content.callback_time", ""),
                  name: "time",
                })}
              </>
            }
          >
            <ListLableValue
              label={"Location:"}
              value={`${_.get(
                props,
                "content.visit_address.line_1",
                ""
              )} ${_.get(props, "content.visit_address.line_2", "")} ${_.get(
                props,
                "content.visit_address.city",
                ""
              )} ${_.get(props, "content.visit_address.state", "")} ${_.get(
                props,
                "content.visit_address.zip_code",
                ""
              )} ${_.get(props, "content.visit_address.country", "")}`}
            />
            <ListLableValue
              label={"Whom You Met:"}
              value={_.get(props, "content.visit_person", "-")}
            />
            <ListLableValue
              label={"Callback Date:"}
              value={_.get(props, "content.callback_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Callback Time:"}
              value={
                props.content.callback_time
                  ? moment(props.content.callback_time, "h_mm_A").format(
                      "h:mm A"
                    )
                  : "-"
              }
              time={"TIME"}
            />
            <StatusOfAction
              actionName={ACT_CALL_BACK_LATER}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
              isConfirmationMsg={_.get(
                props,
                "content.is_send_confirmation_message",
                false
              )}
              isPromiseFollowMsg={_.get(
                props,
                "content.is_send_action_follow_up",
                false
              )}
            />
          </TimelineCardWraper>
        </>
      );
    case PAYMENT:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="payment"
            title={
              <>
                {_.get(props, "is_receiver", false) ? "Portal" : "Field Visit"}{" "}
                - {_.capitalize(_.get(props, "content.visit_outcome", "-"))}{" "}
                {_.get(props, "content.portal_payment_amount.currency", "-")}{" "}
                {_.get(props, "content.portal_payment_amount.value", "-")} via{" "}
                {_.get(props, "content.portal_payment_method", "-")}
                {/* {_.get(props, "action_taken", "-")} */}
              </>
            }
          >
            <ListLableValue
              label={"Location:"}
              value={`${_.get(
                props,
                "content.visit_address.line_1",
                ""
              )} ${_.get(props, "content.visit_address.line_2", "")} ${_.get(
                props,
                "content.visit_address.city",
                ""
              )} ${_.get(props, "content.visit_address.state", "")} ${_.get(
                props,
                "content.visit_address.zip_code",
                ""
              )} ${_.get(props, "content.visit_address.country", "")}`}
            />
            <ListLableValue
              label={"Payment Amount:"}
              currency={{
                value: _.get(props, "content.portal_payment_amount.value", "-"),
                currency: _.get(
                  props,
                  "content.portal_payment_amount.currency",
                  "-"
                ),
              }}
              type={"CURRENCY"}
            />
            <ListLableValue
              label={"Payment Date:"}
              value={_.get(props, "content.portal_payment_date", "-")}
              type={
                !props &&
                props?.content &&
                props?.content?.portal_payment_date !== null
                  ? "DATEORGTZ"
                  : ""
              }
            />
            <ListLableValue
              label={"Payment method:"}
              value={_.get(props, "content.portal_payment_method", "-")}
            />
            <ListLableValue
              label={"Payment Reference:"}
              value={_.get(props, "content.portal_payment_reference", "-")}
            />
            <ListLableValue
              label={"Invoice#:"}
              value={_.get(props, "content.portal_payment_invoice_number", [
                "-",
              ])
                .map((value) => value.value || value)
                .join(",")}
            />
          </TimelineCardWraper>
        </>
      );
    case CALL_DISPUTE:
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );
      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = props?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === props?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === props?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(props?.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(props?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...props.content,
        disputeSubject: `${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(props, "content.dispute_details", "")
            ? _.get(props, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          props.comment ? props.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };
      return (
        <TimelineCardWraper
          {...props}
          icon="dispute"
          title={
            <>
              Field Visit : Dispute{" "}
              {_.get(props, "is_adhoc_action", false) ? "(QA)" : ""} -{" "}
              {_.get(props, "content.dispute_status", "") === "CLOSED" ? (
                <>
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Closed
                </>
              ) : _.get(props, "content.dispute_status", "") === "OPEN" ? (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Open
                </>
              ) : (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  {"-"} In-Progress
                </>
              )}
            </>
          }
        >
          <ListLableValue
            label={"Location:"}
            value={`${_.get(props, "content.visit_address.line_1", "")} ${_.get(
              props,
              "content.visit_address.line_2",
              ""
            )} ${_.get(props, "content.visit_address.city", "")} ${_.get(
              props,
              "content.visit_address.state",
              ""
            )} ${_.get(props, "content.visit_address.zip_code", "")} ${_.get(
              props,
              "content.visit_address.country",
              ""
            )}`}
          />
          <ListLableValue
            label={"Whom You Met:"}
            value={_.get(props, "content.visit_person", "-")}
          />

          <ListLableValue
            label="Dispute Detailed Text:"
            value={_.get(props, "content.dispute_details", "")}
          />
          <ListLableValue
            label="Dispute Type:"
            value={_.get(props, "content.dispute_type", "")}
            referance="dispute_type"
            type="RDTEXT"
          />
          <ListLableValue
            label="Dispute Review Date:"
            value={_.get(props, "content.dispute_review_date", "")}
            type="DATEORGTZ"
          />
          <ListLableValue
            label="Dispute Status:"
            value={_.capitalize(_.get(props, "content.dispute_status", ""))}
          />
          <ListLableValue
            label="Linked Invoices No#:"
            value={
              _.get(props, "content.dispute_invoices", []).length > 0
                ? _.get(props, "content.dispute_invoices", [])
                    .map((to) => to)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Amount:"
            value={
              _.get(props, "content.amt", null)
                ? `${
                    currencyList[currentDefaultFormatDetails.default_currency]
                  } ${format.currency({
                    amount: _.get(props, "content.amt", null),
                  })}`
                : "-"
            }
          />
          <StatusOfAction
            actionName={ACT_RECORD_DISPUTE}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
          />
          <RBACWrapper role={EMAIL_REPLY} type="PERMISSION">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Icon
                  icon="forward"
                  isPressable
                  onClick={async () => {
                    return await getDisputeContent({
                      organization: currentOrganization,
                      customerId: props?.customer_id,
                      disputeId: props?._id,
                    })
                      .then((res) => {
                        dispatch(
                          emailDataToDrawer({
                            emailType: FORWARD,
                            emailData: {},
                            subData: undefined,
                            ...(_.get(res, "data.doc.template_id", "")
                              ? {
                                  template_id: _.get(
                                    res,
                                    "data.doc.template_id",
                                    ""
                                  ),
                                }
                              : {}),
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: FORWARD,
                          subData: undefined,
                          ...(_.get(res, "data.doc.template_id", "")
                            ? {
                                template_id: _.get(
                                  res,
                                  "data.doc.template_id",
                                  ""
                                ),
                              }
                            : {}),
                          type: {
                            ...props.content,
                            type: DISPUTE,
                            disputeSubject: _.get(res, "data.doc.subject", ""),
                            disputeBody: _.get(res, "data.doc.value", ""),
                          },
                        });
                      })
                      .catch((err) =>
                        toast.error(_.get(err, "response.data.message", ""))
                      );
                  }}
                ></Icon>
              </div>
            </div>
          </RBACWrapper>
        </TimelineCardWraper>
      );
    case TL_NOTES:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="note"
            title={
              <>
                Field Visit:{" "}
                {format.rd({
                  id: _.get(props, "content.notes_category", "_"),
                  name: "notes_category",
                })}{" "}
                {_.get(props, "action_taken", "_")}{" "}
              </>
            }
          >
            <ListLableValue
              label={"Receiver:"}
              value={
                _.get(props, "content.to", []) &&
                _.get(props, "content.to", []).length > 0
                  ? props.content.to
                      .map(
                        (to) =>
                          `${_.capitalize(_.get(to, "name"))} ${
                            to.value
                          } ${format.rd({
                            id: _.get(to, "designation", ""),
                            name: "recipient_type",
                          })}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue label="Note:" value={_.get(props, "comment", "")} />
            <ListLableValue
              label="Category:"
              value={_.get(props, "content.notes_category", "")}
              referance="notes_category"
              type="RDTEXT"
            />
            <ListLableValue
              label="Follow-up on:"
              value={_.get(props, "content.notes_follow_up", "")}
              type="DATEORGTZ"
            />
            <ListLableValue
              label="Type:"
              value={_.capitalize(_.get(props, "content.notes_type", ""))}
            />
            <StatusOfAction
              actionName={ACT_NOTES}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            />
          </TimelineCardWraper>
        </>
      );

    case FIELD_UNSUCCESSFUL:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="call_unsuccessfull"
            title={
              <>
                Field Visit-{" "}
                {_.capitalize(_.get(props, "content.visit_status", "-"))}:{" "}
                {_.startCase(
                  _.capitalize(
                    _.get(props, "content.call_unsuccessful_reason", "-")
                  )
                )}
              </>
            }
          >
            <ListLableValue
              label={"Location:"}
              value={`${_.get(
                props,
                "content.visit_address.line_1",
                ""
              )} ${_.get(props, "content.visit_address.line_2", "")} ${_.get(
                props,
                "content.visit_address.city",
                ""
              )} ${_.get(props, "content.visit_address.state", "")} ${_.get(
                props,
                "content.visit_address.zip_code",
                ""
              )} ${_.get(props, "content.visit_address.country", "")}`}
            />
            <ListLableValue
              label={"UnSuccessful Reason:"}
              value={_.get(props, "content.visit_unsuccessful_reason", "-")}
              referance="call_unsuccessful_reason"
              type="RDTEXT"
            />
            <ListLableValue
              label={"Follow Up Date:"}
              value={
                props?.content?.followup_date
                  ? format.date({ value: props?.content?.followup_date })
                  : "-"
              }
            />
            <StatusOfAction
              actionName={ACT_UNSUCCESSFUL}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
              isConfirmationMsg={_.get(
                props,
                "content.is_send_confirmation_message",
                false
              )}
            />
          </TimelineCardWraper>
        </>
      );
    case ACT_RETURN_VISIT:
      return (
        <TimelineCardWraper
          {...props}
          icon="field_visit"
          title={
            <>
              Field Visit - {_.get(props, "name", "-")} on{" "}
              {format.date({ value: _.get(props, "content.visit_date") })}{" "}
            </>
          }
        >
          <ListLableValue
            label={"Location:"}
            value={`${_.get(props, "content.visit_address.line_1", "")} ${_.get(
              props,
              "content.visit_address.line_2",
              ""
            )} ${_.get(props, "content.visit_address.city", "")} ${_.get(
              props,
              "content.visit_address.state",
              ""
            )} ${_.get(props, "content.visit_address.zip_code", "")} ${_.get(
              props,
              "content.visit_address.country",
              ""
            )}`}
          />
          <ListLableValue
            label={"Whom You Met:"}
            value={_.get(props, "content.visit_person", "-")}
          />
          <ListLableValue
            label={"Field Visit Date:"}
            value={_.get(props, "content.return_visit_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Field Visit Time:"}
            value={format.rd({
              id: _.get(props, "content.return_visit_time", "-"),
              name: "time",
            })}
          />

          <StatusOfAction
            actionName={ACT_FIELD_VISIT}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );
    default:
      return null;
  }
};

const EmailAction = (props) => {
  const dispatch = useDispatch();
  const reportTemplateRef = useRef(null);
  const { currentDefaultFormatDetails, referenceData, currentOrganization } =
    useContext(MaxyfiContext);
  const contentRef = useRef();

  let deliveredEmail = props?.content?.delivered_recipients
    ? props?.content?.delivered_recipients
    : [{}];

  let failedEmail = props?.content?.failed_recipients
    ? props?.content?.failed_recipients
    : [{}];

  let EmailTableData = [...deliveredEmail, ...failedEmail];

  const [isEmailContent, setEmailContent] = useState(false);
  const [emailContent, setEmailContentData] = useState("");
  const [isEmailApiFail, setEmailApiFail] = useState(false);
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const executedBy =
    referenceData &&
    referenceData["users"] &&
    Array.isArray(referenceData["users"])
      ? referenceData["users"].find(
          (e) => e.id === _.get(props, "executed_by", "")
        )
      : null;

  return (
    <div ref={reportTemplateRef}>
      <TimelineCardWraper
        {...props}
        icon="email"
        title={
          <>
            Email {_.get(props, "is_adhoc_action", false) ? " (QA) " : ""} -{" "}
            {_.get(props, "name", "")}
          </>
        }
      >
        <div
          style={{
            height: "100px",
            overflow: "hidden",
            position: "absolute",
            zIndex: "-99",
          }}
        >
          <div ref={contentRef} style={{}}>
            <div>
              Email {_.get(props, "is_adhoc_action", false) ? " (QA)" : ""} -
              {_.get(props, "name", "")}
              <br />
              {_.get(props, "is_receiver", false) ? "From :" : "Recipients :"}
              {_.get(props, "content.to", [])
                .map((to) => to.value.toString())
                .toString()
                .replaceAll(",", ", ")}
              <br />
              CC :{" "}
              {_.get(props, "content.cc", [])
                .map((cc) => cc.toString())
                .toString()
                .replaceAll(",", ", ")}
              <br />
              BCC :{" "}
              {_.get(props, "content.bcc", [])
                .map((bcc) => bcc.toString())
                .toString()
                .replaceAll(",", ", ")}
              <br />
              Subject : {_.get(props, "content.subject", "-")}
              <br />
              <br />
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(props, "con_link", "")
                    ? emailContent
                    : _.get(props, "content.body", ""),
                }}
              />
              <br />
            </div>

            <div>
              <Typography type="p" className="text-secondary" subType="regular">
                Captured By:{" "}
                {props && props.executed_by && executedBy?.label
                  ? executedBy?.label
                  : _.get(props, "is_receiver", "")
                  ? "Consumer"
                  : "System"}{" "}
                <DateComponent
                  type={DATE_ORG}
                  value={props && props.executed_on}
                />{" "}
                <FormattedTime value={props && props.executed_on} />
              </Typography>
            </div>

            {!props.is_receiver && props.deliverablity_status ? (
              <EmailDeliveryStatusTable
                EmailTableData={EmailTableData}
                currentDefaultFormatDetails={currentDefaultFormatDetails}
                data={props}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <ListLableValue
          label={_.get(props, "is_receiver", false) ? "From" : "Recipients:"}
          value={_.get(props, "content.to", [])
            .map((to) => to.value.toString())
            .toString()
            .replaceAll(",", ", ")}
        />
        <ListLableValue
          label={"CC:"}
          value={_.get(props, "content.cc", [])
            .map((to) => to.toString())
            .toString()
            .replaceAll(",", ", ")}
        />
        <ListLableValue
          label={"BCC:"}
          value={_.get(props, "content.bcc", [])
            .map((to) => to.toString())
            .toString()
            .replaceAll(",", ", ")}
        />

        <ListLableValue
          label={"Subjects:"}
          value={_.get(props, "content.subject", "-")}
        />
        {!isEmailContent && _.get(props, "con_link", "").length ? (
          <div className="co_timeline_amount_wraper">
            <Typography
              type="p"
              className="text-secondary lable_contain"
              subType="regular"
            >
              Body:
            </Typography>
            <Typography
              type="a"
              className="text-primary"
              style={{ cursor: "pointer" }}
              title="Click to view the email body"
              onClick={async () => {
                try {
                  setEmailContent(true); // Show the loader or placeholder if needed
                  if (_.get(props, "con_link", "")) {
                    const response = await getEmailContentTimeline({
                      organization: currentOrganization,
                      customerId: _.get(props, "customer_id", ""),
                      timelineId: _.get(props, "_id", ""),
                    });

                    setEmailContentData(response.data); // Update state with the email content
                  }
                } catch (error) {
                  setEmailApiFail(true);
                  console.error("Error fetching email content:", error);
                }
              }}
            >
              View Email Body
            </Typography>
          </div>
        ) : (
          <></>
        )}

        {isEmailContent || !_.get(props, "con_link", "") ? (
          <QuillFrame
            content={
              _.get(props, "con_link", "") && !isEmailApiFail
                ? emailContent
                : _.get(props, "content.body", "")
            }
          />
        ) : (
          <></>
        )}
        <div style={{ marginTop: "10px" }}>
          {_.get(props, "content.attachments", []).map((i) => {
            if (!i || !i?.name) {
              return <></>;
            }
            let splitType = i && i.name && i?.name?.split(".");
            return (
              <div
                style={{
                  width: "350px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <DocumentCard
                    res={{
                      file_name: i.name,
                      mime_type: splitType[1],
                      _id: i.ref,
                      customerId: _.get(props, "customer_id", ""),
                      tab: "timeline",
                    }}
                  />
                </div>
              </div>
            );
          })}

          <div
            style={{
              display: "flex",
              gap: "35px",
              justifyContent: "flex-end",
            }}
          >
            <Icon
              icon="download"
              isPressable
              onClick={async () => {
                try {
                  if (!isEmailContent && _.get(props, "con_link", "")) {
                    setEmailContent(true);
                    const response = await getEmailContentTimeline({
                      organization: currentOrganization,
                      customerId: _.get(props, "customer_id", ""),
                      timelineId: _.get(props, "_id", ""),
                    });

                    if (response && response.data) {
                      setEmailContentData(response.data);
                      const doc = new jsPDF("p", "pt", "a4");
                      const content = contentRef.current;

                      doc.html(content, {
                        callback: function (doc) {
                          doc.save("email-report.pdf");
                        },
                        x: 20,
                        y: 20,
                        width: 555,
                        windowWidth: content.scrollWidth,
                      });
                    } else {
                      console.error("No email content available for download.");
                      return;
                    }
                  } else {
                    setEmailContent(true);
                    const doc = new jsPDF("p", "pt", "a4");
                    const content = contentRef.current;

                    doc.html(content, {
                      callback: function (doc) {
                        doc.save("email-report.pdf");
                      },
                      x: 20,
                      y: 20,
                      width: 555,
                      windowWidth: content.scrollWidth,
                    });
                  }
                } catch (error) {
                  console.error(
                    "Error fetching email content for download:",
                    error
                  );
                }
              }}
              color={"#787878"}
            />
            <RBACWrapper role={EMAIL_REPLY} type="PERMISSION">
              <div>
                <Icon
                  icon="replay"
                  isPressable
                  onClick={async () => {
                    if (!isEmailContent && _.get(props, "con_link", "")) {
                      const response = await getEmailContentTimeline({
                        organization: currentOrganization,
                        customerId: _.get(props, "customer_id", ""),
                        timelineId: _.get(props, "_id", ""),
                      });

                      if (response && response.data) {
                        setEmailContentData(_.get(response, "data", ""));

                        dispatch(
                          emailDataToDrawer({
                            emailType: REPLY,
                            emailData: props?.content,

                            subData: props,
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: REPLY,
                          type: props?.content,
                          emailBodyContent: _.get(response, "data", ""),
                        });
                      } else {
                        console.error(
                          "No email content available for download."
                        );
                        return;
                      }
                    } else {
                      dispatch(
                        emailDataToDrawer({
                          emailType: REPLY,
                          emailData: props?.content,

                          subData: props,
                        })
                      );

                      setDrawer(EMAIL_TIMELINE_LAYOUT, {
                        emailType: REPLY,
                        type: props?.content,
                        emailBodyContent: emailContent,
                      });
                    }
                  }}
                ></Icon>
              </div>
              <div>
                <Icon
                  icon="replay_all"
                  isPressable
                  onClick={async () => {
                    if (!isEmailContent && _.get(props, "con_link", "")) {
                      const response = await getEmailContentTimeline({
                        organization: currentOrganization,
                        customerId: _.get(props, "customer_id", ""),
                        timelineId: _.get(props, "_id", ""),
                      });

                      if (response && response.data) {
                        setEmailContentData(_.get(response, "data", ""));

                        dispatch(
                          emailDataToDrawer({
                            emailType: REPLY_ALL,
                            emailData: props?.content,

                            subData: props,
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: REPLY_ALL,
                          type: props?.content,
                          emailBodyContent: _.get(response, "data", ""),
                        });
                      } else {
                        console.error(
                          "No email content available for download."
                        );
                        return;
                      }
                    } else {
                      dispatch(
                        emailDataToDrawer({
                          emailType: REPLY_ALL,
                          emailData: props?.content,

                          subData: props,
                        })
                      );

                      setDrawer(EMAIL_TIMELINE_LAYOUT, {
                        emailType: REPLY_ALL,
                        type: props?.content,
                        emailBodyContent: emailContent,
                      });
                    }
                  }}
                ></Icon>
              </div>
              <div>
                <Icon
                  icon="forward"
                  isPressable
                  // onClick={() => {
                  //   dispatch(
                  //     emailDataToDrawer({
                  //       emailType: FORWARD,
                  //       emailData: props?.content,
                  //       subData: props,
                  //     })
                  //   );

                  //   setDrawer(EMAIL_TIMELINE_LAYOUT, {
                  //     emailType: FORWARD,
                  //     type: props?.content,
                  //     subData: props,
                  //   });
                  // }}
                  onClick={async () => {
                    if (!isEmailContent && _.get(props, "con_link", "")) {
                      const response = await getEmailContentTimeline({
                        organization: currentOrganization,
                        customerId: _.get(props, "customer_id", ""),
                        timelineId: _.get(props, "_id", ""),
                      });

                      if (response && response.data) {
                        setEmailContentData(_.get(response, "data", ""));

                        dispatch(
                          emailDataToDrawer({
                            emailType: FORWARD,
                            emailData: props?.content,

                            subData: props,
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: FORWARD,
                          type: props?.content,
                          emailBodyContent: _.get(response, "data", ""),
                        });
                      } else {
                        console.error(
                          "No email content available for download."
                        );
                        return;
                      }
                    } else {
                      dispatch(
                        emailDataToDrawer({
                          emailType: FORWARD,
                          emailData: props?.content,

                          subData: props,
                        })
                      );

                      setDrawer(EMAIL_TIMELINE_LAYOUT, {
                        emailType: FORWARD,
                        type: props?.content,
                        emailBodyContent: emailContent,
                      });
                    }
                  }}
                ></Icon>
              </div>
            </RBACWrapper>
          </div>
        </div>
      </TimelineCardWraper>
    </div>
  );
};

const HoldAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="hold_action"
        title={
          <>
            {_.get(props, "name", "-")} Till{" "}
            {_.get(props, "content.hold_action_till", "")
              ? format.date({ value: props?.content?.hold_action_till })
              : "-"}{" "}
          </>
        }
      >
        <ListLableValue
          label={"Pause Till Date:"}
          value={
            _.get(props, "content.hold_action_till", "")
              ? format.date({ value: props?.content?.hold_action_till })
              : "-"
          }
        />
        <ListLableValue
          label={"Hold Reason:"}
          value={format.rd({
            name: "hold_action_reason",
            id: _.get(props, "content.hold_reason", "-"),
          })}
        />
      </TimelineCardWraper>
    </>
  );
};

const InstantCallAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="payment"
        title={
          <>
            {_.get(props, "name", "-")} :{" "}
            {_.get(props, "content.portal_payment_amount.currency", "-")} -{" "}
            {_.get(props, "content.portal_payment_amount.value", "-")}{" "}
            {_.get(props, "action_taken", "-")}
          </>
        }
      >
        <ListLableValue
          label={"Payment Amount:"}
          currency={{
            value: _.get(props, "content.portal_payment_amount.value", "-"),
            currency: _.get(
              props,
              "content.portal_payment_amount.currency",
              "-"
            ),
          }}
          type={"CURRENCY"}
        />
        <ListLableValue
          label={"Payment Date:"}
          value={_.get(props, "content.portal_payment_date", "-")}
          type={"DATEORGTZ"}
        />
        <ListLableValue
          label={"Payment method:"}
          value={_.get(props, "content.portal_payment_method", "-")}
        />
        <ListLableValue
          label={"Payment Reference:"}
          value={_.get(props, "content.portal_payment_reference", "-")}
        />
        <ListLableValue
          label={"Invoices#:"}
          value={
            _.get(props, "content.portal_payment_invoice_number", []).length > 0
              ? _.get(props, "content.portal_payment_invoice_number", [])
                  .map((to) => to)
                  .join(", ")
              : "-"
          }
        />
      </TimelineCardWraper>
    </>
  );
};
const EditProcessGroupAction = (props) => {
  const format = useFormat();
  return (
    <TimelineCardWraper
      {...props}
      icon="consumer_group"
      title={
        <>
          Process Grouping-{" "}
          {_.get(props, "content.grp_act", "") === "ADD"
            ? `Added to the Group - ID ${_.get(props, "content.a_gid", "-")}`
            : `Removed to the Group - ID ${_.get(
                props,
                "content.r_gid",
                "-"
              )}`}{" "}
        </>
      }
    >
      <ListLableValue
        label={"Process Grouping: "}
        value={
          _.get(props, "content.grp_act", "") === "ADD" ? "Added" : "Removed"
        }
      />
      <ListLableValue
        label={"Client Name:"}
        value={format.rd({
          id: _.get(props, "content.business_unit", "-"),
          name: "business_unit_list",
        })}
      />
      <ListLableValue
        label={"Invoice Number:"}
        value={
          _.get(props, "content.portal_payment_invoice_number", []).length > 0
            ? _.get(props, "content.portal_payment_invoice_number", [])
                .map((to) => to)
                .join(", ")
            : "-"
        }
      />
      <ListLableValue
        label={"Client Ref Id:"}
        value={
          _.get(props, "content.portal_payment_client_references", []).length >
          0
            ? _.get(props, "content.portal_payment_client_references", [])
                .map((to) => to)
                .join(", ")
            : "-"
        }
      />
    </TimelineCardWraper>
  );
};

const EditConsumerGroupAction = (props) => {
  const format = useFormat();
  return (
    <TimelineCardWraper
      {...props}
      icon="consumer_group"
      title={
        <>
          Consumer Grouping -{" "}
          {_.get(props, "content.grp_act", "") === "ADD"
            ? `Added to the Group - ID ${_.get(props, "content.a_gid", "-")}`
            : `Removed to the Group - ID ${_.get(
                props,
                "content.r_gid",
                "-"
              )}`}{" "}
        </>
      }
    >
      <ListLableValue
        label={"Consumer Grouping: "}
        value={
          _.get(props, "content.grp_act", "") === "ADD" ? "Added" : "Removed"
        }
      />
      <ListLableValue
        label={"Group ID:"}
        value={_.get(props, "content.a_gid", "-")}
      />
      <ListLableValue
        label={"Date & Time:"}
        value={format.date({
          includeTime: true,
          value: _.get(props, "executed_on", "-"),
        })}
      />
    </TimelineCardWraper>
  );
};

//contact Action
const ContactAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="contacts"
        title={
          <>
            Contact: {_.get(props, "name", "-")}{" "}
            {_.get(props, "action_taken", "-")}
          </>
        }
      >
        {_.get(props, "content.contact_phone", []).map((ph) => (
          <>
            <ListLableValue
              label={ph.type}
              name={{
                value: `${ph.value} `,
                status: _.get(ph, "status", ""),
                cmt: _.get(ph, "cmt", ""),
              }}
              type={"NAME"}
            />
          </>
        ))}
        {_.get(props, "content.contact_email", []).map((ph) => (
          <>
            <ListLableValue
              label={ph.type}
              name={{
                value: `${ph.value} `,
                status: _.get(ph, "status", ""),
                cmt: _.get(ph, "cmt", ""),
              }}
              type={"NAME"}
            />
          </>
        ))}
        {_.get(props, "content.contact_address", []).map((ph) => (
          <>
            <ListLableValue
              label={ph.type}
              name={{
                value: `${_.get(ph, "value", "")} `,
                status: _.get(ph, "status", ""),
                cmt: _.get(ph, "cmt", ""),
              }}
              type={"NAME"}
            />
          </>
        ))}
        {/* <ListLableValue
          label={"Contact Address"}
          value={
            _.get(props, "content.contact_address", []).length > 0
              ? props.content.contact_address
                  .map(
                    (to) =>
                      `${format.rd({
                        id: to,
                        name: "contact_address_type",
                      })}`
                  )
                  .join(", ")
              : "-"
          }
        /> */}
      </TimelineCardWraper>
    </>
  );
};

const PaymentAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="payment"
        title={
          <>
            {_.get(props, "name", "-")}:{" "}
            {_.get(props, "content.portal_payment_amount.currency", "-")}{" "}
            {_.get(props, "content.portal_payment_amount.value", "-")} -{" "}
            {_.capitalize(_.get(props, "action_taken"))}
          </>
        }
      >
        <ListLableValue
          label={"Payment Date:"}
          value={_.get(props, "content.portal_payment_date", "-")}
          type={"DATEORGTZ"}
        />
        <ListLableValue
          label={"Payment Method:"}
          value={_.get(props, "content.portal_payment_method", "-")}
        />
        <ListLableValue
          label={"Payment Reference:"}
          value={_.get(props, "content.portal_payment_reference", "-")}
        />
        <ListLableValue
          label={"Invoices #:"}
          value={_.get(props, "content.portal_payment_invoice_number", ["-"])
            .map((value) => value.value || value)
            .join(",")}
        />
        <ListLableValue
          label={"Payment Description"}
          value={_.get(props, "comment", "-")}
        />
        {_.get(props, "content.attachments", []).map((i) => {
          let splitType = i && i?.name && i?.name.split(".");

          return (
            <div
              style={{
                width: "350px",
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
              }}
            >
              <div>
                <DocumentCard
                  res={{
                    file_name: i.name,
                    mime_type: splitType?.[1],
                    _id: i.ref,
                    customerId: _.get(props, "customer_id", ""),
                    tab: "timeline",
                  }}
                />
              </div>
            </div>
          );
        })}
      </TimelineCardWraper>
    </>
  );
};

const InvAdjustmentAction = (props) => {
  const { customerId } = useParams();
  const format = useFormat();
  const { currentOrganization } = useContext(MaxyfiContext);
  const onSuccessRefetch = () => {
    queryClient.refetchQueries([`${CUSTOMER_OVERVIEW_}${customerId}`]);
    queryClient.refetchQueries([`${CO_TIMELINE_}${customerId}`]);
    queryClient.refetchQueries([`${CO_INVOICES_}${customerId}`]);
  };
  let dispatch = useDispatch();
  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const removeAdjustment = useMutation(
    (adjData) =>
      deleteAdjustment({
        ...adjData,
        organization: currentOrganization,
        invoiceId: _.get(props, "content.portal_payment_invoice_id[0]", ""),
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        onSuccessRefetch();
      },
    }
  );
  return (
    <div className="adj_timeline_wraper">
      <TimelineCardWraper
        {...props}
        icon="adjustment"
        title={
          <>
            {_.get(props, "name", "-")}:{" "}
            {_.get(props, "content.portal_payment_invoice_number[0]", "")}{" "}
            {_.get(props, "content.portal_payment_client_references[0]", "")
              ? "/"
              : ""}{" "}
            {_.get(props, "content.portal_payment_client_references[0]", "")}
          </>
        }
      >
        <ListLableValue
          label={"Invoices #:"}
          value={_.get(props, "content.portal_payment_invoice_number[0]", "")}
        />

        <ListLableValue
          label={"Client Reference:"}
          value={_.get(props, "content.portal_payment_client_references", [])
            .map((i) => i.toString())
            .toString()
            .replaceAll(",", ", ")}
        />

        <ListLableValue
          label={"Adjustment Amount:"}
          value={`${_.get(
            props,
            "content.portal_payment_amount.currency",
            "-"
          )} ${format.currency({
            amount: _.get(props, "content.portal_payment_amount.value", "-"),
          })}`}
        />

        {_.get(props, "content.claa", "") &&
        +_.get(props, "content.claa", "") !==
          +_.get(props, "content.portal_payment_amount.value", "") ? (
          <ListLableValue
            label={"Adjustment Amount:"}
            isLabelChild="(Less Commission)"
            value={`${_.get(
              props,
              "content.portal_payment_amount.currency",
              "-"
            )} ${format.currency({
              amount: _.get(props, "content.claa", "-"),
            })}`}
          />
        ) : (
          <></>
        )}

        {_.get(props, "content.aca", "") && (
          <ListLableValue
            label={"Commission Amount:"}
            value={`${_.get(
              props,
              "content.portal_payment_amount.currency",
              "-"
            )} ${format.currency({
              amount: _.get(props, "content.aca", "-"),
            })}`}
          />
        )}

        <ListLableValue
          label={"Adjustment Date:"}
          value={_.get(props, "content.portal_payment_date", "-")}
          type={"DATEORGTZ"}
        />

        <ListLableValue
          label={"Adjustment Reference:"}
          value={_.get(props, "content.portal_payment_reference", "-")}
        />

        <ListLableValue
          label={"Adjustment Type:"}
          value={format.rd({
            id: _.get(props, "content.portal_payment_method", "-"),
            name: "adjustment_type",
          })}
        />

        {_.get(props, "upby", "-") ? (
          <ListLableValue
            label={"Updated By:"}
            value={format.rd({
              id: _.get(props, "upby", "-"),
              name: "users",
            })}
          />
        ) : (
          ""
        )}
        {_.get(props, "upat", null) ? (
          <ListLableValue
            label={"Updated At:"}
            value={_.get(props, "upat", null)}
            type={"DATEORGTZ"}
          />
        ) : (
          ""
        )}
        {_.get(props, "del_by", "") ? (
          <ListLableValue
            label={"Deleted By:"}
            value={format.rd({
              id: _.get(props, "del_by", ""),
              name: "users",
            })}
          />
        ) : (
          ""
        )}
        {_.get(props, "del_at", null) ? (
          <ListLableValue
            label={"Deleted At:"}
            value={_.get(props, "del_at", null)}
            type={"DATEORGTZ"}
          />
        ) : (
          ""
        )}

        {/* <ListLableValue
          label={"Description"}
          value={_.get(props, "comment", "-")}
        /> */}
      </TimelineCardWraper>
      {_.get(props, "del_by", "") ? (
        <></>
      ) : (
        <div className="adj_timeline_icon_wraper">
          <RBACWrapper role={EDIT_ADJUSTMENT} type="PERMISSION">
            <Icon
              icon="edit"
              isPressable
              color="#ADADAD"
              onClick={() =>
                setDrawer(ADJUSTMENT_DRAWER, {
                  invID: _.get(
                    props,
                    "content.portal_payment_invoice_id[0]",
                    ""
                  ),
                  drawerType: EDIT,
                  ...props,
                })
              }
            />
          </RBACWrapper>
          <RBACWrapper role={DELETE_ADJUSTMENT} type="PERMISSION">
            <StatefulPopover
              triggerType={TRIGGER_TYPE.hover}
              autoFocus={false}
              content={({ close }) => (
                <div className="adj_popover_container">
                  <div className="adj_popover_head_container">
                    <Icon icon="alert" color="#FD372A" />
                    <Typography type="p">Do you want to continue?</Typography>
                  </div>
                  <Typography
                    type="p"
                    subType="regular"
                    className="text-secondary"
                  >
                    This will delete the adjustment entry
                  </Typography>
                  <div className="adj_popover_footer_container">
                    <TextButton
                      size="mini"
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => close()}
                    >
                      <Typography type="p" subType="regular">
                        Back
                      </Typography>
                    </TextButton>
                    <TextButton
                      size="mini"
                      kind={KIND.secondary}
                      onClick={() => {
                        removeAdjustment.mutateAsync({
                          timelineId: _.get(props, "_id", ""),
                        });
                        close();
                      }}
                      type="error"
                    >
                      <Typography
                        type="p"
                        subType="regular"
                        className="text-error"
                      >
                        Confirm
                      </Typography>
                    </TextButton>
                  </div>
                </div>
              )}
            >
              <div>
                <Icon icon="delete" color="#ADADAD" isPressable size={18} />
              </div>
            </StatefulPopover>
          </RBACWrapper>
        </div>
      )}
    </div>
  );
};

//customer Name Change
const CustomerNameUpdateAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="payment"
        title={<>Consumer Name Changed to {_.get(props, "name", "-")} </>}
      >
        <ListLableValue
          label={"Old Name"}
          value={_.get(props, "content.customer_old_name", "-")}
        />
        <ListLableValue
          label={"New Name"}
          value={_.get(props, "content.customer_new_name", "-")}
        />
      </TimelineCardWraper>
    </>
  );
};

//callRegarding Action
const CallRecordingAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="call_recording"
        title={<>Call Recording with {_.get(props, "cna", "-")}</>}
      >
        <ListLableValue
          label={"From Number"}
          value={_.get(props, "content.call_detail.from_number", "-")}
        />

        {props?.content?.call_detail?.recording_url ? (
          <div style={{ paddingTop: "5px" }}>
            <audio controls controlsList="nodownload noplaybackrate">
              <source
                src={_.get(props, "content.call_detail.recording_url", " ")}
                type="audio/mpeg"
              />
              <source
                src={_.get(props, "content.call_detail.recording_url", " ")}
                type="audio/ogg"
              />
              <source
                src={_.get(props, "content.call_detail.recording_url", " ")}
                type="audio/wav"
              />
            </audio>
          </div>
        ) : (
          <></>
        )}
      </TimelineCardWraper>
    </>
  );
};

//Update Promise and Payment Plan Date Changes
const UpdatePromiseDateAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="promise_to_pay"
        title={<>Promise Date Edited</>}
      >
        <ListLableValue
          label={"Planned Date:"}
          value={_.get(props, "content.planned_date", "-")}
          type={"DATEORGTZ"}
        />
        <ListLableValue
          label={"Revised Date:"}
          value={_.get(props, "content.revised_date", "-")}
          type={"DATEORGTZ"}
        />
      </TimelineCardWraper>
    </>
  );
};

const UpdatePaymentPlanDateAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="payment_plan"
        title={<>Payment Plan Date Edited</>}
      >
        <ListLableValue
          label={"Planned Date:"}
          value={_.get(props, "content.planned_date", "-")}
          type={"DATEORGTZ"}
        />
        <ListLableValue
          label={"Revised Date:"}
          value={_.get(props, "content.revised_date", "-")}
          type={"DATEORGTZ"}
        />
      </TimelineCardWraper>
    </>
  );
};

//customer Poral Action

const CustomerPortalAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="customer_portal"
        title={
          <>
            Portal: {_.get(props, "name", "-")}{" "}
            {format.date({ value: _.get(props, "executed_on") })}{" "}
          </>
        }
      >
        <ListLableValue
          label={"Actions:"}
          value={_.get(props, "content.portal_actions", "-").join(", ")}
        />
        <ListLableValue
          label={"Outstanding Page"}
          value={_.get(props, "content.portal_outstanding_page", ["-"])
            .map((value) => moment(value).format("h:mm A"))
            .join(",")}
        />
        <ListLableValue
          label={"Payment Page"}
          value={_.get(props, "content.portal_payment_page", ["-"])
            .map((value) => moment(value).format("h:mm A"))
            .join(",")}
        />
      </TimelineCardWraper>
    </>
  );
};

const PortalPaymentAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="customer_portal"
        title={
          <>
            {_.get(props, "name", "-")} :{" "}
            {_.get(props, "content.portal_payment_amount.currency", "-")} -{" "}
            {_.get(props, "content.portal_payment_amount.value", "-")}{" "}
            {_.get(props, "action_taken", "-")}
          </>
        }
      >
        <ListLableValue
          label={"Payment Date:"}
          value={_.get(props, "content.portal_payment_date", "-")}
          type={"DATEORGTZ"}
        />
        <ListLableValue
          label={"Payment method:"}
          value={_.get(props, "content.portal_payment_method", "-")}
        />
        <ListLableValue
          label={"Payment Reference:"}
          value={_.get(props, "content.portal_payment_reference", "-")}
        />
        <ListLableValue
          label={"Invoice#:"}
          value={_.get(props, "content.portal_payment_invoice_number", ["-"])
            .map((value) => value.value || value)
            .join(",")}
        />
        {props?.content?.disc_prc ? (
          <ListLableValue
            label={"Discount:"}
            value={`${_.get(props, "content.disc_prc", 0)}${
              props?.content?.disc_prc ? "%" : ""
            } & Amount ${_.get(
              props,
              "content.portal_payment_amount.currency",
              "-"
            )} ${format.currency({
              amount: _.get(props, "content.disc_amt", 0),
            })}`}
          />
        ) : (
          <></>
        )}

        {_.get(props, "content.cpcm", "") ? (
          <ListLableValue
            label={"Confirmation Message:"}
            value={`${_.get(props, "content.cpcm", "-")} - Accepted by ${_.get(
              props,
              "cna",
              "-"
            )} on ${format.date({
              value: _.get(props, "executed_on"),
              includeTime: true,
            })} `}
          />
        ) : (
          <></>
        )}
      </TimelineCardWraper>
    </>
  );
};

//portal Payment Accsess
const PortalPaymentPlanAction = (props) => {
  const format = useFormat();
  switch (props && props.content && props.content.call_outcome) {
    case PAYMENT_PLAN:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              Portal - Consumer Submitted {_.get(props, "name", "-")} -{" "}
              {_.startCase(
                _.capitalize(_.get(props, "content.promise_status", "-"))
              )}
            </>
          }
        >
          <ListLableValue
            label={"Invoice#:"}
            value={_.get(props, "content.portal_payment_invoice_number", [])
              .map((i) => i.toString())
              .toString()
              .replaceAll(",", ", ")}
          />
          <ListLableValue
            label={"Total Payment Amount:"}
            currency={{
              value: _.get(props, "content.payment_plan_amount.value", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Discount Slab & Rate:"}
            value={`${_.get(props, "content.disc_slb", "-")} & ${_.get(
              props,
              "content.disc_prc",
              "-"
            )}%`}
          />
          <ListLableValue
            label={"Discount Amount:"}
            currency={{
              value: _.get(props, "content.disc_amt", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Installment Amount:"}
            currency={{
              value: _.get(
                props,
                "content.payment_plan_instalation_amount.value",
                "-"
              ),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Planned Start Date:"}
            value={_.get(props, "content.payment_plan_start_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Payment Frequency:"}
            value={_.get(props, "content.payment_plan_frequency", "-")}
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.payment_plan_method", "-")}
          />
          {_.get(props, "content.cpcm", "") ? (
            <ListLableValue
              label={"Confirmation Message:"}
              value={`${_.get(
                props,
                "content.cpcm",
                "-"
              )} - Accepted by ${_.get(props, "cna", "-")} on ${format.date({
                value: _.get(props, "executed_on"),
                includeTime: true,
              })} `}
            />
          ) : (
            <></>
          )}
          <StatusOfAction
            actionName={ACT_PAYMENT_PLAN}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
          {/* pending  status of the  reposence like status */}
        </TimelineCardWraper>
      );
    default:
      return null;
  }
};

//document request info
const DocumentRequestInfoAction = (props) => {
  const format = useFormat();
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="request_info"
        title={
          <>
            {_.capitalize(_.get(props, "content.category", "-"))} Requested{" "}
            {_.startCase(
              _.capitalize(_.get(props, "content.req_info_type", "-"))
            )}
            - {_.capitalize(_.get(props, "content.req_info_status", "-"))}
          </>
        }
      >
        <ListLableValue
          label={"category:"}
          value={_.capitalize(_.get(props, "content.category", "-"))}
        />
        <ListLableValue
          label={"Sub Category:"}
          value={_.startCase(
            _.capitalize(_.get(props, "content.req_info_type", "-"))
          )}
        />
        <ListLableValue
          label={"Invoice#:"}
          value={_.get(props, "content.req_info_invoices", ["-"])
            .map((value) => value.value || value)
            .join(",")}
        />
        <ListLableValue
          label={"Details:"}
          value={_.get(props, "content.req_info_details", "-")}
        />
        <ListLableValue
          label={"Status:"}
          value={_.capitalize(_.get(props, "content.req_info_status", "-"))}
        />
        <ListLableValue
          label={"Assigned To:"}
          value={
            _.get(props, "executed_by", "-")
              ? format.rd({ id: "users", name: props && props.executed_by })
              : "-"
          }
        />
        <ListLableValue
          label={"Raised Date:"}
          value={_.get(props, "executed_on", "-")}
          type={"DATEORGTZ"}
        />
        {/* pending  status of the  reposence like status */}
      </TimelineCardWraper>
    </>
  );
};

//Business Unit Action
const BusinessUnitAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="business_unit"
        title={`Client Name: ${_.get(props, "name", "-")}`}
      >
        <ListLableValue
          label={"Client Location -"}
          value={_.capitalize(
            _.get(props, "content.business_unit_location", "-")
          )}
        />
      </TimelineCardWraper>
    </>
  );
};
//Realaion Manger Action
const RelationMangerAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="rel_manager"
        title={<>RM Assign : {_.get(props, "name", "-")} Assigned as RM</>}
      ></TimelineCardWraper>
    </>
  );
};

const UnAssignRelationMangerAction = (props) => {
  return (
    <>
      <TimelineCardWraper
        {...props}
        icon="rel_manager"
        title={<>RM Unassign : {_.get(props, "name", "-")} Unassigned</>}
      ></TimelineCardWraper>
    </>
  );
};

const TimelineMap = (props) => {
  const {
    currentOrganization,
    currentDefaultFormatDetails,
    referenceData,
    userInfo,
  } = useContext(MaxyfiContext);
  let { type } = props;

  const { customerId } = useParams();

  const markAsReadMutation = useMutation(
    (timelineId) =>
      markEmailAsReadCustomerTimeline({
        customerId: customerId,
        timelineId,
        organization: currentOrganization,
        entity: "customer",
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.refetchQueries([
          `TIMELINE_${CUSTOMER_OVERVIEW}_${customerId}`,
        ]);
      },
    }
  );
  const dispatch = useDispatch();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };
  const format = useFormat();
  switch (type) {
    case "LGA":
      return (
        <>
          <div style={{ position: "relative" }}>
            <TimelineCardWraper
              {...props}
              icon="legal_action"
              title={
                <>
                  Legal- {_.get(props, "content.lst", "-")} {"-"}
                  {""}
                  {_.get(props, "content.ltdeb.currency", "")}{" "}
                  {format.currency({
                    amount: _.get(props, "content.ltdeb.value", ""),
                  })}{" "}
                  -{" "}
                  {_.get(props, "content.ldef", []).length > 0
                    ? _.get(props, "content.ldef", []).length +
                      " " +
                      "Defendants"
                    : "-"}
                </>
              }
            >
              <ListLableValue
                label={"Motion:"}
                value={
                  _.get(props, "content.lmot", []).length > 0
                    ? _.get(props, "content.lmot", [])
                        .map((to) => (to.typ ? to.typ : "-"))
                        .join(", ")
                    : "-"
                }
              />
              <ListLableValue
                label={"Debts:"}
                value={
                  _.get(props, "content.ldeb", []).length > 0
                    ? _.get(props, "content.ldeb", [])
                        .map((to) => `${to ? to : "-"}`)
                        .join(", ")
                    : "-"
                }
              />
              <ListLableValue
                label={"Defendants:"}
                value={
                  _.get(props, "content.ldef", []).length > 0
                    ? _.get(props, "content.ldef", [])
                        .map((to) => to)
                        .join(", ")
                    : "-"
                }
              />
            </TimelineCardWraper>
            <div style={{ position: "absolute", right: "3%", top: "5%" }}>
              <Typography
                type="p"
                style={{
                  color: "#516BEB",
                  fontSize: "13px",
                  fontWeight: "400",
                  lineHeight: "15.23px",
                }}
                onClick={() => {
                  setDrawer(LEGAL_ACTION_DRAWER, { isLegalAudit: true });
                  // LEGAL_AUDIT
                }}
              >
                View More
              </Typography>
            </div>
          </div>
        </>
      );
    case REVERSE_PAYMENT_TMD:
      let reverseAmount = format.currency({
        amount: _.get(props, "content.portal_payment_amount.value", null),
      });
      let positiveFormattedAmount = reverseAmount.replace("-", "");

      return (
        <TimelineCardWraper
          {...props}
          icon="payment"
          title={
            <>
              Payment Reversed -{" "}
              {`${currentDefaultFormatDetails.default_currency} ${
                _.get(props, "content.portal_payment_amount.value", "")
                  ? positiveFormattedAmount
                  : ""
              } - Reversed`}
            </>
          }
        >
          <ListLableValue
            label={"Reversed Payment Date:"}
            value={
              _.get(props, "executed_on", null)
                ? format.date({
                    value: _.get(props, "executed_on", null),
                  })
                : "-"
            }
          />
          <ListLableValue
            label={"Invoices #:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((to) => to)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.portal_payment_method", "")}
          />

          <ListLableValue
            label={"Payment Date"}
            value={
              _.get(props, "content.rev_pmt_dt", "-")
                ? format.date({
                    value: _.get(props, "content.rev_pmt_dt", "-"),
                  })
                : "-"
            }
          />

          <ListLableValue
            label={"Payment Reference:"}
            value={
              _.get(props, "content.portal_payment_client_references", [])
                .length > 0
                ? _.get(props, "content.portal_payment_client_references", [])
                    .map((to) => to)
                    .join(", ")
                : "-"
            }
          />

          <ListLableValue
            label={"Payment Type:"}
            value={_.get(props, "content.portal_payment_method", "-")}
          />
          <ListLableValue
            label={"Payment Reversal:"}
            value={_.get(props, "comment", "-")}
          />
          {/* <ListLableValue
            label={"Payment Reversal"}
            value={`Payment reversal of${_.get(
              props,
              "content.portal_payment_amount.currency",
              ""
            )} ${_.get(
              props,
              "content.portal_payment_amount.value",
              ""
            )} on ${_.get(props, "executed_on", "")}`}
          /> */}
        </TimelineCardWraper>
      );
    case BANKRUPTCY:
      let fileDate = moment
        .utc(_.get(props, "content.bnkfldt", ""))
        ?.tz(currentDefaultFormatDetails.time_zone)
        ?.format(currentDefaultFormatDetails.date_format);

      return (
        <TimelineCardWraper
          {...props}
          icon="bankruptcy"
          title={<>Bankruptcy - {_.get(props, "content.bnkstatus", "")}</>}
        >
          <ListLableValue
            label={"SSN:"}
            value={_.get(props, "content.ssn", "")}
          />
          <ListLableValue
            label={"First Name:"}
            value={_.get(props, "content.fnam", "")}
          />
          <ListLableValue
            label={"Last Name:"}
            value={_.get(props, "content.lname", "")}
          />

          <ListLableValue
            label={"State:"}
            value={format.rd({
              id: _.get(props, "content.bnkstate", ""),
              name: "state_list",
            })}
          />

          <ListLableValue
            label={"Banruptcy#:"}
            value={_.get(props, "content.bnkno", "")}
          />

          <ListLableValue
            label={"Chapter:"}
            value={_.get(props, "content.bnkchp", "")}
          />

          <ListLableValue
            label={"Court:"}
            value={_.get(props, "content.bnkcourt", "")}
          />

          <ListLableValue
            label={"Docket#:"}
            value={_.get(props, "content.dockno", "")}
          />
          <ListLableValue
            label={"Date Filed:"}
            value={_.get(props, "content.bnkfldt", "") ? fileDate : ""}
          />
        </TimelineCardWraper>
      );
    case GENERIC_ACTION:
      return <CaptureAction {...props} />;
    case CALL:
      return <CallAction {...props} />;

    case ESIGNREPORT:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "content.category", "-")} :{" "}
              {_.get(props, "content.template_id", "-")} -{" "}
              {_.get(props, "content.payment_type")}
            </>
          }
        >
          <ListLableValue
            label={"E-Signature Template:"}
            value={_.get(props, "content.letter_name", "-")}
          />
        </TimelineCardWraper>
      );

    case TL_FIELD_VISIT:
      return <FieldVisitAction {...props} />;
    case EMAIL:
      // Reference to the content div
      let deliveredEmail = props?.content?.delivered_recipients
        ? props?.content?.delivered_recipients
        : [{}];

      let failedEmail = props?.content?.failed_recipients
        ? props?.content?.failed_recipients
        : [{}];

      let EmailTableData = [...deliveredEmail, ...failedEmail];

      return (
        <div style={{ position: "relative", backgroundColor: "#ffffff" }}>
          <EmailAction {...props} />
          {props.is_receiver && !props.is_read ? (
            <div style={{ position: "absolute", right: "1%", top: 0 }}>
              <TextButton
                size={"mini"}
                kind="secondary"
                isLoading={markAsReadMutation.isLoading}
                disabled={markAsReadMutation.isLoading}
                onClick={async () => {
                  await markAsReadMutation.mutate(props._id);
                }}
              >
                Mark as Read
              </TextButton>
            </div>
          ) : !props.is_receiver && props.deliverablity_status ? (
            <StatefulPopover
              content={({ close }) => {
                return (
                  <div
                    //  style={{ paddingTop: "10px", paddingBottom: "20px" }}
                    style={{
                      width: "600px",
                      borderRadius: "5px",
                      height: "250px",
                      backgroundColor: "#ffffff",
                      padding: "15px",
                      overflow: "scroll",
                    }}
                  >
                    <div
                      style={{
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <Label3>Email Delivery Status :</Label3>
                    </div>
                    <table>
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <th>Email ID</th>
                        <th>Status</th>
                        <th>Time</th>
                        <th>Reason</th>
                      </tr>

                      {[...(EmailTableData ? EmailTableData : {})].map(
                        (e, i) => {
                          // let date = e?.delivered_on?.replace("e", "");

                          //
                          let deliveredDate = moment
                            .utc(e?.delivered_on)
                            ?.tz(currentDefaultFormatDetails.time_zone)
                            ?.format(currentDefaultFormatDetails.date_format);

                          let reasonList = [
                            {
                              reasonId: "550",
                              label: "Mailbox Does Not Exist",
                            },
                            {
                              reasonId: "5.1.1",
                              label: "Mailbox Does Not Exist",
                            },
                            {
                              reasonId: "552",
                              label: "Message Too Large",
                            },
                            {
                              reasonId: "5.3.4",
                              label: "Message Too Large",
                            },
                            {
                              reasonId: "5.2.2",
                              label: "Mailbox Full",
                            },
                            {
                              reasonId: "500",
                              label: "Message Content Rejected",
                            },
                            {
                              reasonId: "5.6.1",
                              label: "Message Content Rejected",
                            },
                            {
                              reasonId: "554",
                              label: "Unknown Failure",
                            },
                            {
                              reasonId: "5.0.0",
                              label: "Unkown Failure",
                            },
                            {
                              reasonId: "450",
                              label: "Temporary Failure",
                            },
                            {
                              reasonId: "4.0.0",
                              label: "Temporary Failure",
                            },
                            // { reasonId: "5.3.0", label: "Temporary Failure" },
                          ];

                          let findingReason = reasonList?.find(
                            (i) => i?.reasonId === e?.reason
                          );

                          return (
                            <tr>
                              {/* // PRODUCT */}
                              <td>
                                {/* <div style={{ width: "120px", marginTop: "15px" }}> */}
                                {e?.value ? e?.value : ""}
                                {/* </div> */}
                              </td>
                              {/* // DESCRIPTION */}
                              <td>
                                <div style={{ width: "" }}>
                                  {e?.reason ? (
                                    <div style={{ color: "#FD372A" }}>
                                      Failed
                                    </div>
                                  ) : (
                                    <div style={{ color: "#4CAF50" }}>
                                      Delivered
                                    </div>
                                  )}
                                </div>
                              </td>
                              {/* // COST */}
                              <td>
                                <div style={{ width: "" }}>
                                  {e?.delivered_on ? (
                                    <>
                                      {deliveredDate}{" "}
                                      <FormattedTime value={e?.delivered_on} />
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                              {/* // QUANTITY */}
                              <td>
                                <div style={{ width: "" }}>
                                  {e?.reason
                                    ? findingReason?.label
                                      ? findingReason?.label
                                      : "Unknown Error"
                                    : ""}
                                  {/* {findingReason && e?.reason? findingReason?.label:"Unknown Error Occurred"} */}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </table>

                    <div
                      style={{
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <Label3>Email Read At :</Label3>
                    </div>
                    <table>
                      <tr style={{ backgroundColor: "#FFFFFF" }}>
                        <th>Read Time</th>
                        <th>Read from IP Address</th>
                      </tr>

                      {[
                        ...(props?.content?.open_status
                          ? props?.content?.open_status
                          : {}),
                      ].map((e, i) => {
                        // let date = e?.delivered_on?.replace("e", "");

                        //
                        let deliveredDate = moment
                          .utc(e.open_at)
                          ?.tz(currentDefaultFormatDetails.time_zone)
                          ?.format(currentDefaultFormatDetails.date_format);

                        return (
                          <tr>
                            {/* // PRODUCT */}
                            <td>
                              {/* <div style={{ width: "120px", marginTop: "15px" }}> */}
                              {deliveredDate}{" "}
                              <FormattedTime value={e?.open_at} />
                              {/* </div> */}
                            </td>
                            {/* // DESCRIPTION */}
                            <td>
                              <div style={{ width: "" }}>
                                {e?.ip_address ? e.ip_address : ""}
                              </div>
                            </td>
                            {/* // COST */}
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                );
              }}
            >
              <div style={{ position: "absolute", right: "1%", top: 0 }}>
                <DeliveryStatus type={props?.deliverablity_status} />
              </div>
            </StatefulPopover>
          ) : (
            <></>
          )}
        </div>
      );
    case SMS:
      return <SmsAction {...props} />;
    case HOLDACTION:
      return <HoldAction {...props} />;
    case CONTACTS:
      return <ContactAction {...props} />;
    case INSTANT_CALL_PAYMENT:
      return <InstantCallAction {...props} />;
    case EDIT_PROCESS_ACTION:
      return <EditProcessGroupAction {...props} />;
    case EDIT_CONUSMER_ACTION:
      return <EditConsumerGroupAction {...props} />;
    case RELEASED:
      return (
        <TimelineCardWraper
          {...props}
          icon="resume_action"
          title={<>Release Action</>}
        />
      );

    case LETTER:
      return (
        <TimelineCardWraper
          {...props}
          icon="letter"
          title={
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              Letter - {props && props.name}{" "}
              <LetterCardStatus type={props?.content?.delivery_status} />
            </div>
          }
        >
          <ListLableValue
            label={"Recepient:"}
            value={
              _.get(props, "content.to[0]", "")
                ? `${_.get(props, "name", "")} ${_.get(
                    props,
                    "content.to[0].line_1",
                    ""
                  )} ${_.get(props, "content.to[0].line_2", "")}  ${_.get(
                    props,
                    "content.to[0].city",
                    ""
                  )} ${_.get(props, "content.to[0].state", "")} ${_.get(
                    props,
                    "content.to[0].country",
                    ""
                  )} ${_.get(props, "content.to[0].zip_code", "")} `
                : ""
            }
          />
          <ListLableValue
            label={"Date of Communication:"}
            value={_.get(props, "content.date_of_communication", "")}
            type="DATEORGTZ"
          />
          <ListLableValue
            label={"Delivery Method:"}
            value={format.rd({
              id: _.get(props, "content.delivery_method", ""),
              name: "letter_delivery_method_list",
            })}
          />

          <ListLableValue
            label={"Tracking URL:"}
            value={_.get(props, "content.tracking_url", "")}
            type="HYPERLINK"
          />
          {_.get(props, "content.attachments", []).map((i) => {
            if (!i || !i.name) {
              return <></>;
            }
            let splitType = i && i.name && i?.name?.split(".");

            return (
              <div style={{ width: "350px" }}>
                <DocumentCard
                  res={{
                    file_name: i.name,
                    mime_type: splitType[1],
                    _id: i.ref,
                    customerId: _.get(props, "customer_id", ""),
                    tab: "timeline",
                  }}
                />
              </div>
            );
          })}
        </TimelineCardWraper>
      );

    case WORKFLOW:
      return (
        <TimelineCardWraper
          {...props}
          icon="workflow"
          title={
            <>
              Workflow: {_.get(props, "name", "")}{" "}
              {_.get(props, "is_assigned", false) ? "Assigned" : "Unassigned"}
            </>
          }
        >
          <ListLableValue
            label="Start Date:"
            value={
              _.get(props, "content.workflow_start_date", null)
                ? format.date({
                    value: _.get(props, "content.workflow_start_date", null),
                  })
                : ""
            }
          />
          <ListLableValue
            label="Start Stage:"
            value={_.get(props, "content.workflow_start_stage", "")}
          />
          {_.get(props, "content.sgy", "") ? (
            <ListLableValue
              label="Strategy:"
              value={_.get(props, "content.sgy", "")}
            />
          ) : (
            <></>
          )}
        </TimelineCardWraper>
      );

    case WORKFLOW_STRATEGY:
      return (
        <TimelineCardWraper
          {...props}
          icon="workflow"
          title={<>Workflow Strategy</>}
        >
          <ListLableValue
            label="Strategy:"
            value={_.get(props, "name", "") ? _.get(props, "name", "") : ""}
          />
        </TimelineCardWraper>
      );

    case DISPUTE:
      const orgRef = referenceData?.organizations?.find(
        ({ id }) => id === currentOrganization
      );

      let disputeStatus = referenceData["dispute_status"];
      const disputeInvoices = props?.content?.dispute_invoices
        ?.map((i) => i.toString())
        .toString()
        .replaceAll(",", ", ");

      let refType = referenceData["dispute_type"];
      const timeZone = orgRef?.time_zone;

      const findingStatus = disputeStatus?.find(
        (i) => i.id === props?.content?.dispute_status
      );
      const findingValue = refType?.find(
        (i) => i.id === props?.content?.dispute_type
      );
      const disputeRaisedOn = moment
        .utc(props?.executed_on)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);

      const timezoneBasedDate = moment
        .utc(props?.content?.dispute_review_date)
        ?.tz(timeZone)
        ?.format(currentDefaultFormatDetails.date_format);
      const subjectBodyDispatch = {
        ...props.content,
        type: DISPUTE,
        disputeSubject: `${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        } : Dispute : ${
          findingValue && findingValue.label ? findingValue.label : "-"
        } Raised on ${disputeRaisedOn}`,
        disputeBody: `Hi,<br /><br />Find below the details of the Dispute Raised by ${
          _.get(props, "cna", "") ? _.get(props, "cna", "") : "-"
        }<br />Dispute Type: ${
          findingValue && findingValue?.label ? findingValue?.label : "-"
        }<br />Dispute Details: ${
          _.get(props, "content.dispute_details", "")
            ? _.get(props, "content.dispute_details", "")
            : "-"
        }<br />Disputed Invoice Numbers: ${
          disputeInvoices ? disputeInvoices : "-"
        }<br /><br />Dispute Status : ${
          findingStatus?.label ? findingStatus?.label : "-"
        }<br />Dispute Review Date : ${
          timezoneBasedDate ? timezoneBasedDate : "-"
        }<br />User Comments :  ${
          props.comment ? props.comment : "-"
        }<br /><br />Regards<br />${
          userInfo && userInfo?.display_name ? userInfo?.display_name : "-"
        }`,
      };
      return (
        <TimelineCardWraper
          {...props}
          icon="dispute"
          title={
            <>
              Dispute {_.get(props, "is_adhoc_action", false) ? "(QA)" : ""} -{" "}
              {_.get(props, "content.dispute_status", "") === "CLOSED" ? (
                <>
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Closed
                </>
              ) : _.get(props, "content.dispute_status", "") === "OPEN" ? (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  - Open
                </>
              ) : (
                <>
                  {" "}
                  {format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })}{" "}
                  {"-"} In-Progress
                </>
              )}
            </>
          }
        >
          <ListLableValue
            label="Dispute Type:"
            value={
              _.get(props, "content.dispute_type", "")
                ? format.rd({
                    id: _.get(props, "content.dispute_type", ""),
                    name: "dispute_type",
                  })
                : ""
            }
          />
          <ListLableValue
            label="Assigned To:"
            value={
              _.get(props, "content.assigned_to", "")
                ? format.rd({
                    id: _.get(props, "content.assigned_to", ""),
                    name: "users",
                  })
                : "-"
            }
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.dispute_invoices", []).length > 0
                ? _.get(props, "content.dispute_invoices", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Amount"
            value={
              _.get(props, "content.amt", null)
                ? `${
                    currencyList[currentDefaultFormatDetails.default_currency]
                  } ${format.currency({
                    amount: _.get(props, "content.amt", null),
                  })}`
                : "-"
            }
          />
          <ListLableValue
            label="Dispute Detailed Text:"
            value={_.get(props, "content.dispute_details", "")}
          />
          <ListLableValue
            label="Satus:"
            value={
              _.get(props, "content.dispute_status", "")
                ? _.capitalize(_.get(props, "content.dispute_status", ""))
                : "-"
            }
          />
          <ListLableValue
            label="Review Date"
            value={_.get(props, "content.dispute_review_date", "")}
            type="DATEORGTZ"
          />
          <StatusOfAction
            actionName={ACT_RECORD_DISPUTE}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
          />
          <RBACWrapper role={EMAIL_REPLY} type="PERMISSION">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Icon
                  icon="forward"
                  isPressable
                  onClick={async () => {
                    return await getDisputeContent({
                      organization: currentOrganization,
                      customerId: props?.customer_id,
                      disputeId: props?._id,
                    })
                      .then((res) => {
                        dispatch(
                          emailDataToDrawer({
                            emailType: FORWARD,
                            emailData: {},
                            subData: undefined,
                            ...(_.get(res, "data.doc.template_id", "")
                              ? {
                                  template_id: _.get(
                                    res,
                                    "data.doc.template_id",
                                    ""
                                  ),
                                }
                              : {}),
                          })
                        );

                        setDrawer(EMAIL_TIMELINE_LAYOUT, {
                          emailType: FORWARD,
                          ...(_.get(res, "data.doc.template_id", "")
                            ? {
                                template_id: _.get(
                                  res,
                                  "data.doc.template_id",
                                  ""
                                ),
                              }
                            : {}),

                          subData: undefined,
                          ...(_.get(res, "data.doc.template_id", "")
                            ? {
                                template_id: _.get(
                                  res,
                                  "data.doc.template_id",
                                  ""
                                ),
                              }
                            : {}),
                          type: {
                            ...props.content,
                            type: DISPUTE,
                            disputeSubject: _.get(res, "data.doc.subject", ""),
                            disputeBody: _.get(res, "data.doc.value", ""),
                          },
                        });
                      })
                      .catch((err) =>
                        toast.error(_.get(err, "response.data.message", ""))
                      );
                  }}
                ></Icon>
              </div>
            </div>
          </RBACWrapper>
        </TimelineCardWraper>
      );

    case NEXTACTION:
      return (
        <TimelineCardWraper
          {...props}
          icon="next_action"
          title={
            <>
              Next Action :{" "}
              {RDRapper(
                "workflow_type",
                _.get(props, "content.next_action_type", "")
              )}{" "}
              on {format.date({ value: _.get(props, "content.planned_on") })}
            </>
          }
        >
          <ListLableValue
            label="Next Action Name"
            value={_.startCase(_.get(props, "content.next_action_type", ""))}
          />
          <ListLableValue
            label="Next Action Date"
            value={_.get(props, "content.planned_on", "")}
            type="DATEORGTZ"
          />
        </TimelineCardWraper>
      );
    case NOTES:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="note"
            title={
              <>
                {_.capitalize(_.get(props, "type", ""))}:{" "}
                {_.get(props, "action_taken", "_")}{" "}
              </>
            }
          >
            <ListLableValue label="Note:" value={_.get(props, "comment", "")} />
            {props && props?.content && props?.content.notes_category && (
              <>
                <ListLableValue
                  label="Category:"
                  value={_.get(props, "content.notes_category", "")}
                  referance="notes_category"
                  type="RDTEXT"
                />
              </>
            )}
            {props && props?.content && props?.content.notes_type && (
              <>
                <ListLableValue
                  label="Type:"
                  value={_.capitalize(_.get(props, "content.notes_type", ""))}
                />
              </>
            )}
            <StatusOfAction
              actionName={ACT_NOTES}
              is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            />
          </TimelineCardWraper>
        </>
      );
    case INVOICE_UPDATE:
      return (
        <TimelineCardWraper
          {...props}
          icon="invoice_update"
          title={
            <div style={{ display: "flex", gap: "5px" }}>
              Invoice Updated -{" "}
              {_.startCase(_.get(props, "content.invoice_number", ""))}{" "}
              {_.get(props, "content.is_cancelled", false) ? (
                <div style={{ paddingLeft: "5px", color: "red" }}>
                  ( Cancelled )
                </div>
              ) : (
                <></>
              )}
            </div>
          }
        >
          <ListLableValue
            label="Invoice Number:"
            value={_.startCase(_.get(props, "content.invoice_number", ""))}
          />
          <ListLableValue
            label={"Client Ref Id:"}
            value={
              _.get(props, "content.portal_payment_client_references", [])
                .length > 0
                ? _.get(props, "content.portal_payment_client_references", [])
                    .map((to) => to)
                    .join(", ")
                : "-"
            }
          />
          <ListLableValue
            label="Invoice Date:"
            value={_.get(props, "content.invoice_date", "")}
            type="DATEORGTZ"
          />
          <ListLableValue
            label="Invoice Amount:"
            currency={{
              value: _.get(props, "content.amount.value", ""),
              currency: _.get(props, "content.amount.currency", ""),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label="Due Date:"
            value={_.get(props, "content.due_date", "")}
            type="DATEORGTZ"
          />
          {_.get(props, "content.itm_rect", false) ? (
            <ListLableValue
              label="Itemization Error Rectified:"
              value={"Yes"}
            />
          ) : (
            <></>
          )}
        </TimelineCardWraper>
      );
    case PORTAL_PAYMENT_PLAN:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              Portal - Consumer Submitted {_.get(props, "name", "-")} -{" "}
              {format.rd({
                id: _.get(props, "content.promise_status", ""),
                name: "promise_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Invoice#:"}
            value={_.get(props, "content.portal_payment_invoice_number", [])
              .map((i) => i.toString())
              .toString()
              .replaceAll(",", ", ")}
          />
          <ListLableValue
            label={"Total Payment Amount:"}
            currency={{
              value: _.get(props, "content.payment_plan_amount.value", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Discount Slab & Rate:"}
            value={`${_.get(props, "content.disc_slb", "-")} & ${_.get(
              props,
              "content.disc_prc",
              "-"
            )}%`}
          />
          <ListLableValue
            label={"Discount Amount:"}
            currency={{
              value: _.get(props, "content.disc_amt", "-"),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Installment Amount:"}
            currency={{
              value: _.get(
                props,
                "content.payment_plan_instalation_amount.value",
                "-"
              ),
              currency: _.get(
                props,
                "content.payment_plan_amount.currency",
                "-"
              ),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Planned Start Date:"}
            value={_.get(props, "content.payment_plan_start_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Payment Frequency:"}
            value={_.get(props, "content.payment_plan_frequency", "-")}
          />
          <ListLableValue
            label={"Payment Method:"}
            value={_.get(props, "content.payment_plan_method", "-")}
          />
          {_.get(props, "content.cpcm", "") ? (
            <ListLableValue
              label={"Confirmation Message:"}
              value={`${_.get(
                props,
                "content.cpcm",
                "-"
              )} - Accepted by ${_.get(props, "cna", "-")} on ${format.date({
                value: _.get(props, "executed_on"),
                includeTime: true,
              })} `}
            />
          ) : (
            <></>
          )}
          <StatusOfAction
            actionName={ACT_PAYMENT_PLAN}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
          {/* pending  status of the  reposence like status */}
        </TimelineCardWraper>
      );
    case TL_PAYMENT:
    case "ADP":
      return <PaymentAction {...props} />;
    case CUSTOMER_NAME_UPDATE:
      return <CustomerNameUpdateAction {...props} />;
    case CALL_RECORDING:
      return <CallRecordingAction {...props} />;
    case TL_RELATION_MANAGER:
      return <RelationMangerAction {...props} />;
    case UN_RELATION_MANAGER:
      return <UnAssignRelationMangerAction {...props} />;
    case TL_UPDATE_PROMISE_DATE:
      return <UpdatePromiseDateAction {...props} />;
    case TL_UPDATE_PAYMENT_PLAN_DATE:
      return <UpdatePaymentPlanDateAction {...props} />;
    case CUSTOMERPORTAL:
      return <CustomerPortalAction {...props} />;
    case PORTAL_PAYMENT:
      return <PortalPaymentAction {...props} />;

    case TL_DOCUMENT_REQUEST_INFO:
      return <DocumentRequestInfoAction {...props} />;
    case BUSINESS_UNIT:
      return <BusinessUnitAction {...props} />;
    case CUSTOMERT_STATUS:
      return (
        <TimelineCardWraper
          {...props}
          icon="customer_portal"
          title={<>Consumer Status : {_.startCase(_.get(props, "name", ""))}</>}
        ></TimelineCardWraper>
      );

    case TL_CLARIFICATION_REQUEST_INFO:
      return (
        <TimelineCardWraper
          {...props}
          icon="request_info"
          title={
            <>
              {_.capitalize(_.get(props, "content.category", "-"))} Requested{" "}
              {_.startCase(
                _.capitalize(_.get(props, "content.req_info_type", "-"))
              )}
              - {_.capitalize(_.get(props, "content.req_info_status", "-"))}
            </>
          }
        >
          <ListLableValue
            label={"category:"}
            value={_.capitalize(_.get(props, "content.category", "-"))}
          />
          <ListLableValue
            label={"Sub Category:"}
            value={_.startCase(
              _.capitalize(_.get(props, "content.req_info_type", "-"))
            )}
          />
          <ListLableValue
            label={"Invoice#:"}
            value={_.get(props, "content.req_info_invoices", ["-"])
              .map((value) => value.value || value)
              .join(",")}
          />
          <ListLableValue
            label={"Details:"}
            value={_.get(props, "content.req_info_details", "-")}
          />
          <ListLableValue
            label={"Status:"}
            value={_.capitalize(_.get(props, "content.req_info_status", "-"))}
          />
          <ListLableValue
            label={"Assigned To:"}
            value={
              _.get(props, "executed_by", "-")
                ? format.rd({ id: props && props.executed_by, name: "users" })
                : "-"
            }
          />
          <ListLableValue
            label={"Raised Date:"}
            value={_.get(props, "executed_on", "-")}
            type={"DATEORGTZ"}
          />
          {/* pending  status of the  reposence like status */}
        </TimelineCardWraper>
      );

    case TL_SKIP_ACTIONS:
      return (
        <TimelineCardWraper
          {...props}
          icon="skip_action"
          title={
            <>
              {_.startCase(_.get(props, "name", ""))} on{" "}
              {format.date({ value: _.get(props, "executed_on") })}{" "}
            </>
          }
        ></TimelineCardWraper>
      );

    case PAYMENT:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="payment"
            title={
              <>
                Payment:{" "}
                {_.get(props, "content.portal_payment_amount.currency", "-")}
                {format.currency({
                  amount:
                    _.get(props, "content.portal_payment_amount.value", "-") ||
                    0,
                })}{" "}
                {_.get(props, "action_taken", "-")}
              </>
            }
          >
            <ListLableValue
              label={"Payment Amount:"}
              currency={{
                value: _.get(props, "content.portal_payment_amount.value", "-"),
                currency: _.get(
                  props,
                  "content.portal_payment_amount.currency",
                  "-"
                ),
              }}
              type={"CURRENCY"}
            />
            <ListLableValue
              label={"Payment Date:"}
              value={_.get(props, "content.portal_payment_date", "-")}
              type={"DATEORGTZ"}
            />
            <ListLableValue
              label={"Payment method:"}
              value={_.get(props, "content.portal_payment_gateway_name", "-")}
            />
            <ListLableValue
              label={"Payment Reference:"}
              value={_.get(props, "content.portal_payment_reference", "-")}
            />
            <ListLableValue
              label={"Invoice#:"}
              value={_.get(props, "content.portal_payment_invoice_number", [
                "-",
              ])
                .map((value) => value.value || value)
                .join(",")}
            />
          </TimelineCardWraper>
        </>
      );

    case PAYMENTDETAILS:
      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              {_.get(props, "is_receiver", false)
                ? "Portal"
                : _.get(props, "action_source", "")}{" "}
              - {_.get(props, "name", "")} by{" "}
              {format.date({ value: _.get(props, "content.promise_date") })}{" "}
              {format.rd({
                id: _.get(props, "content.promise_status", ""),
                name: "promise_status",
              })}
            </>
          }
        >
          <ListLableValue
            label={"Receiver:"}
            value={
              props?.content?.to.length > 0
                ? props.content.to
                    .map(
                      (to) =>
                        `${_.capitalize(_.get(to, "name"))} ${
                          to.value
                        } ${format.rd({
                          id: _.get(to, "designation", ""),
                          name: "recipient_type",
                        })}`
                    )
                    .join(", ")
                : "-"
            }
          />

          <ListLableValue
            label={"Promise Type:"}
            value={_.get(props, "content.promise_type", "-")}
            type="RDTEXT"
            referance="promise_type"
          />

          <ListLableValue
            label={"Promise Amount:"}
            currency={{
              value: _.get(props, "content.promise_amount.value", "-"),
              currency: _.get(props, "content.promise_amount.currency", "-"),
            }}
            type={"CURRENCY"}
          />
          <ListLableValue
            label={"Promise Date:"}
            value={_.get(props, "content.promise_date", "-")}
            type={"DATEORGTZ"}
          />
          <ListLableValue
            label={"Invoices#:"}
            value={
              _.get(props, "content.portal_payment_invoice_number", []).length >
              0
                ? _.get(props, "content.portal_payment_invoice_number", [])
                    .map((inv) => inv)
                    .join(", ")
                : "-"
            }
          />
          <StatusOfAction
            actionName={ACT_PROMISE_TO_PAY}
            isCalender={_.get(props, "content.is_enable_calendar_alert", false)}
            is_hold_action={_.get(props, "content.is_hold_workflow", false)}
            isConfirmationMsg={_.get(
              props,
              "content.is_send_confirmation_message",
              false
            )}
            isPromiseFollowMsg={_.get(
              props,
              "content.is_send_action_follow_up",
              false
            )}
          />
        </TimelineCardWraper>
      );

    // case "INVA_ADJ":
    //   return (
    //     <>
    //       <TimelineCardWraper
    //         {...props}
    //         icon="invoice_update"
    //         title={
    //           <>
    //             Invoice Updated - {_.get(props, "content.invoice_number", "-")}
    //           </>
    //         }
    //       >
    //         <ListLableValue
    //           label={"Invoice Amount:"}
    //           currency={{
    //             value: _.get(props, "content.amount.value", "-"),
    //             currency: _.get(props, "content.amount.currency", "-"),
    //           }}
    //           type={"CURRENCY"}
    //         />
    //         <ListLableValue
    //           label={"Adjustment Amount:"}
    //           currency={{
    //             value: _.get(props, "content.overdue_amount.value", "-"),
    //             currency: _.get(props, "content.overdue_amount.currency", "-"),
    //           }}
    //           type={"CURRENCY"}
    //         />
    //         <ListLableValue
    //           label={"New Invoice Amount:"}
    //           currency={{
    //             value: _.get(props, "content.promise_amount.value", "-"),
    //             currency: _.get(props, "content.overdue_amount.currency", "-"),
    //           }}
    //           type={"CURRENCY"}
    //         />
    //         <ListLableValue
    //           label={"Client:"}
    //           value={`${format.rd({
    //             name: "business_unit_list",
    //             id: _.get(props, "content.business_unit", "-"),
    //           })}`}
    //         />
    //       </TimelineCardWraper>
    //     </>
    //   );

    case INVA_ADJ:
      return <InvAdjustmentAction {...props} />;

    case "whatsapp":
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="whatsapp"
            title={
              <>
                {props && props.is_sender ? "WhatsApp" : "WhatsApp - Reply"} -{" "}
                {_.get(props, "content.subject", "-")}
              </>
            }
          >
            <ListLableValue
              label={"Recepients"}
              value={
                _.get(props, "content.to", [])
                  ? _.get(props, "content.to", [])
                      .map(
                        (to) => `${_.capitalize(_.get(to, "name"))} ${to.value}`
                      )
                      .join(", ")
                  : "-"
              }
            />
            <ListLableValue value={_.get(props, "content.body", "-")} />
            <div
              onClick={() => {
                setDrawer(WHATSAPP_CHAT, {
                  phoneNumber: _.get(props, "content.to", [])
                    ? _.get(props, "content.to[0].value", "")
                    : "",
                });
              }}
              style={{
                display: "flex",
                gap: "5px",
                cursor: "pointer",
                justifyContent: "flex-end",
              }}
            >
              <Icon
                icon="sms_outline"
                size={18}
                color="#516BEB"
                isPressable
              ></Icon>
              <Typography type="p" className="text-primary">
                Chat Now
              </Typography>
            </div>
          </TimelineCardWraper>
        </>
      );

    case RESCHEDULE_NEXT_ACTION_DATE:
      return (
        <>
          <TimelineCardWraper
            {...props}
            icon="payment"
            title={
              <>
                Workflow Reschedule:{" "}
                {_.get(props, "content.reschedule_date", null)
                  ? "Reschedule Date"
                  : ""}
                {_.get(props, "content.reschedule_date", null)
                  ? format.date({
                      value: _.get(props, "content.reschedule_date", null),
                    })
                  : ""}{" "}
              </>
            }
          ></TimelineCardWraper>
        </>
      );

    case PPL_CHNG:
      let paymentActionType = {
        Added: "Installment Added",
        Updated: "Installment modified",
        Deleted: "Installment cancelled",
      };

      return (
        <TimelineCardWraper
          {...props}
          icon="promise_to_pay"
          title={
            <>
              Payment Plan -{" "}
              {paymentActionType[_.get(props, "action_taken", "")]}:{" "}
              {_.get(props, "action_taken", "") === "Updated"
                ? format.currency({
                    amount: _.get(props, "content.rvsd_amt", ""),
                  })
                : format.currency({
                    amount: _.get(props, "content.pln_amt", ""),
                  })}{" "}
              on{" "}
              {_.get(props, "action_taken", "") === "Updated"
                ? format.date({ value: _.get(props, "content.revised_date") })
                : format.date({ value: _.get(props, "content.planned_date") })}
            </>
          }
        >
          {_.get(props, "action_taken", "") === "Updated" &&
          _.get(props, "content.pln_amt", "") ? (
            <ListLableValue
              label={"Before "}
              value={`${format.currency({
                amount: _.get(props, "content.pln_amt", ""),
              })} on ${format.date({
                value: _.get(props, "content.planned_date", "-"),
              })}`}
            />
          ) : (
            <></>
          )}

          {_.get(props, "action_taken", "") !== "Deleted" &&
          _.get(props, "content.rvsd_amt", "") ? (
            <ListLableValue
              label={"After"}
              value={`${format.currency({
                amount: _.get(props, "content.rvsd_amt", ""),
              })} on ${format.date({
                value: _.get(props, "content.revised_date", "-"),
              })}`}
            />
          ) : (
            <></>
          )}

          {_.get(props, "action_taken", "") !== "Deleted" &&
          _.get(props, "executed_by", "") ? (
            <ListLableValue
              label={"Audit User"}
              value={`${format.rd({
                id: _.get(props, "executed_by", "-"),
                name: "users",
              })}`}
            />
          ) : (
            <></>
          )}

          {_.get(props, "action_taken", "") !== "Deleted" &&
          _.get(props, "executed_on", "") ? (
            <ListLableValue
              label={" Date / Time"}
              value={format.date({
                value: _.get(props, "executed_on", ""),
                includeTime: true,
              })}
            />
          ) : (
            <></>
          )}
        </TimelineCardWraper>
      );
    default:
      return null;
  }
};

const TimelineDrawer = () => {
  const { customerId } = useParams();
  const { currentOrganization } = useContext(MaxyfiContext);
  const [timelineData, setTimeLineData] = useState([]);
  const [isCondenced, setIsCondenced] = useState(false);
  const [internalAction, setInternalAction] = useState(false);
  let [queryFilter, setQueryFilter] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const initialData = useSelector(
    (s) => s.invoiceOverviewReducer.invoiceDetails
  );
  const ref = useRef(null);
  // const [isOpen, setOpen] = useState(false);
  let { isOpen, phoneNumber, chatRefetch } = useSelector(
    (e) => e.timelineReducer
  );
  function condencedFn() {
    return setIsCondenced(!isCondenced);
  }

  const timeLineListData = useInfiniteQuery(
    // `${CUSTOMER_OVERVIEW}-TIMELINE-${getParams}`,
    [
      `TIMELINE_${CUSTOMER_OVERVIEW}_${customerId}`,
      {
        // pageSize: 0,
        // refetchCount: refetchTimeline,
        // sort: querySort,
        filters: queryFilter,
        internalAction,
      },
    ],
    async ({ queryKey, pageParam = 1 }) => {
      // let { filters, pageParam = 1 } = queryKey[1];
      let { pageIndex, sort, pageSize, filters, page } = queryKey[1];
      //

      return await getCustomerOverviewTimelineListV2({
        page: pageParam,
        organization: currentOrganization,
        id: customerId,
        sort,
        entity: "customer",
        ...filters,
        is_hide_internal: !internalAction,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        if (allPages.length < maxPages) {
          return allPages.length + 1;
        } else {
          return undefined;
        }
      },
    }
  );

  const onScroll = async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (
      !timeLineListData.isFetchingNextPage &&
      scrollHeight - scrollTop <= clientHeight * 1.5
    ) {
      if (timeLineListData.hasNextPage)
        await timeLineListData.fetchNextPage({ cancelRefetch: false });
    }
  };

  useEffect(() => {
    const dev = ref.current;
    if (ref.current) {
      dev.addEventListener("scroll", onScroll);
    }
    return () => {
      ref?.current?.removeEventListener("scroll", onScroll);
    };
  }, [ref, ref.current, onScroll]);

  useEffect(() => {
    if (timeLineListData && timeLineListData.data) {
      let allData = [];

      timeLineListData &&
        timeLineListData.data &&
        timeLineListData.data.pages.map((e) => {
          allData.push(...(e && e.data && e.data.docs));
        });

      setTimeLineData(allData);
    }
  }, [timeLineListData && timeLineListData.data]);

  const updateQueryFilter = (activeFilters) => {
    if (activeFilters.length > 0) {
      setQueryFilter({
        type: [...activeFilters],
      });
    } else {
      setQueryFilter({});
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <TitleHead title="Timeline" />
          <Icon
            icon="grid"
            isPressable
            style={{ marginBottom: "5px" }}
            onClick={() => condencedFn()}
          />
        </div>

        <div style={{ alignItems: "center", paddingRight: "15px" }}>
          <CheckBoxBaseweb
            checked={internalAction}
            checkmarkType={STYLE_TYPE.toggle_round}
            labelPlacement={LABEL_PLACEMENT.right}
            overrides={{
              Label: {
                style: ({ $theme }) => ({
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#333860",
                }),
              },
            }}
            onChange={(evt) => setInternalAction(evt.target.checked)}
          >
            Internal Actions
          </CheckBoxBaseweb>
        </div>
      </div>
      <ChipFilterNew
        filters={[
          { label: "SMS", value: "sms" },
          { label: "Email", value: "email" },
          { label: "Call", value: "call" },
          { label: "WhatsApp", value: "whatsapp" },
          { label: "Letter", value: "letter" },
          { label: "Next Action", value: "NEXT_ACTION" },
          { label: "Inv Adj", value: "INVA_ADJ" },
          // { label: "Invoice", value: "invoice" },
          // { label: "Payment", value: "payment" },
          // { label: "Dispute", value: "dispute" },
          // { label: "Hold Action", value: "hold_action" },
          // { label: "Skip Action", value: "action_skipped" },
          // { label: "Invoice Update", value: "INVOICE_UPDATE" },
          // { label: "Review Promise", value: "reviewpromise" },
        ]}
        setFilter={updateQueryFilter}
      />
      <div ref={ref} className="co-timeline-list_container">
        {timelineData && Array.isArray(timelineData) && (
          <>
            {timelineData.map((tm) => {
              return isCondenced ? (
                <TimelineMap {...tm} />
              ) : tm?._id === selectedId ? (
                <TimelineMap {...tm} selectedId={selectedId} />
              ) : (
                <TimelineCondensed
                  {...tm}
                  setIsCondenced={setIsCondenced}
                  setSelectedId={setSelectedId}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default TimelineDrawer;
