import React, { useContext, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";

import { SelectBox } from "../../../../components/SelectBox";

import { TextArea } from "../../../../components/TextArea";

import { TextBox } from "../../../../components/TextBox";

import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { Controller } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import setSelectValues from "../../../../utils/setSelectValues";
import { useSelector } from "react-redux";

const PayAction = (call) => {
  let {
    referenceData,
    currentOrganization,
    userInfo,
    space,
    currentDefaultFormatDetails,
  } = useContext(MaxyfiContext);
  let intl = useIntl();
  let { id } = useParams();

  let {
    control,
    errors,
    actioWatch,
    setValue,
    watch,
    settingData,
    total_outstanding_invoice_amount,
    setError,
  } = call;

  let {} = watch();

  let { choose_payment, payment_amount } = watch();

  const { customerDetails } = useSelector((s) => s.customerOverviewReducer);

  let rdData = referenceData["currency"];
  let customerCurrency = customerDetails.invoice_currencies;

  let filteringCurrency = rdData.filter(
    (i) => customerCurrency && customerCurrency.includes(i.id)
  );

  // useEffect(() => {
  //   if (payment_amount > total_outstanding_invoice_amount?.value) {
  //     return setError("payment_amount.value", {
  //       type: "custom",
  //       message: "Payment Amount is Greater than Outstanding Amount",
  //     });
  //   }
  // }, [payment_amount]);

  return (
    <>
      <div className="cp-action-pay_action">
        <div
          // className="cp-action-message-confirmation"
          style={{ width: "225px" }}
        >
          <Controller
            name="choose_payment"
            control={control}
            // rules={{ required: "Required" }}
            defaultValue={setSelectValues("INSTANT")}
            render={({ field }) => (
              <SelectBox
                {...field}
                clearable={false}
                name={field.name}
                fullWidth
                size={"mini"}
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "choose_payment",
                })}
                placeholder={intl.formatMessage({
                  id: "choose_payment",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["payment_type"]}
              />
            )}
          />
        </div>

        {choose_payment &&
          choose_payment[0] &&
          choose_payment[0].id == "INSTANT" && (
            <div style={{ width: "225px" }}>
              <Controller
                name="payment_methods"
                control={control}
                rules={{ required: "Required" }}
                render={({ field }) => (
                  <SelectBox
                    {...field}
                    clearable={false}
                    name={field.name}
                    fullWidth
                    size={"mini"}
                    error={errors[field.name] && errors[field.name].message}
                    label={intl.formatMessage({
                      id: "payment_method",
                    })}
                    placeholder={intl.formatMessage({
                      id: "payment_method",
                    })}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                    }}
                    requiredAstric={true}
                    options={
                      referenceData["online_payment_method"] &&
                      Array.isArray(referenceData["online_payment_method"])
                        ? referenceData["online_payment_method"].map((e) => {
                            return {
                              ...e,
                              id: `${e?.id}${
                                _.get(e, "online_payment_type", "") ? "-" : ""
                              }${_.get(e, "online_payment_type", "")}`,
                              _id: e?.id,
                            };
                          })
                        : []
                    }
                  />
                )}
              />
            </div>
          )}

        {choose_payment &&
        choose_payment[0] &&
        choose_payment[0].id == "INSTANT" ? (
          <div>
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ width: "225px" }}>
                <Controller
                  name="payment_amount.currency"
                  control={control}
                  rules={{ required: "Required" }}
                  defaultValue={setSelectValues(
                    customerDetails && customerDetails.default_currency
                  )}
                  render={({ field }) => (
                    <SelectBox
                      {...field}
                      clearable={false}
                      name={field.name}
                      fullWidth
                      size={"mini"}
                      error={
                        errors["payment_amount"] &&
                        errors["payment_amount"].currency &&
                        errors["payment_amount"].currency.message
                      }
                      label={intl.formatMessage({
                        id: "cur",
                      })}
                      placeholder={intl.formatMessage({
                        id: "cur",
                      })}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      requiredAstric={true}
                      options={filteringCurrency}
                    />
                  )}
                />
              </div>
              <div style={{ width: "225px" }}>
                <Controller
                  name="payment_amount.value"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <TextBox
                      {...field}
                      clearable={false}
                      name={field.name}
                      fullWidth
                      size={"mini"}
                      error={
                        errors["payment_amount"] &&
                        errors["payment_amount"].value &&
                        errors["payment_amount"].value.message
                      }
                      label={intl.formatMessage({
                        id: "payment_amount",
                      })}
                      placeholder={intl.formatMessage({
                        id: "payment_amount",
                      })}
                      value={field.value}
                      requiredAstric={true}
                      type="number"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {choose_payment &&
      choose_payment[0] &&
      choose_payment[0].id == "INSTANT" ? (
        <>
          <div>
            <Controller
              defaultValues={false}
              name="is_save_card"
              control={control}
              render={({ field }) => (
                <CheckBoxBaseweb
                  {...field}
                  // disabled={true}
                  checked={field.value}
                  labelPlacement={LABEL_PLACEMENT.right}
                >
                  <FormattedMessage id="save_card_information_for_future_purpose">
                    save_card_information_for_future_purpose
                  </FormattedMessage>
                </CheckBoxBaseweb>
              )}
            />
            <div style={{ marginTop: "10px" }}>
              <Controller
                name="comment"
                control={control}
                // rules={{ required: "Required" }}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    clearable={false}
                    error={errors[field.name] && errors[field.name].message}
                    name={field.name}
                    label={intl.formatMessage({
                      id: "user_comments",
                    })}
                    placeholder={intl.formatMessage({
                      id: "user_comments",
                    })}
                    value={field.value}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default PayAction;
