import React, { useState, useContext } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import DownloadOverview from "../../containers/CustomerSummary/DownloadOverview";
import TableViewPicker from "../../components/TableViewPicker";
import InvoiceSummaryTable from "../../containers/InvoiceSummary/InvoiceSummaryTable";
import { useSelector, useDispatch } from "react-redux";
import {
  setInvoiceSummaryPageIndex,
  setInvoiceSummaryTableView,
  setNeedsActionFilterInv,
} from "../../redux/invoiceSummary/action";
import DownloadSummary from "../../components/DownloadSummary/DownloadSummary";
import DownloadInvoiceSummary from "../../components/DownloadInvoice/DownloadInvoice";
import { invoiceSummaryData } from "../../services/invoiceSummary";
import { useLocation, useSearchParams } from "react-router-dom";
import InvoiceOverview from "../InvoiceOverview/InvoiceOverview";
import { INVOICE_OVERVIEW } from "../../constants";
import { useQuery } from "react-query";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import AddRecurringInv from "../../components/AddRecurringInv/AddRecurringInv";
import { createDocument } from "../../services/documentSystem";
import AddNewInvoicingForm from "../../containers/Invoicing/AddNewInvoicingForm";
import DMSystem from "../../containers/CustomerOverView/DMSystem";
import {
  addingInvoiceDocumentData,
  documentInvoiceAddingDrawerState,
} from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
// import { TextButton, SIZE } from "../TextButton";
import { TextButton, SIZE } from "../../components/TextButton";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_INVOICE,
  ADD_RECURRING_INVOICE,
  DOWNLOAD_INVOICE_LIST,
} from "../../providers/RBACProvider/permissionList";
import RoleAccessSelect from "../../components/RoleAccessSelect/RoleAccessSelect";
import moment from "moment";

function InvoiceSummary() {
  const [addSearchParams, setAddSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { tableDraftView, viewName, tableCurrentState } = useSelector(
    (state) => state.invoiceSummaryReducer
  );
  const { addingInvoiceDrawerState, addingInvoiceType } = useSelector(
    (s) => s.invoiceAddingDocumentReducer
  );
  let {
    currentOrganization,
    userPersonalization,
    currentDefaultFormatDetails,
    appAccessRest,
    space,
  } = useContext(MaxyfiContext);
  let globalFilter = tableCurrentState.globalFilters;

  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const getInvoiceParams = getSearchParams.get("invoice");
  const [selectedInvCustomerId, setSelectedInvCustomerId] = useState("");

  const getType = getSearchParams.get("type");

  let localPageSize = localStorage.getItem("invoice_summary_page_size");

  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);

  const { gridStructure, gridAndViewLoaded = false } = useSelector(
    (s) => s.invoiceSummaryReducer
  );

  let reduxData = useSelector((s) => s.invoiceSummaryReducer.tableState);
  let hiddenColData = useSelector(
    (state) => state.invoiceSummaryReducer.tableDraftView.table
  );
  let currentTableState = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState
  );

  let removingExpanderSelection = reduxData.columns.filter(
    (i) => i.id != "expander" && i.id != "selection"
  );

  let allColumns = removingExpanderSelection.map((i) => i);

  let hiddenColumns = hiddenColData
    .filter((i) => !i.is_hidden)
    .sort((a, b) => a.order - b.order)
    .map((i) => i.col_id);

  let filteredColumns = hiddenColumns
    .map((e) => {
      let column = allColumns.find((col) => col.path === e);

      if (column && column.path) {
        return column;
      }
    })
    .filter((e) => e);

  const getParams = getSearchParams.get("inv");

  let customerFilter = tableCurrentState.filters;

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };

  const NeedsActionFilterData = useSelector(
    (s) => s.invoiceSummaryReducer.tableCurrentState.needs_action
  );

  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];

  // API is passing in useQuery
  let { data, isFetched, isError, isLoading, refetch, status } = useQuery(
    [
      `${INVOICE_OVERVIEW}-${currentOrganization}`,
      {
        pageIndex: tableCurrentState.pageIndex + 1,
        pageSize: localPageSize ? localPageSize : tableCurrentState.pageSize,
        sortBy:
          tableCurrentState.sort && tableCurrentState.sort[0]
            ? tableCurrentState.sort[0].desc
              ? "-" + tableCurrentState.sort[0].id
              : tableCurrentState.sort[0].id
            : "",
        customerFilter,
        globalFilter,
        filters: {
          // ...globalFilter,
          ...appAccessRest,
          ...(NeedsActionFilter
            ? { needs_actions: [...NeedsActionFilter] }
            : []),
          ...(FilteredCustomerStage
            ? { stage: [...FilteredCustomerStage] }
            : []),
          ...(globalFilter && globalFilter.length > 0
            ? { search_value: globalFilter }
            : []),
        },
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, customerFilter } =
        queryKey[1];
      let newFilter = {
        combinator: "and",
        rules: [],
        ...customerFilter,
      };

      // let newFilter =
      //   dynamicQueryFilter({
      //   gridStructure: gridStructure,
      //   customerQueryFilter: globalFilter,
      //   timeZone: currentDefaultFormatDetails.time_zone,
      //   customerFilter: customerFilter,
      // });

      const GridStructure = gridStructure.table?.reduce((prev, curr) => {
        if (curr.nested) {
          return [...prev, ...(curr?.nested ? curr?.nested : [])];
        }
        return [...prev, curr];
      }, []);

      const findDateObj = GridStructure.filter((dq) => dq.type === "DATE").map(
        (dq) => dq.accessor
      );

      const dynamicDate = findDateObj.map((dy) => dy + "_dynamic");

      // if (globalFilter.length > 0) {
      //   newFilter.rules = [
      //     ...newFilter.rules,
      //     {
      //       operator: "contains",
      //       value: globalFilter,
      //       id: "8fd3ed39-52ec-4e5e-986e-6829a8d0d047",
      //       field: "invoice_number",
      //       valueSource: "value",
      //     },
      //   ];
      // }

      if (newFilter.rules.length > 0) {
        newFilter.rules = newFilter.rules.map((fd) => {
          if (findDateObj.includes(fd.field)) {
            let [dateFrom, dateTo] = fd.value.split(",");
            if (dateFrom && dateTo) {
              let newDateFrom = "";
              let newDateTo = "";

              if (dateFrom && moment(dateFrom).isValid()) {
                newDateFrom = moment(dateFrom)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              if (dateTo && moment(dateTo).isValid()) {
                newDateTo = moment(dateTo)
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              return { ...fd, value: `${newDateFrom},${newDateTo}` };
            } else {
              if (dateFrom && moment(dateFrom).isValid()) {
                let newDateFrom =
                  fd.operator === ">"
                    ? moment
                        .tz(dateFrom, currentDefaultFormatDetails.time_zone)
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(dateFrom, currentDefaultFormatDetails.time_zone)
                        .startOf("day")
                        .valueOf();
                // moment(dateFrom)
                //   .tz(currentDefaultFormatDetails.time_zone)
                //   .startOf("day")
                //   .valueOf();
                return { ...fd, value: newDateFrom };
              }
            }
          }

          if (dynamicDate.includes(fd.field)) {
            let numberRegex = /\d/gi;
            let symbelRegex = /[-+]/g;
            let [dateFrom, dateTo] = fd.value.split(",");

            if (dateFrom && dateTo) {
              let joinAlpa = dateFrom
                ? dateFrom?.match(/[A-Z]/gi)?.join("")
                : [];
              let daysFrom = dateFrom?.match(numberRegex);
              let joinFromDate = daysFrom ? daysFrom?.join("") : [];
              let actionFrom = dateFrom?.match(symbelRegex);
              let daysTo = dateTo?.match(numberRegex);
              let joinToDate = daysTo?.join("");
              let actionTo = dateTo?.match(symbelRegex);
              let replaceField = fd?.field?.replace("_dynamic", "");
              let convertDateFrom;

              if (joinFromDate) {
                convertDateFrom =
                  actionFrom && actionFrom[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .subtract(joinFromDate, "day")
                        .startOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .add(joinFromDate, "day")
                        .startOf("day")
                        .valueOf();
              } else if (!joinFromDate && joinAlpa === "Today") {
                convertDateFrom = moment
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              let convertDateTo;

              if (joinToDate) {
                convertDateTo =
                  actionTo && actionTo[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .subtract(joinToDate, "day")
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails.time_zone)
                        .add(joinToDate, "day")
                        .endOf("day")
                        .valueOf();
              } else if (!joinToDate && joinAlpa === "Today") {
                convertDateTo = moment
                  .tz(currentDefaultFormatDetails.time_zone)
                  .startOf("day")
                  .valueOf();
              }

              return {
                ...fd,
                field: replaceField,
                value: `${convertDateFrom},${convertDateTo}`,
              };
            } else {
              let joinAlpa = dateFrom
                ? dateFrom?.match(/[A-Z]/gi)?.join("")
                : [];
              let daysFrom = dateFrom?.match(numberRegex);
              let joinFromDate = daysFrom ? daysFrom?.join("") : [];
              let actionFrom = dateFrom?.match(symbelRegex);
              let replaceField = fd?.field.replace("_dynamic", "");

              let convertDate;
              if (joinFromDate) {
                convertDate =
                  actionFrom && actionFrom[0] === "-" && fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .subtract(joinFromDate, "day")
                        .endOf("day")
                        .valueOf()
                    : actionFrom && actionFrom[0] === "-"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .subtract(joinFromDate, "day")
                        .startOf("day")
                        .valueOf()
                    : fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .add(joinFromDate, "day")
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .add(joinFromDate, "day")
                        .startOf("day")
                        .valueOf();
              } else if (!joinFromDate && joinAlpa === "Today") {
                convertDate =
                  fd.operator === ">"
                    ? moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .endOf("day")
                        .valueOf()
                    : moment
                        .tz(currentDefaultFormatDetails?.time_zone)
                        .startOf("day")
                        .valueOf();
              }

              return { ...fd, field: replaceField, value: convertDate };
            }
          }

          return fd;
        });
      }

      return await invoiceSummaryData({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        customerFilter: newFilter,
      });
    },
    {
      enabled: gridAndViewLoaded,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
  let invoiceTitle;
  if (getType === "INV-ADD-INV") {
    invoiceTitle = "add_invoice";
  }
  if (getType === "INV-ADD-REC") {
    invoiceTitle = "add_recurring_invoice";
  }
  if (getType === "INV-EDIT-INV") {
    invoiceTitle = "edit_invoice";
  }

  let invoicePermissionData =
    space && space?.role_permissions?.includes(ADD_INVOICE);

  let recurringPermissionData =
    space && space?.role_permissions?.includes(ADD_RECURRING_INVOICE);

  return (
    <Layout>
      {getInvoiceParams ? (
        <>
          <div className="content-container">
            <div className="content-header">
              <div className="content-header__title">
                <PageTitle id={invoiceTitle} />
              </div>
              <div className="content-header__actions">
                {/* <TextButton onClick={() => {}}>Add New Invoice</TextButton> */}
              </div>
            </div>
            <div className="content-body">
              <AddNewInvoicingForm
                setSelectedInvCustomerId={setSelectedInvCustomerId}
              />

              <DMSystem
                resourceName="customerId"
                // resourceId={getParams}
                resourceId={selectedInvCustomerId}
                documentData={""}
                createDocument={createDocument}
                deleteDocumentMethod={""}
                editDocumentMethod={""}
                entity={"customer"}
                drawerOpen={addingInvoiceDrawerState}
                documentDrawertype={addingInvoiceType}
                addingSelectedDocument={addingInvoiceDocumentData}
                openingDrawerState={documentInvoiceAddingDrawerState}
              />
            </div>
          </div>
        </>
      ) : !getParams ? (
        <>
          <div className="content-container --layout_relative">
            <RoleAccessSelect pageIndex={setInvoiceSummaryPageIndex} />
            <div className="content-header">
              <div className="content-header__title">
                <PageTitle id="invoice_summary" />
              </div>
              <div className="content-header__actions">
                {/* <DownloadOverview /> */}
                {invoicePermissionData || recurringPermissionData ? (
                  <>
                    {currentDefaultFormatDetails.accounting_book === "CSV" && (
                      // <AddRecurringInv />
                      <StatefulPopover
                        focusLock
                        placement={PLACEMENT.bottomRight}
                        overrides={{
                          Body: {
                            style: ({ $theme }) => ({
                              borderRadius: "5px",
                              overflow: "hidden",
                              width: "131px",
                              background: "#ffffff",
                              zIndex: "1",
                            }),
                          },
                          Inner: {
                            style: ({ $theme }) => ({
                              backgroundColor: "#ffffff",
                              padding: "10px",
                            }),
                          },
                        }}
                        content={({ close }) => (
                          // <StatefulMenu
                          //   items={ITEMS}
                          //   overrides={{
                          //     List: { style: { height: "90px", width: "230px" } },
                          //   }}
                          // />
                          <div>
                            <RBACWrapper role={ADD_INVOICE} type="PERMISSION">
                              <div
                                style={{
                                  padding: "5px 0px",
                                  borderBottom: "0.5px solid #ceded9",
                                  width: "100%",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAddSearchParams({
                                    // customer: `${getParams}`,
                                    invoice: `invoice-form`,
                                    type: "INV-ADD-INV",
                                  });
                                }}
                              >
                                Invoice
                              </div>
                            </RBACWrapper>
                            <RBACWrapper
                              role={ADD_RECURRING_INVOICE}
                              type="PERMISSION"
                            >
                              <div
                                style={{
                                  padding: "5px 0px",
                                  width: "100%",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setAddSearchParams({
                                    // customer: `${getParams}`,
                                    invoice: `recurring-form`,
                                    type: "INV-ADD-REC",
                                  });
                                }}
                              >
                                Recurring
                              </div>
                            </RBACWrapper>
                          </div>
                        )}
                      >
                        <div style={{ width: "130px" }}>
                          <TextButton
                            size={SIZE.mini}
                            endEnhancer={
                              <i className="mx-icon-arrow_drop_down_FILL0_wght400_GRAD0_opsz48-2 mx-icon-arrow_drop_down" />
                              // <AssignDataDownArrow />
                            }
                            fullWidth
                            style={{ fontWeight: "500", fontSize: "14px" }}
                            // onClick={() => {
                            //   setAddSearchParams({
                            //     customer: `${getParams}`,
                            //     invoice: `invoice-form`,
                            //   });
                            // }}
                          >
                            Add Invoice
                          </TextButton>
                        </div>
                      </StatefulPopover>
                    )}
                  </>
                ) : (
                  <></>
                )}

                <RBACWrapper type="PERMISSION" role={DOWNLOAD_INVOICE_LIST}>
                  <DownloadInvoiceSummary
                    queryFilter={{ queryFilter, ...appAccessRest }}
                    querySortBy={
                      currentTableState.sort && currentTableState.sort[0]
                        ? currentTableState.sort[0].desc
                          ? "-" + currentTableState.sort[0].id
                          : currentTableState.sort[0].id
                        : null
                    }
                    service={invoiceSummaryData}
                    filteredColumns={filteredColumns}
                    fileName={"Invoice Summary"}
                    gridStructure={gridStructure}
                  />
                </RBACWrapper>
              </div>
            </div>
            <div className="content-body">
              <InvoiceSummaryTable
                queryFilter={queryFilter}
                setQueryFilter={setQueryFilter}
                service={invoiceSummaryData}
                isFetched={isFetched}
                isError={isError}
                data={data}
                setQueryPageIndex={setQueryPageIndex}
                queryPageSize={queryPageSize}
                setQueryPageSize={setQueryPageSize}
                needsActionFilterDataLength={NeedsActionFilterData}
                needsActionFilter={setNeedsActionFilterInv}
                isLoading={isLoading}
                status={status}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <InvoiceOverview
            getParams={getParams}
            tableData={data && data.data && data.data.docs}
            // setQueryFilter={setQueryFilter}
          />
        </>
      )}
    </Layout>
  );
}

export default InvoiceSummary;
