import moment from "moment";
import React from "react";
import { Icon, Typography } from "../../../../components_v2";
import { useContext } from "react";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import _ from "lodash";
import { useNumericFormat } from "react-number-format";
import { CUSTOMER_OVERVIEW_ } from "../../../../constants";
import { useParams } from "react-router-dom";
import queryClient from "../../../../providers/queryClient";
import { useState } from "react";
import { useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { TextBox } from "../../../../components/TextBox";
import { useIntl } from "react-intl";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { KIND, TextButton } from "../../../../components/TextButton";
import { useMemo } from "react";

const InstallmentList = ({
  frequency,
  installments,
  planned_payment_amount,
  setInstallments,
  isEdit,
  setIsEdit,
}) => {
  let intl = useIntl();

  const {
    currentDefaultFormatDetails,
    currencyGroupStyle,
    currencySeparator,
    currencyDecimalSeparator,
  } = useContext(MaxyfiContext);
  const numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  const { control, errors, watch, settingData, setValue, handleSubmit } =
    useForm({
      defaultValues: {},
    });

  const { schedule_payment, schedule_paymenting } = watch();

  const totalAmount = useMemo(() => {
    if (schedule_payment?.length > 0) {
      let total = schedule_payment?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.planned_amount);
      }, 0);

      return total.toFixed(2);
    }

    if (installments?.length > 0) {
      let total = installments?.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue?.planned_amount);
      }, 0);

      return total.toFixed(2);
    }
  }, [schedule_payment?.map((e) => e?.planned_amount), planned_payment_amount]);

  let dataValidates =
    schedule_payment &&
    schedule_payment.map((item, index) => {
      let currTime = moment(item?.planned_date).utc().valueOf() / 1000;
      let nextTime =
        moment(schedule_payment[index + 1]?.planned_date)
          .utc()
          .valueOf() / 1000;

      if (
        Math.floor(nextTime) < Math.floor(currTime) &&
        schedule_payment[index + 1]?.planned_date
      ) {
        return "less(or)greater";
      } else if (
        Math.floor(nextTime) == Math.floor(currTime) &&
        schedule_payment[index + 1]?.planned_date
      ) {
        return "equal";
      } else {
        return "true";
      }
    });
  let Amount =
    schedule_payment &&
    schedule_payment.some((item, i) => {
      if (item.planned_amount == "") {
        return true;
      }
    });

  useEffect(() => {
    if (installments) {
      installments.map((element, i) => {
        setValue(
          `schedule_payment.[${i}].planned_date`,
          new Date(element && element.promise_date)
        );
        setValue(
          `schedule_payment.[${i}].planned_amount`,
          Number(element?.promise_amount?.value).toFixed(2)
        );
      });
    }
  }, [installments]);

  const onSubmits = (data) => {
    let upgrade = [];
    data.schedule_payment.forEach((item) => {
      let { planned_amount, planned_date } = item;
      let promise_date = new Date(planned_date).valueOf();

      let promise_amount = {
        currency: installments?.[0]?.promise_amount?.currency,
        value: Number(planned_amount),
      };
      upgrade.push({ promise_date, promise_amount });
    });

    if (
      Number(totalAmount).toFixed(2) ==
      Number(planned_payment_amount).toFixed(2)
    ) {
      setInstallments(upgrade);
      setIsEdit(false);
    }
  };

  //   const customerData = queryClient.getQueryData(
  //     `${CUSTOMER_OVERVIEW_}${customerId}`
  //   );

  return (
    <div
      style={{
        background: "#F0F5FF",
        padding: "6px 10px",
        marginBottom: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="paymentplan_view-btn">
          <Typography>
            {frequency} Payment plan with installment amount of{" "}
            {_.get(installments, "[0].promise_amount.currency", "-")}{" "}
            {numberFormat.format(
              `${
                installments?.[0]?.promise_amount?.value
                  ? installments?.[0]?.promise_amount?.value
                  : 0
              }`
            )}
            &nbsp;starting&nbsp;
            {installments?.length !== 0
              ? moment(installments?.[0]?.promise_date).format(
                  currentDefaultFormatDetails.date_format
                )
              : ""}{" "}
            from till{" "}
            {moment(
              installments?.[installments?.length - 1]?.promise_date
            ).format(currentDefaultFormatDetails.date_format)}
          </Typography>
        </div>
        <div className="monthly_plan">
          <div className="monthlyupdate">
            <Icon icon="edit" isPressable onClick={() => setIsEdit(!isEdit)} />
          </div>
        </div>
      </div>

      {isEdit ? (
        <div
          style={{
            maxHeight: "200px",
            overflow: "hidden",
            overflow: "scroll",
          }}
        >
          <form>
            <div style={{ height: "90%", overflowY: "scroll" }}>
              {installments.map((ele, i) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "11px",
                        width: "100%",
                        height: "8%",
                      }}
                    >
                      <div
                        className="--drawer_payment_amount_changes"
                        style={{ marginTop: "16px", width: "170px" }}
                      >
                        <Controller
                          name={`schedule_payment.[${i}].planned_amount`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <TextBox
                              {...field}
                              hidden={true}
                              size={"mini"}
                              type="number"
                              name={field.name}
                              requiredAstric={true}
                              label={intl.formatMessage(
                                {
                                  id: i + 1,
                                  defaultMessage: "Installment Amount {name}",
                                },
                                {
                                  name: i + 1,
                                }
                              )}
                              placeholder={intl.formatMessage({
                                id: "planned_installment_amount",
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div style={{ marginTop: "16px" }}>
                        <Controller
                          name={`schedule_payment.[${i}].planned_date`}
                          control={control}
                          rules={{ required: "Required" }}
                          render={({ field }) => (
                            <>
                              <DateRangeSelect
                                {...field}
                                name={field.name}
                                requiredAstric={true}
                                size={"mini"}
                                label={intl.formatMessage({
                                  id: "installment_date",
                                })}
                                placeholder={intl.formatMessage({
                                  id: "installment_date",
                                })}
                                value={field.value}
                                onChange={(e) => field.onChange(e.date)}
                                minDate={new Date()}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {!(
                  Number(totalAmount).toFixed(2) ==
                  Number(planned_payment_amount).toFixed(2)
                ) ? (
                  <div>
                    <Typography subType={"regular"} className="text-error">
                      Sum of Installment Amount does not match with Payment
                      Amount (
                      {numberFormat.format(
                        Number(totalAmount) < Number(planned_payment_amount)
                          ? `${(
                              Number(totalAmount) -
                              Number(planned_payment_amount)
                            ).toFixed(2)}`
                          : `+ ${(
                              Number(totalAmount) -
                              Number(planned_payment_amount)
                            ).toFixed(2)}`
                      )}
                      )
                    </Typography>
                  </div>
                ) : dataValidates && dataValidates?.includes("equal") ? (
                  <div>
                    <Typography $style={{ color: "red" }}>
                      2 Installments cannot be the same installment date
                    </Typography>
                  </div>
                ) : dataValidates &&
                  dataValidates?.includes("less(or)greater") ? (
                  <div>
                    <Typography $style={{ color: "red" }}>
                      Installment date should be greater than next installment
                      date
                    </Typography>
                  </div>
                ) : Amount ? (
                  <div>
                    <Typography $style={{ color: "red" }}>
                      Invalid Installment Amount
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ display: "flex", gap: "5px" }}>
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={"mini"}
                      fullWidth
                      kind={KIND.tertiary}
                      type="button"
                      onClick={() => {
                        //   setIsOpen(false);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </TextButton>
                  </div>{" "}
                  <div style={{ width: "125px", height: "36px" }}>
                    <TextButton
                      size={"mini"}
                      fullWidth
                      type="button"
                      onClick={handleSubmit(onSubmits)}
                      disabled={
                        !(
                          Number(totalAmount).toFixed(2) ==
                          Number(planned_payment_amount).toFixed(2)
                        ) ||
                        (schedule_payment &&
                          schedule_payment?.some(
                            (ele) => !ele?.planned_date
                          )) ||
                        !dataValidates?.every((ele) => ele == "true") ||
                        Amount
                      }
                    >
                      Save
                    </TextButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InstallmentList;
