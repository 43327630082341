import React, { useContext, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { SelectBox, SIZE } from "../../../../components/SelectBox";
import { TextBox } from "../../../../components/TextBox";
import DateRangeSelect from "../../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { MaxyfiContext } from "../../../../providers/MaxyfiProvider";
import { TextButton } from "../../../../components/TextButton";
import queryClient from "../../../../providers/queryClient";
import {
  CUSTOMER_OVERVIEW_,
  ACT_AUTO_PAYMENT_PLAN,
  CO_INVOICES_,
} from "../../../../constants";
import getSelectValues from "../../../../utils/getSelectValues";
import ActionReminders from "../../../../components_v2/ActionReminders";
import getActionReminder from "../../../../utils_v2/getActionReminders";
import { Typography, Icon } from "../../../../components_v2";
import useFormat from "../../../../hooks/useFormat";
import PromiseStatusTypes from "../PromiseStatusTypes";
import {
  getConvienceFeePPL,
  getConvienceFeePPLPost,
  getHolidayBlackoutHolidayDate,
} from "../../../../services";
import { useSelector } from "react-redux";
import { currencyList } from "../../../../utils_v2/CurrencyToSymbol";
import setSelectValues from "../../../../utils/setSelectValues";
import { toast } from "react-toastify";
import CheckBoxBaseweb from "../../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import AplInstallmentsList from "./AplInstallments";
import InstallmentList from "./InstallmentList";
import APLInstallmentList from "./APLInstallmentList";
import { number } from "yup";

const freqMoment = {
  WEEKLY: {
    type: "day",
    count: 7,
  },
  FORTNIGHTLY: {
    type: "day",
    count: 14,
  },
  MONTHLY: {
    type: "month",
    count: 1,
  },
  QUARTERLY: {
    type: "month",
    count: 3,
  },
};

const AutoPaymentPlanForm = (props) => {
  const format = useFormat();
  const {
    isInvoice,
    formValues = {},
    service,
    onSuccess = () => {},
    onCancel = () => {},
    // isIncrementor = true,
    isContent = true,
    selectedInvoices = {},
    totalAmount = 0,
  } = props;
  const { customerId } = useParams();
  const intl = useIntl();

  let { referenceData, currentDefaultFormatDetails, currentOrganization } =
    useContext(MaxyfiContext);

  let { validationField } = useSelector((e) => e.customerOverviewDrawer);
  const [expand, setExpand] = useState(false);
  const [isCompute, setIsCompute] = useState(false);
  const [computeBy, setComputeBy] = useState("count");
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {
      frequency: [{ label: "Weekly", id: "WEEKLY" }],
      start_date: new Date(),
      is_waive_off: false,
    },
  });
  const paymentGatewayId = watch("payment_gateway_id");
  const [isInstallmentsss, setInstallments] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isInstalationCountChanged, setInstalationCountChanged] =
    useState(false);
  const [isDisableCompute, setIsDisableCompute] = useState(true);
  const truncateNumber = (number, decimal) => {
    if (String(number).split(".")?.[0] && String(number).split(".")?.[1]) {
      return Number(
        String(number).split(".")?.[0] +
          "." +
          String(number).split(".")?.[1]?.slice(0, decimal)
      );
    } else {
      return number;
    }
  };

  console.log("paymentGatewayId", paymentGatewayId);

  const [
    startDate,
    isHold,
    amount,
    currency,
    frequency,
    count,
    paymentPlan,
    instalationAmount,
    residualAmountType,
    is_waive_off,
  ] = watch([
    "start_date",
    "is_hold_workflow",
    "amount",
    "currency",
    "frequency",
    "instalation_count",
    "payment_gateway_id",
    "instalation_amount",
    "residual_amount_type",
    "is_waive_off",
  ]);

  // const co = useMutation(
  //   (formData) =>
  //     getConvienceFeePPLPost({
  //       ...formData,
  //       id: customerId,
  //       entity: "customer",
  //       organization: currentOrganization,
  //     }),
  //   {
  //     onError: (error, variables, context) => {
  //       if (error.response) {
  //         let { data } = error.response;
  //         let errorData = data.message;
  //         toast.error(errorData);
  //       }

  //       //Need to handle if no error response is send from backend
  //     },
  //     onSuccess: (data) => {
  //       if (data && data.data && data.data.redirect_url) {
  //         window.open(data.data.redirect_url);
  //       }

  //       onSuccess();
  //     },
  //   }
  // );

  const {
    data: convienceFeeData,
    isFetched: isConventionFetched,
    isError: isErrorConvience,
    isLoading: isLoadingConvention,
  } = useQuery(
    [
      `GET_CONVENIENCE_FEE_PAYMENT_PLAN_${customerId}`,
      {
        amount,
        count,
        paymentGatewayId: paymentGatewayId?.[0]._id,
        isInstalationCountChanged,
        instalationAmount,
        residualAmountType, // Get the value of instalation_amount
        online_payment_type: paymentGatewayId?.[0].online_payment_type,
        is_waive_off: is_waive_off,
        isInstallments: isInstallmentsss,
      },
    ],
    async ({ queryKey }) => {
      const [
        ,
        {
          amount,
          count,
          paymentGatewayId,
          isInstalationCountChanged,
          instalationAmount,
          online_payment_type,
          is_waive_off,
          isInstallments,
        },
      ] = queryKey;
      const params = !isInstalationCountChanged
        ? {
            amount,
            count,
            paymentGatewayId,
            online_payment_type,
            is_waive_off,
            payment_plan_list: isInstallments.map((e) => {
              return {
                promise_amount: {
                  value: Number(e?.amount),
                  currency: _.get(
                    customerData,
                    "data.doc.default_currency",
                    ""
                  ),
                },
                promise_date: e?.date,
              };
            }),
          }
        : {
            amount,
            split_amount: instalationAmount,
            residual_type: getSelectValues(residualAmountType),
            paymentGatewayId,
            online_payment_type,
            is_waive_off,
            payment_plan_list: isInstallments.map((e) => {
              return {
                promise_amount: {
                  value: Number(e?.amount),
                  currency: _.get(
                    customerData,
                    "data.doc.default_currency",
                    ""
                  ),
                },
                promise_date: e?.date,
              };
            }),
          };

      return await getConvienceFeePPLPost({
        organization: currentOrganization,
        customerId: customerId,
        ...params, // Spread params to include either `count` or `split_amount`
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(amount) &&
        Boolean(count) &&
        Boolean(getSelectValues(paymentGatewayId)), // Ensure all conditions are met
      onSuccess: (data) => {
        console.log("Query success:", data);
      },
      onError: (error) => {
        console.error("Query error:", error);
      },
    }
  );

  console.log("convienceFeeData", convienceFeeData);

  // const installments = useMemo(() => {
  //   let installments = [];
  //   let countInc = 0;

  //   if (amount && currency && frequency && count && startDate) {
  //     const freq =
  //       freqMoment[frequency && frequency[0] ? frequency[0].id : null];

  //     installments = Array.from({ length: count }).map((e, i) => {
  //       const installmentDate = moment(startDate)
  //         .tz(currentDefaultFormatDetails?.time_zone)
  //         .startOf("day")
  //         .add(countInc, freq.type)
  //         .utc()
  //         .valueOf();

  //       countInc += freq.count;

  //       return {
  //         date: installmentDate,
  //         amount: Number(amount) / Number(count),
  //       };
  //     });
  //   }

  //   let countV2 = _.get(installments, "[0].amount", null)
  //     ? truncateNumber(_.get(installments, "[0].amount", null))
  //     : undefined;
  //   console.log(countV2, "countV2");

  //   if (instalationAmount) {
  //     setValue("instalation_amount", truncateNumber(instalationAmount, 2));
  //   }

  //   return installments;
  // }, [amount, currency, frequency, count, startDate]);
  const [installmentsTrigger, setInstallmentsTrigger] = useState(0); // State for triggering memo

  // const installments = useMemo(() => {
  //   let installments = [];
  //   let countInc = 0;
  //   if (
  //     amount &&
  //     currency &&
  //     frequency &&
  //     count &&
  //     startDate &&
  //     instalationAmount
  //   ) {
  //     const freq =
  //       freqMoment[frequency && frequency[0] ? frequency[0].id : null];
  //     const installmentAmount = Number(instalationAmount);
  //     const totalAmount = Number(amount);

  //     const totalInstallmentsAmount = installmentAmount * (count - 1);
  //     const residualAmount = totalAmount - totalInstallmentsAmount;

  //     const amounts = Array.from({ length: count }).map((_, i) => {
  //       if (getSelectValues(residualAmountType) === "FIRST_INSTALMENT") {
  //         return i === 0 ? residualAmount : installmentAmount;
  //       } else if (getSelectValues(residualAmountType) === "LAST_INSTALMENT") {
  //         return i === count - 1 ? residualAmount : installmentAmount;
  //       }
  //       return installmentAmount; // Default case
  //     });

  //     installments = amounts.map((installmentAmt, i) => {
  //       const installmentDate = moment(startDate)
  //         .tz(currentDefaultFormatDetails?.time_zone)
  //         .startOf("day")
  //         .add(countInc, freq.type)
  //         .utc()
  //         .valueOf();

  //       countInc += freq.count;

  //       return {
  //         date: installmentDate,
  //         amount: installmentAmt, // Truncate to 2 decimal places
  //       };
  //     });

  //     if (
  //       getSelectValues(residualAmountType) === "KEEP_LAST_INSTALMENT" &&
  //       residualAmount > 0
  //     ) {
  //       const lastInstallmentAmount =
  //         Number(amount) - Number(instalationAmount) * Number(count) || 0;

  //       const lastInstallmentDate = moment(startDate)
  //         .tz(currentDefaultFormatDetails?.time_zone)
  //         .startOf("day")
  //         .add(countInc, freq.type)
  //         .utc()
  //         .valueOf();

  //       installments.push({
  //         date: lastInstallmentDate,
  //         amount: truncateNumber(lastInstallmentAmount, 2),
  //       });
  //     }
  //   } else if (amount && currency && frequency && count && startDate) {
  //     const freq =
  //       freqMoment[frequency && frequency[0] ? frequency[0].id : null];

  //     installments = Array.from({ length: count }).map((e, i) => {
  //       const installmentDate = moment(startDate)
  //         .tz(currentDefaultFormatDetails?.time_zone)
  //         .startOf("day")
  //         .add(countInc, freq.type)
  //         .utc()
  //         .valueOf();

  //       countInc += freq.count;

  //       return {
  //         date: installmentDate,
  //         amount: Number(amount) / Number(count),
  //       };
  //     });
  //   }

  //   return installments;
  // }, [
  //   amount,
  //   currency,
  //   frequency,
  //   count,
  //   startDate,
  //   residualAmountType,
  //   isInstalationCountChanged,
  //   installmentsTrigger,
  //   // instalationAmount,
  // ]);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  useEffect(() => {
    if (Number(instalationAmount) > Number(amount)) {
      setError("instalation_amount", {
        type: "custom",
        message: "Instalation Amount is Greater than Total Amount",
      });
    } else if (
      _.get(validationField, "minPayment") &&
      Number(amount) < _.get(validationField, "minPayment")
    ) {
      setError("amount", {
        type: "custom",
        message: `*Minimum Payment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("instalation_amount");
      clearErrors("amount");
    }

    if (Number(count) > _.get(validationField, "maxInstalmentCount")) {
      setError("instalation_count", {
        type: "custom",
        message: `* Maximum Allowed installments is 
         ${_.get(validationField, "maxInstalmentCount", 0)}`,
      });
    } else {
      clearErrors("instalation_count");
    }
    if (Number(instalationAmount) < _.get(validationField, "minPayment")) {
      setError("instalation_amount", {
        type: "custom",
        message: `*Minimum Installment amount is ${_.get(
          customerData,
          "data.doc.default_currency",
          0
        )} ${_.get(validationField, "minPayment", 0)}`,
      });
    } else {
      clearErrors("instalation_amount");
    }
  }, [amount, instalationAmount, count]);

  const { data, isFetched, isError, isLoading, refetch } = useQuery(
    [
      `GET_BLACKOUT_HOLIDAY_${currentOrganization}`,
      {
        filters: { date: isInstallmentsss.map((e) => e.date) },
      },
    ],
    async ({ queryKey }) => {
      let { filters } = queryKey[1];
      return await getHolidayBlackoutHolidayDate({
        organization: currentOrganization,
        customerId: customerId,
        filters,
      });
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isInstallmentsss && isInstallmentsss.length > 0 ? true : false,
    }
  );

  const { currencies, outstanding_amount } = useMemo(() => {
    const currencies = _.get(
      customerData,
      "data.doc.invoice_currencies",
      []
    ).map((e) => ({ id: e, label: e }));
    const outstanding_amount = _.get(
      customerData,
      "data.doc.total_outstanding_invoice_amount.value",
      0
    );

    setValue("currency", currencies[0] ? [currencies[0]] : []);
    setValue("amount", outstanding_amount);

    return { currencies, outstanding_amount };
  }, [customerData?.data]);

  const serviceMutation = useMutation(
    (formData) =>
      service({
        ...formData,
        id: customerId,
        entity: "customer",
        organization: currentOrganization,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data) => {
        if (data && data.data && data.data.redirect_url) {
          window.open(data.data.redirect_url);
        }

        onSuccess();
      },
    }
  );

  console.log("payment_gateway_id", paymentPlan);

  const onSubmit = async (data) => {
    if (Number(count) > _.get(validationField, "maxInstalmentCount")) {
      setError("instalation_count", {
        type: "custom",
        message: `* Maximum Allowed installments is 
         ${_.get(validationField, "maxInstalmentCount", 0)}`,
      });
      // return false;
    }
    const {
      frequency,
      start_date,
      amount,
      currency,
      comment,
      residual_amount_type,
      instalation_count,
      payment_gateway_id,
      instalation_amount,
      online_payment_type,
      is_waive_off,
    } = data;

    if (isInvoice.length < 1) {
      return;
    }
    if (+amount > +outstanding_amount) {
      return setError("amount", {
        type: "custom",
        message: "Amount greater than due amount",
      });
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(comment, "text/html");
    const mentionedUsers = Array.from(doc.querySelectorAll("span.mention"))
      .map((span) => span.getAttribute("data-id"))
      .filter((id) => id);

    // Remove duplicates using Set
    const uniqueMentionedUsers = Array.from(new Set(mentionedUsers));

    let values = {
      ...selectedInvoices,
      ...formValues,
      ...(isContent ? { content: "-" } : {}),
      // ...(online_payment_type ? { online_payment_type: "HSA" } : {}),
      is_waive_off,
      method: "PORTAL",
      comment,
      instalation_count: instalation_count,
      instalation_amount: {
        currency: _.get(customerData, "data.doc.default_currency", ""),
        value: instalation_amount,
      },
      residual_amount_type: residual_amount_type
        ? getSelectValues(residual_amount_type)
        : "LAST_INSTALMENT",
      frequency: getSelectValues(frequency),
      payment_gateway_id: payment_gateway_id?.[0]?._id,
      // getSelectValues(payment_gateway_id),
      ...(payment_gateway_id &&
      payment_gateway_id[0] &&
      payment_gateway_id[0].online_payment_type &&
      payment_gateway_id[0].online_payment_type.length
        ? { online_payment_type: payment_gateway_id[0].online_payment_type }
        : {}),
      start_date: moment(start_date)
        .tz(currentDefaultFormatDetails?.time_zone)
        .startOf("day")
        .utc()
        .valueOf(),

      amount: {
        value: amount,
        currency: getSelectValues(currency),
      },
      payment_plan_list: isInstallmentsss.map((e) => {
        return {
          promise_amount: {
            value: Number(e?.amount),
            currency: _.get(customerData, "data.doc.default_currency", ""),
          },
          promise_date: e?.date,
        };
      }),
      ...getActionReminder(data, {
        is_consent_enabled: props.isConsent,
        is_incrementor: props.isIncrementor,
      }),
      mention_users: uniqueMentionedUsers,
    };

    await serviceMutation.mutateAsync(values);
  };

  useEffect(() => {
    if (
      referenceData["auto_debit_payment_method"] &&
      Array.isArray(referenceData["auto_debit_payment_method"]) &&
      referenceData["auto_debit_payment_method"][0]
    ) {
      setValue("payment_gateway_id", [
        {
          ...referenceData["auto_debit_payment_method"]?.[0],
          _id: referenceData["auto_debit_payment_method"]?.[0]?.id,
          id: `${referenceData["auto_debit_payment_method"]?.[0]?.id}-${referenceData["auto_debit_payment_method"]?.[0]?.online_payment_type}`,
        },
      ]);
    }
  }, []);

  console.log("paymentPlan_MAX", paymentPlan);

  useEffect(() => {
    if (
      (paymentPlan &&
        paymentPlan[0] &&
        paymentPlan[0].gateway === "ACCEPTBLUE") ||
      paymentPlan?.[0]?.gateway === "REPAY_CHANNEL"
    ) {
      let day = new Date();
      let nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);

      setValue(
        "start_date",
        moment()
          .tz(currentDefaultFormatDetails?.time_zone)
          .add(1, "day")
          .toDate()
      );
    } else {
      setValue("start_date", new Date());
    }
  }, [paymentPlan]);

  const calculateTenure = (basetype) => {
    const totalAmount = parseFloat(amount) || 0;
    let installmentAmount = parseFloat(instalationAmount) || 0;
    const frequencyId =
      frequency && frequency?.[0] && frequency?.[0]?.id && frequency?.[0]?.id
        ? frequency &&
          frequency?.[0] &&
          frequency?.[0]?.id &&
          frequency?.[0]?.id
        : "WEEKLY";
    const residualAmountOption = getSelectValues(residualAmountType);

    let numOfInstallments = count;

    console.log("__MX_AA PRE", {
      totalAmount,
      computeBy,
      installmentAmount,
      count,
    });

    let reminder = 0;

    if (computeBy === "count") {
      const unit = count;
      installmentAmount = truncateNumber(totalAmount / unit, 2);
      reminder = Number((totalAmount - installmentAmount * unit).toFixed(2));
    } else {
      numOfInstallments = Math.floor(totalAmount / installmentAmount);
      reminder = Number((totalAmount % installmentAmount).toFixed(2));
    }

    const freqMoment = {
      WEEKLY: {
        type: "day",
        count: 7,
      },
      FORTNIGHTLY: {
        type: "day",
        count: 14,
      },
      MONTHLY: {
        type: "month",
        count: 1,
      },
      QUARTERLY: {
        type: "month",
        count: 3,
      },
    };

    let countInc = 0;
    const installments = Array.from({
      length: parseInt(numOfInstallments),
    }).map((inst, idx) => {
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${startDate ? startDate.getFullYear() : ""}-${
            startDate ? startDate.getMonth() + 1 : ""
          }-${startDate ? startDate.getDate() : ""}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      countInc += count;

      return {
        date: installmentDate,
        amount: Number(
          Number(installmentAmount) +
            (residualAmountOption === "FIRST_INSTALMENT" && idx === 0
              ? Number(reminder)
              : 0) +
            (residualAmountOption === "LAST_INSTALMENT" &&
            idx === parseInt(numOfInstallments) - 1
              ? Number(reminder)
              : 0)
        ).toFixed(2),
      };
    });

    if (reminder !== 0 && residualAmountOption === "KEEP_LAST_INSTALMENT") {
      console.log(reminder, "reminder");
      const { type, count } = freqMoment[frequencyId];
      const installmentDate = moment
        .tz(
          `${startDate.getFullYear()}-${
            startDate.getMonth() + 1
          }-${startDate.getDate()}`,
          "YYYY-MM-DD",
          currentDefaultFormatDetails?.time_zone
        )
        .startOf("day")
        .add(countInc, type)
        .utc()
        .valueOf();

      installments.push({
        date: installmentDate,
        amount: reminder,
      });
    }
    // old code is removed for calculation on 20-12-2023
    if (installments.length > 0) {
      setInstallments(installments);
      if (computeBy === "count") {
        setValue("instalation_amount", truncateNumber(installmentAmount, 2));
      } else if (computeBy === "residual_amount") {
        setValue("instalation_count", installments.length);
      } else {
        setValue("instalation_count", installments.length);
      }
    }

    // if (basetype === "count") {
    //   let installments1 = [];
    //   let countIncs = 0;
    //   if (amount && currency && frequency && count && startDate) {
    //     const freq =
    //       freqMoment[frequency && frequency[0] ? frequency[0].id : null];
    //     installments1 = Array.from({ length: count }).map((e, i) => {
    //       const installmentDate = moment(startDate)
    //         .tz(currentDefaultFormatDetails?.time_zone)
    //         .startOf("day")
    //         .add(countIncs, freq.type)
    //         .utc()
    //         .valueOf();
    //       countIncs += freq.count;
    //       return {
    //         date: installmentDate,
    //         amount: Number(amount) / Number(count),
    //       };
    //     });
    //   }
    //   let coutv1 = Number(_.get(installments1, "[0].amount", null));
    //   let countV2 = coutv1 ? truncateNumber(coutv1, 2) : undefined;
    //   setValue("instalation_amount", countV2);
    // } else if (basetype === "amount") {
    //   const truncatedInstalationAmount = truncateNumber(
    //     Number(instalationAmount),
    //     2
    //   );

    //   // Update the form values
    //   setValue("instalation_amount", truncatedInstalationAmount);
    // }
    // if (installments.length) {
    //   setValue("instalation_count", installments.length);
    // }
  };

  useEffect(() => {
    if (totalAmount > 0) {
      setValue("amount", totalAmount);
    } else {
      setValue("amount", outstanding_amount);
    }
  }, [totalAmount]);

  useEffect(() => {
    if (isInstallmentsss.length) {
      setValue("start_date", new Date(isInstallmentsss?.[0]?.date));
    }
  }, [isInstallmentsss?.[0]?.date]);

  console.log(
    "Installments_count ",
    referenceData["auto_debit_payment_method"]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div style={{ display: "flex", gap: "5px", marginTop: "20px" }}>
        <div>
          <Controller
            name="currency"
            control={control}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <SelectBox
                {...field}
                name={field.name}
                error={errors[field.name] && errors[field.name].message}
                clearable={false}
                size={SIZE.mini}
                label={intl.formatMessage({
                  id: "cur",
                })}
                placeholder={intl.formatMessage({
                  id: "cur",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={currencies}
                requiredAstric={true}
              />
            )}
          />
        </div>

        <Controller
          name="amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "amount",
              })}
              placeholder={intl.formatMessage({
                id: "amount",
              })}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
                setValue("instalation_count", "");
                setValue("instalation_amount", "");
                setIsCompute(false);
              }}
            />
          )}
        />

        <Controller
          name="instalation_count"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              // disabled={isInstalationCountChanged}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "no_of_installment",
              })}
              placeholder={intl.formatMessage({
                id: "no_of_installment",
              })}
              onChange={(val) => {
                field.onChange(val);
                setComputeBy("count");
                setIsCompute(false);
              }}
              onBlur={() => {
                setIsCompute(false);
                calculateTenure("count");
              }}
              value={field.value}
            />
          )}
        />

        <Controller
          name="instalation_amount"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <TextBox
              {...field}
              hidden={true}
              type="number"
              name={field.name}
              size={SIZE.mini}
              requiredAstric={true}
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "instalation_amount",
              })}
              // disabled={isInstalationAmountChanged}
              placeholder={intl.formatMessage({
                id: "instalation_amount",
              })}
              value={field.value}
              onChange={(val) => {
                field.onChange(val);
                setComputeBy("amount");
                setIsCompute(false);
              }}
              onBlur={() => {
                setValue("instalation_amount", truncateNumber(field.value, 2));
                setIsCompute(false);
                calculateTenure("amount");
              }}
            />
          )}
        />

        <Controller
          name="frequency"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: "frequency",
              })}
              placeholder={intl.formatMessage({
                id: "frequency",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                setIsCompute(false);
              }}
              options={referenceData["payment_plan_frequency"] || []}
            />
          )}
        />
      </div>

      <div style={{ display: "flex", gap: "5px" }}>
        <Controller
          name="residual_amount_type"
          control={control}
          defaultValue={setSelectValues("LAST_INSTALMENT")}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              requiredAstric={true}
              size="mini"
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              label={intl.formatMessage({
                id: "residual_amount",
              })}
              value={field.value}
              onChange={(e) => {
                setComputeBy("residual_amount");
                setIsCompute(false);
                field.onChange(e.value);
              }}
              options={referenceData["payment_plan_residual"] || []}
            />
          )}
        />
        <Controller
          name="start_date"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <DateRangeSelect
              {...field}
              name={field.name}
              size={SIZE.mini}
              error={errors[field.name] && errors[field.name].message}
              requiredAstric={true}
              label={intl.formatMessage({
                id: "start_date",
              })}
              placeholder={intl.formatMessage({
                id: `${currentDefaultFormatDetails.date_format}`,
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.date);
                setIsCompute(false);
              }}
              minDate={
                (paymentPlan &&
                  paymentPlan[0] &&
                  paymentPlan[0].gateway === "ACCEPTBLUE") ||
                paymentPlan?.[0]?.gateway === "REPAY_CHANNEL"
                  ? moment()
                      .tz(currentDefaultFormatDetails?.time_zone)
                      .add(1, "day")
                      .toDate()
                  : new Date()
              }
            />
          )}
        />

        <Controller
          name="payment_gateway_id"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              {...field}
              name={field.name}
              error={errors[field.name] && errors[field.name].message}
              clearable={false}
              size={SIZE.mini}
              label={intl.formatMessage({
                id: "payment_method",
              })}
              placeholder={intl.formatMessage({
                id: "payment_method",
              })}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);

                // setValue("online_payment_type", e?.value?.[0]?.online_payment_type)
              }}
              options={
                referenceData["auto_debit_payment_method"] &&
                Array.isArray(referenceData["auto_debit_payment_method"])
                  ? referenceData["auto_debit_payment_method"].map((e) => {
                      return {
                        ...e,
                        id: `${e?.id}-${e?.online_payment_type}`,
                        _id: e?.id,
                      };
                    })
                  : []
              }
            />
          )}
        />
      </div>
      <div style={{ textAlign: "end", marginBottom: "13px" }}>
        <TextButton
          kind="primary"
          size="mini"
          type="button"
          disabled={!instalationAmount?.toString()?.length > 0 || !errors}
          onClick={() => {
            // setIsCheck(false);
            // if (setIsCompute) {
            setIsCompute(true);
            // }
            calculateTenure();
            setIsEdit(false);
          }}
        >
          Click to Compute
        </TextButton>
      </div>
      {Array.isArray(isInstallmentsss) &&
        isInstallmentsss.length > 0 &&
        isCompute && (
          <APLInstallmentList
            installments={isInstallmentsss}
            frequency={_.get(frequency, "[0].label", "-")}
            planned_payment_amount={amount}
            setInstallments={setInstallments}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            sepType={_.get(paymentPlan, "[0].pltyp", "")}
            paymentPlan={
              paymentPlan && paymentPlan[0] && paymentPlan[0].gateway
            }
            amount={amount}
          />
        )}
      {!watch("online_payment_type") &&
        convienceFeeData &&
        convienceFeeData.data &&
        convienceFeeData?.data?.doc &&
        convienceFeeData?.data?.doc?.total_fee_amount > 0 && (
          <div className="alert-holiday-section">
            <Icon icon="alert" color="#516BEB" size={"18"} />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              {`${
                _.get(convienceFeeData, "data.doc.min_fee", 0) > 0
                  ? `${
                      currencyList[
                        _.get(customerData, "data.doc.default_currency", 0)
                      ]
                    } ${_.get(convienceFeeData, "data.doc.min_fee", 0)}`
                  : ""
              } ${
                _.get(convienceFeeData, "data.doc.max_fee", 0) > 0 ? "-" : ""
              } 
        ${
          _.get(convienceFeeData, "data.doc.max_fee", 0) > 0
            ? `${
                currencyList[
                  _.get(customerData, "data.doc.default_currency", 0)
                ]
              } ${_.get(convienceFeeData, "data.doc.max_fee", 0)}`
            : ""
        } Processing Fees applies per installment and Overall ${
                currencyList[
                  _.get(customerData, "data.doc.default_currency", 0)
                ]
              } ${_.get(convienceFeeData, "data.doc.total_fee_amount", 0)}`}
            </Typography>
          </div>
        )}

      {/* <PaymentPlanList
        amount={amount}
        currency={currency && currency[0] ? currency[0].id : null}
        frequency={frequency && frequency[0] ? frequency[0].id : null}
        count={count}
        startDate={startDate}
        paymentPlan={paymentPlan}
        // installments={installments}
        setExpand={setExpand}
        setIsCompute={setIsCompute}
        isCompute={isCompute}
        installmentAmount={instalationAmount}
        expand={expand}
        residualAmountType={residualAmountType}
        isInstallmentsss={isInstallmentsss}
        calculateTenure={calculateTenure}
        truncateNumber={truncateNumber}
      /> */}

      {_.get(data, "data.doc.is_holiday") && (
        <>
          <div className="alert-holiday-section" style={{ marginTop: "10px" }}>
            <Icon icon="alert_circle" color="#516BEB" />
            <Typography
              type="p"
              style={{
                fontWeight: "400",
              }}
            >
              One or more schedule falls on a Holiday -{" "}
              {_.get(data, "data.doc.holidays", []).map((val, i) => {
                return (
                  <b>
                    {moment(val).format(
                      currentDefaultFormatDetails.date_format
                    )}
                    {_.get(data, "data.doc.holidays", []).length - 1 === i
                      ? "."
                      : ","}{" "}
                  </b>
                );
              })}
            </Typography>
          </div>
        </>
      )}

      {paymentGatewayId?.[0]?.fee_cmp_at === "AP" ||
      paymentPlan?.[0]?.gateway === "PAYSCOUT" ? (
        <div
          className="cp-message-confirmation-checkbox"
          style={{ width: "150px" }}
        >
          <Controller
            defaultValues={false}
            name="is_waive_off"
            control={control}
            render={({ field }) => (
              <CheckBoxBaseweb
                {...field}
                checked={field.value}
                labelPlacement={LABEL_PLACEMENT.right}
              >
                <FormattedMessage id="waive_off">Waive Off</FormattedMessage>
              </CheckBoxBaseweb>
            )}
          />
        </div>
      ) : (
        <></>
      )}

      <ActionReminders
        {...props}
        watch={watch}
        control={control}
        errors={errors}
        setValue={setValue}
        actionType={formValues.action_type}
        is_inbound_call={formValues.is_inbound_call ? true : false}
        outcome={ACT_AUTO_PAYMENT_PLAN}
        consentDate={startDate}
        callValidationDuration={_.get(
          customerData,
          "data.doc.mini_miranda.call_validation_duration"
        )}
        timeZone={_.get(customerData, "data.doc.timezone")}
        is_incrementor={props.isIncrementor}
        is_consent_enabled={props.isConsent}
        isHold={isHold}
      />

      <div
        style={{
          display: "flex",
          marginTop: "10px",
          gap: "10px",
          justifyContent: "flex-end",
        }}
      >
        <TextButton
          kind="tertiary"
          size="mini"
          type="button"
          disabled={serviceMutation.isLoading}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </TextButton>
        <TextButton
          size="mini"
          disabled={serviceMutation.isLoading || !isCompute}
          isLoading={serviceMutation.isLoading}
        >
          Save
        </TextButton>
      </div>
    </form>
  );
};

const AutoPaymentPlan = (props) => {
  const { customerId } = useParams();

  const [text, setAlertPromise] = useState(false);
  const [isInvoice, setIsInvoice] = useState([]);
  const [currentInvoice, setCurrentInvoice] = useState([]);
  const { currentDefaultFormatDetails } = useContext(MaxyfiContext);

  const [isPromiseExist, setIsPromiseExist] = useState(false);

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  const format = useFormat();
  const invoicesData = queryClient.getQueryData([
    `${CO_INVOICES_}${customerId}`,
    { filters: {}, gid: _.get(customerData, "data.doc.gid", "") },
  ]);

  const consumerGroupPromiseText = _.get(invoicesData, "data.docs", []).some(
    (obj) => obj.hasOwnProperty("pgid")
  );

  const invOption = useMemo(() => {
    let findpgid;

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      customerData.data.doc.pgid
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
      );
    }

    if (
      customerData &&
      customerData.data &&
      customerData.data.doc &&
      !customerData.data.doc.pgid &&
      customerData.data.doc._id
    ) {
      findpgid = _.get(invoicesData, "data.docs", []).filter(
        ({ customer_id }) => customer_id === _.get(customerData, "data.doc._id")
      );
    }

    let invIds = findpgid
      .filter((fl) => fl.status !== "paid" && fl.status !== "stl")
      .map(({ _id, not_paid_amount, ...e }) => ({
        id: _id,
        outstanding: not_paid_amount,
        label: `${format.rd({
          id: _.get(e, "business_unit", ""),
          name: "business_unit_list",
        })}${_.get(e, "business_unit", "") ? " / " : ""}${_.get(
          e,
          "invoice_number",
          ""
        )}${_.get(e, "invoice_number", "") ? " / " : ""}
      ${_.get(e, "client_reference", "")}${
          _.get(e, "client_reference", "") ? " / " : ""
        }
      ${moment
        .utc(e.invoice_date)
        .tz(currentDefaultFormatDetails.time_zone)
        .format(currentDefaultFormatDetails.date_format)}${
          _.get(not_paid_amount, "currency", "") ? " / " : ""
        }${_.get(not_paid_amount, "currency", "")}${
          _.get(not_paid_amount, "value", "") ? " " : ""
        }${_.get(not_paid_amount, "value", "")}`,
      }));
    setIsInvoice([...invIds]);
    setCurrentInvoice([...invIds]);
    return invIds;
  }, [invoicesData]);

  // const invoices = useMemo(() => {
  //   let findpgid = _.get(invoicesData, "data.docs", []).filter(
  //     ({ pgid }) => pgid === _.get(customerData, "data.doc.pgid")
  //   );

  //   let invIds = findpgid.map(({ _id, not_paid_amount }) => ({
  //     id: _id,
  //     outstanding: not_paid_amount,
  //   }));
  //   setIsInvoice([...invIds]);
  //   return _.get(invoicesData, "data.docs", []).map((e) => ({
  //     id: e._id,
  //     outstanding: e.not_paid_amount,
  //     label: `${format.rd({
  //       id: e?.business_unit,
  //       name: "business_unit_list",
  //     })} / ${e.client_reference ? e.client_reference : ""} / ${
  //       e.invoice_number
  //     } / ${moment
  //       .utc(e.invoice_due_date)
  //       .tz(currentDefaultFormatDetails.time_zone)
  //       .format(currentDefaultFormatDetails.date_format)} / ${
  //       e.not_paid_amount && e.not_paid_amount.currency
  //     } ${e.not_paid_amount && e.not_paid_amount.value}`,
  //   }));
  // }, [invoicesData]);

  const promiseStatus = useMemo(() => {
    let matchingIds = [];
    for (
      let i = 0;
      i < _.get(customerData, "data.doc.promise_to_pay", []).length;
      i++
    ) {
      let invArray =
        customerData && customerData?.data?.doc?.promise_to_pay?.[i];
      for (let j = 0; j < invArray?.inv?.length; j++) {
        if (isInvoice.some((item) => item.id === invArray?.inv?.[j])) {
          if (invArray && invArray.status === "IN_PROGRESS") {
            matchingIds.push(invArray);
          }
        }
      }
    }
    return matchingIds;
  }, [isInvoice]);

  useEffect(() => {
    if (promiseStatus.length > 0) {
      setIsPromiseExist(true);
    }
  }, [isInvoice]);

  return (
    <div style={{ marginTop: "15px" }}>
      <div style={{ width: "350px" }}>
        <SelectBox
          requiredAstric={true}
          value={isInvoice}
          getValueLabel={({ option }) => {
            const { label } = option;
            const value = label ? label.split("/") : [];
            return <>{value && value[1] ? value[1] : value[0]}</>;
          }}
          onChange={(e) => {
            setIsInvoice(e.value);
            setAlertPromise(e.value.length < currentInvoice.length);
          }}
          multi
          options={invOption}
          size={"mini"}
        />
        {isInvoice.length < 1 && (
          <span style={{ color: "red" }}>Invioces is required</span>
        )}
      </div>
      {!isPromiseExist &&
        text &&
        _.get(customerData, "data.doc.gid") &&
        consumerGroupPromiseText && (
          <Typography
            className="co_action_exist"
            style={{ textAlign: "start" }}
          >
            * New Group will be created for the selected records.
          </Typography>
        )}
      {isPromiseExist ? (
        <PromiseStatusTypes
          groupedPromise={_.get(customerData, "data.doc.gid")}
          setAlertPromise={text}
          setIsPromiseExist={setIsPromiseExist}
          type={_.get(promiseStatus, "[0].status", "")}
          promiseStatus={_.get(promiseStatus, "[0]", {})}
        />
      ) : (
        <AutoPaymentPlanForm
          isInvoice={isInvoice}
          selectedInvoices={{ invoices: isInvoice?.map(({ id }) => id) }}
          totalAmount={isInvoice
            .reduce((sum, item) => +sum + +item?.outstanding?.value, 0)
            .toFixed(2)}
          {...props}
        />
      )}
    </div>
  );
};

export default AutoPaymentPlan;
