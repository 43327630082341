import React, { useEffect, useState } from "react";
import { Modal, SIZE } from "baseui/modal";
import UploadFile from "../../assets/img/svg/UploadFile";
import Papa from "papaparse";
import { TextButton } from "../../components/TextButton";
import * as ModalButton from "../../components/TextButton";
import { useDropzone } from "react-dropzone";

const BulkImportCustomField = ({ setIsImportData }) => {
  const [acceptedFileValues, setAcceptedFileValue] = useState();

  let { acceptedFiles, open } = useDropzone({
    accept: {
      "text/csv": [".csv"],
    },
    //   validator: fileSizeValidation,
  });

  useEffect(() => {
    setAcceptedFileValue(acceptedFiles);
  }, [acceptedFiles]);

  async function addFile() {
    Papa.parse(acceptedFileValues[0], {
      header: false,
      skipEmptyLines: true,
      delimiter: ",",
      complete: function (results) {
        let keys =
          results.data && results.data[0] && Array.isArray(results.data[0])
            ? results.data[0]
            : []; // header row
        let output =
          results &&
          results.data &&
          results.data.slice(1).map((row) => {
            let obj = {};
            row.forEach((value, index) => {
              obj[keys[index]] = value;
            });
            return obj;
          });
        setIsImportData([...output]);
      },
    });
  }

  useEffect(() => {
    if (acceptedFileValues?.length) {
      addFile();
    }
  }, [acceptedFileValues?.length]);

  return (
    <>
      <div style={{ textAlign: "end" }}>
        <TextButton size="mini" onClick={() => open()}>
          Import
        </TextButton>
      </div>
    </>
  );
};

export default BulkImportCustomField;
