import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
import { toast } from "react-toastify";
const APP_CODE = process.env.REACT_APP_APP_CODE;
export const getUserList = async ({
  organization = null,
  menu = "customer_summary",
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/grid_structure/${menu}`
  );
};

export const customerData = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize = 20,
  filters = {},
  customerFilter = {},
  search_value = null,
  is_include_consolidate = false,
}) => {
  return await axiosWithAuth.post(
    `${process.env.REACT_APP_HOST}${organization}/customer`,
    customerFilter,
    {
      params: {
        page: pageIndex,
        sort: sortBy,
        page_size: pageSize,
        search_value: search_value,
        ...(is_include_consolidate
          ? { is_include_consolidate: is_include_consolidate }
          : {}),

        ...filters,
      },
    }
  );
};

export const getViewData = async ({
  organization = null,
  menu,
  viewId = null,
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/view/`,
    {
      params: {
        page: 1,
        page_size: 100,
        menu,
      },
    }
  );
};
export const defaultViewData = async ({
  organization = null,
  menu = null,
  viewId = null,
}) => {
  return await axiosWithAuth.patch(
    `${process.env.REACT_APP_HOST}${organization}/view/${viewId}/default`,
    { menu }
  );
};

export const deleteViewData = ({ organization = null, viewId = null }) => {
  return axiosWithAuth.patch(
    `${process.env.REACT_APP_HOST}${organization}/view/${viewId}/delete`
  );
};

export const pinViewData = ({ organization = null, viewId = null, menu }) => {
  return axiosWithAuth.patch(
    `${process.env.REACT_APP_HOST}${organization}/view/${viewId}/pin`,
    {
      menu,
    }
  );
};

export const createCustomerContact = async ({
  organization = null,
  ...contactData
}) => {
  return await axiosWithAuth.post(
    `${process.env.REACT_APP_HOST}${organization}/customer/add`,
    contactData
  );
};
export const globalsearch = async ({
  organization = null,
  pageSize = 20,
  filters = {},
  entity = null,
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/${entity}/globalSearch`,
    {
      params: {
        page_size: pageSize,
        ...filters,
      },
    }
  );
};
export const globalInvoiceSearch = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize = 20,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/invoices/globalSearch`,
    {
      params: {
        page: pageIndex,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },
    }
  );
};
export const customerTNC = async ({
  organization = null,
  templateType,
  filters = {},
  query = {},
}) => {
  return await axiosWithAuth
    .post(
      `${process.env.REACT_APP_HOST}${organization}/customer/dialer-download`,
      query,
      {
        headers: {
          appCode: APP_CODE,
        },

        params: {
          ...filters,
        },
      }
    )
    .then((blob) => {
      download({ fileName: "Dialer Data.csv", fileData: blob.data });
    });
};

export const customerListDataTNC = async ({
  organization = null,
  pageIndex = 1,
  sortBy,
  pageSize = 20,
  filters = {},
  customerFilter = {},
  search_value = null,
  is_include_consolidate = false,
  columns,
}) => {
  return await axiosWithAuth
    .post(
      `${process.env.REACT_APP_HOST}${organization}/customer/customer-list-download`,
      { columns, filter: customerFilter },
      {
        headers: {
          appCode: APP_CODE,
        },
        params: {
          page: pageIndex,
          sort: sortBy,
          page_size: pageSize,
          search_value: search_value,
          ...(is_include_consolidate
            ? { is_include_consolidate: is_include_consolidate }
            : {}),
          ...filters,
        },
      }
    )
    .then((blob) => {
      download({ fileName: "List Data.csv", fileData: blob.data });
    });
};

export const clientSummaryDownload = async ({
  organization = null,
  templateType,
  filters = {},
  query = {},
  colums = [],
}) => {
  return await axiosWithAuth
    .post(
      `${process.env.REACT_APP_HOST}organization/${organization}/download-business-unit`,
      query,
      {
        headers: {
          appCode: APP_CODE,
        },

        params: {
          ...filters,
        },
      }
    )
    .then((blob) => {
      // toast.success(blob.data.message);
    });
};
export const fieldVisitDownload = async ({
  organization = null,
  templateType,
  filters = {},
  query = {},
  colums = [],
}) => {
  return await axiosWithAuth
    .post(
      `${process.env.REACT_APP_HOST}${organization}/customer/download-field-visit`,
      query,
      {
        headers: {
          appCode: APP_CODE,
        },

        params: {
          ...filters,
        },
      }
    )
    .then((blob) => {
      toast.success(blob.data.message);
    });
};

export const epicDownload = async ({
  organization = null,
  templateId,
  mapFieldId,
  fileName,
}) => {
  return await axiosWithAuth
    .get(
      `${process.env.REACT_APP_HOST}${organization}/adhoc-template/${templateId}/mapping-file/${mapFieldId}`,
      {
        headers: {
          appCode: APP_CODE,
        },
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      download({
        fileName: `${fileName}.csv`,
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};

export const reportSummaryDownload = async ({
  organization = null,
  reportId,
  fileName,
}) => {
  return await axiosWithAuth
    .get(
      `${process.env.REACT_APP_HOST}${organization}/generated-report/${reportId}`,
      {
        headers: {
          appCode: APP_CODE,
        },
        responseType: "arraybuffer",
      }
    )
    .then((blob) => {
      download({
        fileName: `${fileName}.${
          blob?.headers?.["content-type"]?.split("/")[1]
        }`,
        fileData: blob.data,
        fileOptions: {
          type: "application/pdf",
        },
      });
    });
};

export const getUserViewData = async ({
  organization = null,
  menu,
  userId = "new",
}) => {
  return await axiosWithAuth.get(
    `${process.env.REACT_APP_HOST}${organization}/view/`,
    {
      params: {
        page: 1,
        page_size: 0,
        menu,
        user_id: userId,
      },
    }
  );
};
