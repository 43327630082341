import React from "react";
import { Controller } from "react-hook-form";
import { TextBox } from "../../../components/TextBox";
import DateRangeSelect from "../../../components/TableFilters/DateRangeFilter/DateRangeSelect";
import { TextArea } from "../../../components/TextArea";
import { SIZE, SelectBox } from "../../../components/SelectBox";
import { Icon } from "../../../components_v2";
import { Input } from "baseui/input";

const InputTypes = ({
  type = "",
  control = null,
  name = "",
  path = "",
  options = [],
  setIsEditing,
  disabled = true,
}) => {
  if (name === "Judgment Amount" && type === "AMOUNT") {
    return (
      <Controller
        name={`${path}.value`}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            type="number"
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
            endEnhancer={
              <Icon
                icon="edit"
                size={16}
                color="#ADADAD"
                onClick={() => {
                  setIsEditing(true);
                }}
              />
            }
          />
        )}
      />
    );
  } else if (type === "AMOUNT") {
    return (
      <Controller
        name={`${path}.value`}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            type="number"
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
          />
        )}
      />
    );
  } else if (name !== "Judgment Amount" && type === "NUMBER") {
    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            type="number"
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
          />
        )}
      />
    );
  } else if (type === "DATE") {
    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <DateRangeSelect
            {...field}
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            onChange={(e) => field.onChange(e.date)}
            minDate={new Date("1900-01-01T00:00:00.000Z")}
            disabled={!disabled}
          />
        )}
      />
    );
  } else if (type === "LONG_TEXT") {
    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <TextArea
            {...field}
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
          />
        )}
      />
    );
  } else if (type === "HYPERLINK") {
    function windowOpen(url) {
      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }
      return window.open(url);
    }

    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
            endEnhancer={
              <div style={{ paddingBottom: "5px" }}>
                <Icon
                  icon="workflow_heigh"
                  isPressable
                  onClick={() => windowOpen(field.value)}
                />
              </div>
            }
          />
        )}
      />
    );
  } else if (type === "NUMBER") {
    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            type="number"
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
          />
        )}
      />
    );
  } else if (type === "TEXT" && options.length > 0) {
    return (
      <Controller
        name={path}
        key={path}
        control={control}
        render={({ field }) => (
          <SelectBox
            {...field}
            name={field.name}
            clearable={false}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.value);
            }}
            options={options || []}
            disabled={!disabled}
          />
        )}
      />
    );
  } else {
    return (
      <Controller
        name={path}
        control={control}
        key={path}
        render={({ field }) => (
          <TextBox
            {...field}
            name={field.name}
            size={SIZE.mini}
            label={name}
            placeholder={name}
            value={field.value}
            disabled={!disabled}
          />
        )}
      />
    );
  }
};

export default InputTypes;
