import * as React from "react";
import { useContext, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { Radio } from "baseui/radio";
import { ParagraphMedium } from "baseui/typography";
import EditPencil from "../../../utils/EditPencil";
import { IconButton } from "../../../components/IconButton";
import { useNumericFormat } from "react-number-format";
import { getConvienceFeePAYPost, makeInstantPayment } from "../../../services";
import { useParams } from "react-router-dom";
import { TextButton, KIND, SIZE } from "../../../components/TextButton";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Input } from "baseui/input";

import TickSvg from "../../../assets/img/svg/TickSvg";
import ActionRemove from "../../../assets/img/svg/ActionRemove";
import queryClient from "../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../constants";
import _ from "lodash";
import { PAYMENT_CARD_DRAWER, setDrawerState } from "../../../redux/actions";
import { Icon, Typography } from "../../../components_v2";
import useFormat from "../../../hooks/useFormat";
import { SelectBox } from "../../../components/SelectBox";
import { FormattedMessage, useIntl } from "react-intl";
import CheckBoxBaseweb from "../../../components/CheckBoxBaseweb";
import { LABEL_PLACEMENT } from "baseui/checkbox";
import getSelectValues from "../../../utils/getSelectValues";
import { currencyList } from "../../../utils_v2/CurrencyToSymbol";

const PaymentCard_V2 = () => {
  const { customerId } = useParams();
  const format = useFormat();
  const intl = useIntl();
  const [card, setCard] = useState({
    isCard: false,
    cardId: "add_card",
    paymentMethodId: null,
    isSaveCard: false,
    isHsa: false,
    is_waive_off: false,
  });
  const [isEdit, setIsEdit] = useState({
    state: false,
    data: "",
  });

  const customerData = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );

  const cards = useMemo(() => {
    let cards = _.get(customerData, "data.doc.transaction_cards", []);
    return cards && Array.isArray(cards)
      ? [...cards, { id: "add_card" }]
      : [{ id: "add_card" }];
  }, [customerData]);

  const {
    currencyDecimalSeparator,
    currencyGroupStyle,
    currencySeparator,
    currentOrganization,
    referenceData,
  } = useContext(MaxyfiContext);

  const paymentDtl = useSelector((e) => e.customerOverviewDrawer.data);

  let { amount, currency, installment_id } = paymentDtl;

  const { data: convienceFeePayData, refetch: refetchConvenienceFee } =
    useQuery(
      [
        `GET_CONVENIENCE_FEE_PAY_${customerId}`,
        {
          amount: amount,
          paymentGatewayId:
            _.get(card, "paymentMethodId", []) &&
            getSelectValues(_.get(card, "paymentMethodId", [])) &&
            getSelectValues(_.get(card, "paymentMethodId", [])).replace(
              /-.*$/,
              ""
            ),
          ...(_.get(card, "paymentMethodId.[0].online_payment_type", "")
            ? {
                online_payment_type: _.get(
                  card,
                  "paymentMethodId.[0].online_payment_type",
                  ""
                ),
              }
            : {}),

          is_waive_off: _.get(card, "paymentMethodId[0].is_waive_off", false),
        },
      ],
      async ({ queryKey }) => {
        const [
          ,
          {
            amount,
            count,
            paymentGatewayId,
            online_payment_type,
            is_waive_off,
          },
        ] = queryKey;

        return await getConvienceFeePAYPost({
          organization: currentOrganization,
          customerId: customerId,
          amount,
          count,
          paymentGatewayId,
          online_payment_type, // Add the new query param
          is_waive_off,
        });
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled:
          Boolean(amount) &&
          Boolean(
            card &&
              card?.paymentMethodId &&
              getSelectValues(card && card?.paymentMethodId)
          ), // Ensure all conditions are met
        cacheTime: 5 * 60 * 1000,
        onSuccess: (data) => {
          console.log("Query success:", data);
        },
        onError: (error) => {
          console.error("Query error:", error);
        },
      }
    );

  const handlePaymentMethodChange = (e) => {
    setCard({ ...card, isCard: false, paymentMethodId: e.value });

    // Trigger API refetch after state update
    refetchConvenienceFee();
  };
  const dispatch = useDispatch();

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  let numberFormat = useNumericFormat({
    thousandsGroupStyle: currencyGroupStyle,
    decimalScale: 2,
    fixedDecimalScale: true,
    thousandSeparator: currencySeparator,
    decimalSeparator: currencyDecimalSeparator,
  });

  const makeInstantPaymentMutation = useMutation(
    (data) =>
      makeInstantPayment({
        data,
        organization: currentOrganization,
        customerId: customerId,
      }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;

          // if (typeof data.error === "object") {
          //   errorData = Object.keys(data.error).map(
          //     (e) => `${e}: ${data.error[e]}`
          //   );
          //   errorData = errorData.toString();
          // }
          toast.error(errorData);
        }

        //Need to handle if no error response is send from backend
      },
      onSuccess: (data, variables, context) => {
        if (
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.payment_initiate_url
        ) {
          window.open(
            data &&
              data.data &&
              data.data.doc &&
              data.data.doc.payment_initiate_url
          );
          closeDrawer();
        } else if (
          data &&
          data.data &&
          data.data.doc &&
          data.data.doc.payment_status_fetch_url
        ) {
          window.open(
            data &&
              data.data &&
              data.data.doc &&
              data.data.doc.payment_status_fetch_url
          );
          closeDrawer();
        } else {
          closeDrawer();
        }
        // need to close the drawer
        // dispatch(
        //   instantPaymentDrawer({
        //     modal: false,
        //   })
        // );
      },
    }
  );

  console.log("card_MAX", card);

  return (
    <div
      style={{
        overflow: "scroll",
        height: "calc(100vh - 45px)",
        width: "550px",
      }}
    >
      <div className="review-drawer_header">
        <Icon icon="back_arrow" isPressable />
        <>
          <Typography type="p" className="text-secondary">
            Review Promise /
          </Typography>{" "}
          <Typography type="h2">Card Details</Typography>
        </>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          paddingRight: "15px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography type="h4" subType="medium">
            Payment Amount
          </Typography>
          <Typography type="p" className="text-secondary" subType="regular">
            To maintain a "Kept" status, full payment is required
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {!isEdit.state ? (
            <>
              <span style={{ fontSize: "10px" }}>{currency}</span>&nbsp;
              <ParagraphMedium>
                {numberFormat.format(amount ? `${amount}` : "-")}
              </ParagraphMedium>
              <div className="cp_pencil_icon">
                <IconButton
                  size="mini"
                  kind="tertiary"
                  onClick={() => setIsEdit({ state: true, data: amount })}
                >
                  <EditPencil size={"12px"} />
                </IconButton>
              </div>
            </>
          ) : (
            <>
              <Input
                onChange={(e) => {
                  setIsEdit({
                    state: true,
                    data: e.target.value,
                  });
                }}
                type="number"
                value={isEdit.data}
                overrides={{
                  MaskToggleButton: {
                    style: () => ({
                      // backgroundColor: "#fff",
                      background: "#ffffff",
                      cursor: "pointer",
                    }),
                  },
                  Root: {
                    style: ({ $theme, $isFocused, $error }) => ({
                      borderRadius: "5px",
                      borderWidth: "1px",
                      borderColor: $isFocused
                        ? $error
                          ? "#FD372A"
                          : "#516BEB"
                        : $error
                        ? "#FD372A"
                        : "#CDCED9",
                      backgroundColor: "#ffffff",
                      paddingRight: "0px",
                      padding: "0px",
                      paddingLeft: "0px",
                    }),
                  },
                  Input: {
                    style: ({ $theme, $isFocused }) => ({
                      backgroundColor: "#ffffff",
                      "::placeholder": {
                        color: $isFocused ? "#cbd5e0" : "transparent",
                        transition: "color 0.4s ease",
                      },
                    }),
                  },

                  EndEnhancer: {
                    style: ({ $theme }) => ({
                      paddingTop: "5px",
                      backgroundColor: "#ffffff",
                      display: "none",
                      width: "",
                    }),
                  },
                  StartEnhancer: {
                    style: ({ $theme }) => ({
                      // outline: `${$theme.colors.warning200} solid`,
                      backgroundColor: "#EEEEEE",
                      display: "none",
                    }),
                  },
                }}
                size="mini"
              />
              <div className="cp_edit_ic">
                <div className="tick_cp_icon">
                  <TextButton
                    size="mini"
                    kind="tertiary"
                    disabled={
                      isEdit.data <= 0 ||
                      isEdit.data >
                        _.get(
                          customerData,
                          "data.doc.total_outstanding_invoice_amount.value",
                          0
                        )
                    }
                    onClick={() => {
                      setDrawer(PAYMENT_CARD_DRAWER, {
                        ...paymentDtl,
                        amount: isEdit.data,
                      });
                      setIsEdit({
                        state: false,
                        data: 0,
                      });
                    }}
                  >
                    <TickSvg />
                  </TextButton>
                </div>
                <div className="close_cp_icon">
                  <TextButton
                    size="mini"
                    kind="tertiary"
                    onClick={() => {
                      setIsEdit({
                        state: false,
                        data: 0,
                      });
                    }}
                  >
                    <ActionRemove />
                  </TextButton>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {cards && cards[0] ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "15px",
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                paddingBottom: "10px",
              }}
            >
              <Typography type="h4" subType="medium">
                Saved Cards
              </Typography>
              <Typography type="p" className="text-secondary" subType="regular">
                You've utilized these cards for payments previously
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            {cards.map((e, i) => (
              <>
                {e.id === "add_card" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2px",
                      padding: "4px",
                      width: "195px",
                      border: "1px solid #EEEEEE",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setCard({
                        isCard: true,
                        cardId: e.id,
                        paymentMethodId: e.payment_config_id,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      <div className="add_saved_pay_cards">
                        <Icon icon="plus" color="#516beb" />
                      </div>

                      <Typography type="p" className="regular" subType="medium">
                        Add New Card
                      </Typography>
                    </div>
                    <Radio checked={card && card.cardId === e.id}></Radio>
                  </div>
                ) : (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "2px",
                      padding: "4px",
                      width: "195px",
                      border: "1px solid #EEEEEE",
                      borderRadius: "5px",
                    }}
                    onClick={() => {
                      setCard({
                        isCard: true,
                        cardId: e.id,
                        paymentMethodId: e.payment_config_id,
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography type="p" className="text-primary">
                        {format.rd({
                          name: "online_payment_method",
                          id: e?.payment_config_id,
                        })}
                      </Typography>
                      <div style={{ display: "flex", gap: "3px" }}>
                        <Typography
                          type="p"
                          className="regular"
                          subType="regular"
                        >
                          Card Ends with
                        </Typography>
                        <Typography
                          type="p"
                          className="regular"
                          subType="medium"
                        >
                          {e?.last4}
                        </Typography>
                      </div>
                    </div>
                    <Radio checked={card && card.cardId === e.id}></Radio>
                  </div>
                )}
              </>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {card.cardId === "add_card" && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: "15px",
              marginTop: "30px",
            }}
          >
            {cards && !cards[0] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Typography type="h4" subType="medium">
                  Saved Cards
                </Typography>
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                >
                  You've utilized these cards for payments previously
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "2px",
                    padding: "4px",
                    width: "195px",
                    border: "1px solid #EEEEEE",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setCard({
                      isCard: false,
                      cardId: "add_card",
                      paymentMethodId: null,
                      isSaveCard: false,
                    });
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "3px",
                    }}
                  >
                    <div className="add_saved_pay_cards">
                      <Icon icon="plus" color="#516beb" />
                    </div>

                    <Typography type="p" className="regular" subType="medium">
                      Add New Card
                    </Typography>
                  </div>
                  <Radio checked={card && card.cardId === "add_card"}></Radio>
                </div>
              </div>
            )}
          </div>

          <div style={{ width: "250px" }}>
            <SelectBox
              clearable={false}
              // name={field.name}
              fullWidth
              size={"mini"}
              // error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "payment_method",
              })}
              placeholder={intl.formatMessage({
                id: "payment_method",
              })}
              value={card.paymentMethodId}
              onChange={handlePaymentMethodChange}
              requiredAstric={true}
              options={
                referenceData["online_payment_method"] &&
                Array.isArray(referenceData["online_payment_method"])
                  ? referenceData["online_payment_method"].map((e) => {
                      return {
                        ...e,
                        id: `${e?.id}${
                          _.get(e, "online_payment_type", "") ? "-" : ""
                        }${_.get(e, "online_payment_type", "")}`,
                        _id: e?.id,
                      };
                    })
                  : []
              }
            />
          </div>
          {!(card && card?.isHsa) &&
            convienceFeePayData &&
            convienceFeePayData.data &&
            convienceFeePayData?.data?.doc &&
            convienceFeePayData?.data?.doc?.total_fee_amount > 0 && (
              <div className="alert-holiday-section">
                <Icon icon="alert" color="#516BEB" size={"18"} />
                <Typography
                  type="p"
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {`${
                    _.get(convienceFeePayData, "data.doc.total_fee_amount", 0) >
                    0
                      ? ` ${
                          currencyList[
                            _.get(customerData, "data.doc.default_currency", 0)
                          ]
                        }${_.get(
                          convienceFeePayData,
                          "data.doc.total_fee_amount",
                          0
                        )}`
                      : ""
                  }  Processing Fees applies`}
                </Typography>
              </div>
            )}

          {card?.paymentMethodId?.[0]?.fee_cmp_at === "AP" ||
          card?.paymentMethodId?.[0]?.gateway === "PAYSCOUT" ? (
            <div className="cp-message-confirmation-checkbox">
              <CheckBoxBaseweb
                checked={card.is_waive_off}
                labelPlacement={LABEL_PLACEMENT.right}
                onChange={(e) =>
                  setCard({ ...card, is_waive_off: e.target.checked })
                }
              >
                <FormattedMessage id="waive_fee">Waive Fee</FormattedMessage>
              </CheckBoxBaseweb>
            </div>
          ) : (
            <></>
          )}

          {_.get(card, "paymentMethodId[0].is_hsa", false) && (
            <div className="cp-message-confirmation-checkbox">
              <CheckBoxBaseweb
                checked={card.isHsa}
                labelPlacement={LABEL_PLACEMENT.right}
                onChange={(e) => setCard({ ...card, isHsa: e.target.checked })}
              >
                <FormattedMessage id="enable_hsa">Enable HSA</FormattedMessage>
              </CheckBoxBaseweb>
            </div>
          )}
          <CheckBoxBaseweb
            checked={card.isSaveCard}
            labelPlacement={LABEL_PLACEMENT.right}
            onChange={(e) => setCard({ ...card, isSaveCard: e.target.checked })}
          >
            <FormattedMessage id="save_card_information_for_future_purpose">
              save_card_information_for_future_purpose
            </FormattedMessage>
          </CheckBoxBaseweb>
        </div>
      )}

      <div
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          paddingTop: "20px",
        }}
      >
        <TextButton
          type="button"
          onClick={() => {
            closeDrawer();
          }}
          kind={KIND.tertiary}
          size={SIZE.mini}
        >
          Cancel
        </TextButton>
        <TextButton
          type="submit"
          size={SIZE.mini}
          onClick={async () => {
            await makeInstantPaymentMutation.mutateAsync({
              payment_amount: { value: amount, currency },
              ...(card.isCard
                ? {
                    card_id: card.cardId,
                    payment_method: _.get(card, "paymentMethodId[0]._id", ""),
                  }
                : {}),
              ...(!card.isCard
                ? {
                    payment_method: _.get(card, "paymentMethodId[0]._id", ""),
                    is_save_card: card?.isSaveCard,
                  }
                : {}),
              is_waive_off: card?.is_waive_off,
              ...(_.get(card, "paymentMethodId[0].online_payment_type", "")
                ? {
                    online_payment_type: _.get(
                      card,
                      "paymentMethodId[0].online_payment_type",
                      ""
                    ),
                  }
                : {}),
              ...(installment_id ? { installment_id } : {}),
            });
          }}
          disabled={
            makeInstantPaymentMutation.isLoading || !card.paymentMethodId
          }
          isLoading={makeInstantPaymentMutation.isLoading}
        >
          Continue
        </TextButton>
      </div>
    </div>
  );
};

export default PaymentCard_V2;
