import React from "react";

const NavigateIcon = ({ size = "14", color = "black" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={size}
      height={size}
      fill={color}
    >
      <title>Open Link</title>
      <g id="_13-Arrow_Up" data-name="13-Arrow Up">
        <path d="M25,0H7A7,7,0,0,0,0,7V25a7,7,0,0,0,7,7H25a7,7,0,0,0,7-7V7A7,7,0,0,0,25,0Zm5,25a5,5,0,0,1-5,5H7a5,5,0,0,1-5-5V7A5,5,0,0,1,7,2H25a5,5,0,0,1,5,5Z" />
        <path d="M24,7H14V9h7.59L7.29,23.29l1.41,1.41L23,10.41V18h2V8A1,1,0,0,0,24,7Z" />
      </g>
    </svg>
  );
};

export default NavigateIcon;
